import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ApiMessageProvider, useApiMessages } from './context/ApiMessageContext'; // Import the context and hook
import { Alert, Spin } from 'antd';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import './assets/theme/theme-colors.scss'
// import './assets/theme/typography.scss'
import './assets/theme/spacing.scss'
import './assets/theme/mixin.scss'
import './common-style.scss'
import './assets/theme/button.scss'
import './assets/theme/input.scss'
import './App.css'
import "./"
import Layout from './Layout';
import ResetPassword from './pages/ResetPassword';
import { GlobalLoading } from 'react-global-loading';
import ClientDetails from './pages/ClientDetails';
import { AppProvider } from './context/AppContext';
import Settings from './pages/Settings';

const isAuthenticated = () => {
  // Check if a token exists in localStorage (replace 'your_token_key' with your actual token key)
  const token = localStorage.getItem('token');
  return token !== null;
};

const PrivateRoute = ({ element }) => {
  return isAuthenticated() ? (
    element
  ) : (
    <Navigate to="/login" replace={true} />
  );
};

const style = {
  position: 'fixed',
  top: 175,
  left: 0,
  right: 0,
  bottom: 0,
  marginLeft: "20px",
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center'
};

const App = () => {
  return (
    <AppProvider>
      <ApiMessageProvider>
        {/* <GlobalLoading loadingThickness="4" loadingSize={30} loadingSpeed={1} WrapperComponent={() => <div style={style} >Please wait....</div>} />/>*/}
        <GlobalLoading WrapperComponent={() => <div style={style} className='loader'><Spin tip="Please wait...." size="large">
          <div className="content-loader" />
        </Spin></div>} />
        <Router>
          <Routes>
            <Route path="/" element={<PrivateRoute element={<Layout>
              <Dashboard />
            </Layout>} />} />
            <Route
              path="/login"
              element={
                isAuthenticated() ? (
                  <Navigate to="/dashboard" replace={true} />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              path="/reset-password-by-email/:id"
              element={
                isAuthenticated() ? (
                  <Navigate to="/dashboard" replace={true} />
                ) : (
                  <ResetPassword />
                )
              }
            />
            <Route path="*" element={<Navigate to="/login" />} />
            <Route path="/dashboard" element={<PrivateRoute element={<Layout>
              <Dashboard />
            </Layout>} />} />
            <Route path="/client-details/:id" element={<PrivateRoute element={<Layout>
              <ClientDetails />
            </Layout>} />} />
            <Route path="/settings" element={<PrivateRoute element={<Layout>
              <Settings />
            </Layout>} />} />

          </Routes>

        </Router>
        <ApiMessages /> {/* Display API messages here */}
      </ApiMessageProvider>

    </AppProvider>
  );
};

const ApiMessages = () => {
  const { errorMessage, successMessage, setErrorMessage, setSuccessMessage } = useApiMessages();
  const alertStyle = {
    position: 'fixed',
    top: 20, // Set the top position to 0
    right: 20,
    minWidth: 200,
    width: 'max-content',
    zIndex: 9999999999, // Set a high z-index to ensure it's on top
  };

  useEffect(() => {

    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage('')
      }, 3000)
    }
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage('')
      }, 3000)
    }
  }, [successMessage, errorMessage])

  return (
    <div>
      {/* Display error message */}
      {errorMessage && <Alert message="Error" description={errorMessage} style={alertStyle} type="error" showIcon closable onClose={() => setErrorMessage('')} />}

      {/* Display success message */}
      {successMessage && <Alert message="Success" description={successMessage} style={alertStyle} type="success" showIcon closable onClose={() => setSuccessMessage('')} />}
    </div>
  );
};

export default App;