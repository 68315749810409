import React, { useState, useEffect } from "react";
import { Button, Form, Select } from "antd";
import { postRequest } from '../../../../services/apiService';
import { showLoading } from 'react-global-loading';
import { useApiMessages } from '../../../../context/ApiMessageContext';
import './index.scss';
import { UPDATE_FIRM_SETTING_API, GET_FIRM_SETTING_API } from "../../../../services/apiConstant";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
function Disclosure() {
    const [formDisclosure] = Form.useForm();
    const [data, setData] = useState(null);
    const { setErrorMessage, setSuccessMessage } = useApiMessages();
    const [textRequired, setTextRequired] = useState(0);

    const szReference = localStorage.getItem('szReference');

    const getWelcomeMessage = async (props) => {
        showLoading(true);
        try {
            const response = await postRequest(GET_FIRM_SETTING_API, {
                szReference,
            });

            showLoading(false);

            if (response?.code === 200) {
                setData(response?.data);
                formDisclosure.setFieldsValue(response?.data);
            } else {
                setErrorMessage("Something went wrong");
            }
        } catch (error) {
            showLoading(false);
            setErrorMessage("Something went wrong");
        }
    }
    useEffect(() => {
        getWelcomeMessage();
    }, []);
    const saveHandler = async () => {
        showLoading(true);
        try {
            const values = await formDisclosure.validateFields();
            console.log(values);
            const response = await postRequest(UPDATE_FIRM_SETTING_API, {
                szReference,
                ...values,
            });

            showLoading(false);

            if (response?.code === 200) {
                setSuccessMessage("Content updated successfully.");
                getWelcomeMessage();
                window.scrollTo(0, 0);
            } else {
                setErrorMessage("Something went wrong");
            }
        } catch (error) {
            showLoading(false);
            setErrorMessage("Something went wrong");
            console.log('Form Validation Error:', error);
        }
    }
    return (
        <div className="form-container">
            <Form
                form={formDisclosure}
                layout="vertical"
                name="basic-disclosure"
                wrapperCol={{ span: 16 }}
                style={{}}
                initialValues={{ remember: true }}
                onFinish={saveHandler}
                onFinishFailed={() => { }}
                autoComplete="off"
                className="form-section"
            >
                <Form.Item
                    name="firm_disclosure"
                    rules={[{ required: textRequired, message: "Please input your Disclosure text above" }]}
                    initialValue={data?.firm_disclosure || ''}
                >
                    <CKEditor
                        editor={ClassicEditor}
                        data={data?.firm_disclosure || ''}
                        onReady={(editor) => {

                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            formDisclosure.setFieldValue('firm_disclosure', data);
                        }}
                    />
                    <span style={{ color: '#516E80' }}>To ensure correct formatting, please Paste as Plain Text</span>

                </Form.Item>
                <Form.Item
                    name="iDonotIncludeInFNCGoal"
                    label="Do you want to include Disclosures in the Financial Plan?"
                    rules={[{ required: true, message: "Please Select value" }]}
                    initialValue={data?.iDonotIncludeInFNCGoal}
                >
                    <Select
                        options={[{ 'label': 'Yes', value: 1 }, { 'label': 'No', value: 0 }]}
                        onChange={(value) => setTextRequired(value)}
                    />
                </Form.Item>
                <Form.Item>
                    <Button className="primary-large-btn" htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </div>

    );
}

export default Disclosure;
