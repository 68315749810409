import { Table, Input, DatePicker } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { postRequest } from "../../../../services/apiService";
import {
  SPENDING_AND_ACCOUNT_API,
  UPDATE_CURRENT_SPENDING_ACCOUNT_API,
} from "../../../../services/apiConstant";
import { showLoading } from "react-global-loading";
import { CaretRightOutlined } from "@ant-design/icons";

import * as moment from "moment";
import { numberFormatBasic,capitalizeFirstLetter } from "../../../../utils/helper";
import "./index.scss";
import dayjs from "dayjs";
function CurrentBalance(props) {
  const params = useParams();

  const szReference = params?.id;
  console.log(szReference);

  const [dataSource, setDataSource] = useState(null);
  const [editId, setEditId] = useState(null);
  const [editValue, setEditValue] = useState(null);
  const [editField, setEditField] = useState(null);

  useEffect(() => {
    props.active === "1" && getSpendingAndAccountData();
  }, [props]);

  useEffect(() => {
    getSpendingAndAccountData();
  }, []);

  const savehandler = async (field) => {
    if (editValue && editId && field) {
      showLoading(true);
      try {
        const response = await postRequest(
          UPDATE_CURRENT_SPENDING_ACCOUNT_API,
          {
            szReference,
            id: editId,
            szName: field,
            szValue: editValue,
          }
        );
        showLoading(false);

        if (response?.code === 200) {
          setEditId(null);
          setEditValue(null);
          setEditField(null);
          getSpendingAndAccountData();
        }
      } catch (error) {
        showLoading(false);
        console.error(error);
      }
    } else {
      setEditId(null);
      setEditValue(null);
      setEditField(null);
    }
  };

  const setEditHandler = (item, field) => {
    setEditId(item.id);
    setEditField(field);
  };

  const getFieldEdibale = (record, field, _ = "") => {
    return (
      <>
        {(editId !== record.id || editField !== field) && (
          <div className="text-block" title="Click here to edit">
            <span
              className="text"
              onClick={() => {
                console.log(field);
                setEditHandler(record, field);
              }}
              style={{ cursor: "pointer" }}
            >
              {field === "interestRate"
                ? _
                  ? _ + "%"
                  : "-"
                : field.includes("Date")
                ? _
                  ? moment(_).format("MM/DD/YY")
                  : "-"
                : field.includes("mount") ||
                  field.includes("alance") ||
                  field.includes("redit")
                ? numberFormatBasic(_)
                : numberFormatBasic(_)}
            </span>
          </div>
        )}
        {editId === record.id && editField === field && (
          <span className="input-dev">
            {field.includes("Date") && (
              <DatePicker
                defaultValue={dayjs(_ ? _ : new Date())}
                onChange={(e) => setEditValue(e)}
                onBlur={() => savehandler(field)}
                format="MM/DD/YY"
              />
            )}
            {!field.includes("Date") && (
              <Input
                type="text"
                defaultValue={_}
                onChange={(e) => setEditValue(e.target.value)}
                suffix={
                  <CaretRightOutlined onClick={() => savehandler(field)} />
                }
              />
            )}
          </span>
        )}
      </>
    );
  };

  const columnCashAccount = [
   
    {
      title: "Bank Name",
      dataIndex: "bankName",
      key: "bankName",
      render: (_, record) => (_ ? _ : "-"),
    },
    {
      title: "Account Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (_ ? _ : "-"),
    },
    {
      title: "Account Type",
      dataIndex: "type",
      key: "type",
      render: (_, record) => (_ ? _ : "-"),
    },
    {
      title: "Current Balance",
      dataIndex: "availableBalanceAmount",
      key: "availableBalanceAmount",
      align: "center",
      render: (_, record) => (_ ? numberFormatBasic(_) : numberFormatBasic(0)),
    },
    {
      title: "Balance Date",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      width: 200,
      render: (_, record) => (_ ? moment(_).format("MM/DD/YY") : "-"),
    },
   
  ];
  const columnInvestmentAccount = [
    {
      title: "Bank Name",
      dataIndex: "bankName",
      key: "bankName",
      render: (_, record) => (_ ? _ : "-"),
    },
    {
      title: "Account Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (_ ? _ : "-"),
    },
    {
      title: "Account Type",
      dataIndex: "type",
      key: "type",
      render: (_, record) => (_ ? _ : "-"),
    },
    {
      title: "Current Balance",
      dataIndex: "currentBalance",
      key: "currentBalance",
      align: "center",
      render: (_, record) => (_ ? numberFormatBasic(_) : numberFormatBasic(0)),
    },
    {
      title: "Balance Date",
      dataIndex: "createdAt",
      align: "center",
      key: "createdAt",
      render: (_, record) => (_ ? moment(_).format("MM/DD/YY") : "-"),
    },
  ];

  const columnCreditAccount = [
    {
      title: "Bank Name",
      dataIndex: "bankName",
      key: "bankName",
      align: "center",
      render: (_, record) => (_ ? _ : "-"),
    },
    {
      title: "Description",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (_, record) => (_ ? _ : "-"),
    },
    {
      title: "Interest Rate",
      dataIndex: "interestRate",
      key: "interestRate",
      align: "center",
      render: (_, record) => getFieldEdibale(record, "interestRate", _ ? _ : 0),
    },
    {
      title: "Current balance",
      dataIndex: "currentBalance",
      key: "currentBalance",
      align: "center",
      render: (_, record) =>
        getFieldEdibale(record, "currentBalance", _ ? _ : 0),
    },
    {
      title: "Balance Date",
      dataIndex: "createdAt",
      align: "center",
      key: "createdAt",
      render: (_, record) => (_ ? moment(_).format("MM/DD/YY") : "_"),
    },
    {
      title: "Minimum Pmt",
      dataIndex: "paymentMinAmount",
      key: "paymentMinAmount",
      align: "center",
      render: (_, record) =>
        getFieldEdibale(record, "paymentMinAmount", _ ? _ : 0),
    },
    {
      title: "Due Date",
      dataIndex: "paymentDueDate",
      key: "paymentDueDate",
      align: "center",
      render: (_, record) => getFieldEdibale(record, "paymentDueDate", _),
    },
    {
      title: "Available Balance",
      dataIndex: "creditAvailableAmount",
      key: "creditAvailableAmount",
      align: "center",
      render: (_, record) =>
        getFieldEdibale(record, "creditAvailableAmount", _ ? _ : 0),
    },
    {
      title: "Credit Limit",
      dataIndex: "creditMaxAmount",
      key: "creditMaxAmount",
      align: "center",
      render: (_, record) => (_ ? numberFormatBasic(_) : numberFormatBasic(0)),
    },
  ];

  const columnLoanAccount = [
    {
      title: "bank Name",
      dataIndex: "bankName",
      key: "bankName",
      align: "center",
      render: (_, record) => (_ ? _ : "-"),
    },
    {
      title: "Debt Type",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (_, record) => (_ ? _ : "-"),
    },
    {
      title: "Interest Rate",
      dataIndex: "interestRate",
      key: "interestRate",
      render: (_, record) => getFieldEdibale(record, "interestRate", _ ? _ : 0),
      align: "center",
      display: "flex",
      justify: "center",
    },
    {
      title: "Current Balance",
      dataIndex: "availableBalanceAmount",
      key: "availableBalanceAmount",
      // render: (_, record) => (
      //   _? _ : 0
      // ),
      render: (_, record) =>
        getFieldEdibale(record, "availableBalanceAmount", _ ? _ : 0),
      align: "center",
    },
    {
      title: "Balance Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, record) => (_ ? moment(_).format("MM/DD/YY") : "_"),
      align: "center",
    },
    {
      title: "Payment",
      dataIndex: "paymentMinAmount",
      key: "paymentMinAmount",
      render: (_, record) =>
        getFieldEdibale(record, "paymentMinAmount", _ ? _ : 0),
      align: "center",
    },
    {
      title: "Due Date",
      dataIndex: "paymentDueDate",
      key: "paymentDueDate",
      align: "center",
      render: (_, record) =>
        getFieldEdibale(record, "paymentDueDate", _ ? _ : ""),
    },
  ];

  const getDescription = (record)=> {
    if(record?.type === "car" ||
    record?.type == "personalProperty" ||
    record?.type === "otherAst"){
      return record?.description
    }
    else if(record?.type === "2ndVacationProperty" ||
    record?.type === "primaryHome" ||
    record?.type === "investmentProperty"){
      return record?.nickName
    }
    else if(
          record?.type === "educationSaving" ||
          record?.type === "investment" ||
          record?.type === "retire" ||
          record?.type === "checkingAccount" ||
          record?.type == "savings" ||
          record?.type === "creditCard" ||
           record?.type === "studentLoan" ||
           record?.type === "carLoan" ||
           record?.type === "personalLoan" ||
           record?.type === "mortgage" ||
           record?.type === "homeEquityLoan" ||
           record?.type === "2ndVacationPropertyMortgage" ||
           record?.type === "investmentPropertyMortgage" ||
          record?.type === "cd"){
      return record?.bankName
    }
    else if(record?.type === "otherDebt"){
      return record?.debtName
    }
    else{
      return ""
    }

  }

  
  const getEstimatedValue = (record)=> {
    if(record?.type === "2ndVacationProperty" ||
    record?.type === "primaryHome" ||
    record?.type === "investmentProperty" ||
    record?.type === "personalProperty" ||
    record?.type === "otherAst" ||
    record?.type === "car" ||
    record?.type === "business"){
      return record?.estimatedValue
    }
    else if(record?.type === "educationSaving" ||
    record?.type === "investment" ||
    record?.type === "retire" ||
    record?.type === "checkingAccount" ||
    record?.type == "savings" ||
    record?.type === "cd"){
      return record?.currentBalance
    }
    else{
      return ""
    }

  }

  
  const getOutStanding = (record)=> {
    if(record?.type  === "medicalDebt" ||
    record?.type  === "debtInCollections" ||
    record?.type  === "familyBorrowing" ||
    record?.type  === "otherDebt"){
      return record?.amountOwed
    }
    else if(record?.type  === "creditCard" ||
    record?.type  === "studentLoan" ||
    record?.type  === "carLoan" ||
    record?.type  === "personalLoan" ||
    record?.type  === "mortgage" ||
    record?.type  === "homeEquityLoan" ||
    record?.type  === "2ndVacationPropertyMortgage" ||
    record?.type  === "investmentPropertyMortgage"){
      return record?.outstandingBalance
    }
   
    else{
      return ""
    }

  }

  const getMonthlyPayment = (record)=> {
    if(record?.type  !== "creditCard"){
      return record?.monthlyPayment
    }
    else {
      return record?.paymentMinAmount
    }
   

  }

  const columnOtherAssetAccount = [
    
    {
      title: "Type",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (_, record) => capitalizeFirstLetter(record?.name)
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "center",
      render: (_, record) => getDescription(record)
    },
    {
      title: "Estimated Value",
      dataIndex: "estimatedValue",
      key: "estimatedValue",
      align: "center",
      render: (_, record) =>  numberFormatBasic(getEstimatedValue(record))
      
    },
    {
      title: "Outstanding Balance",
      dataIndex: "outstandingBalance",
      key: "outstandingBalance",
      align: "center",
      render: (_, record) => numberFormatBasic(getOutStanding(record))
    },
    {
      title: "Monthly Payment",
      dataIndex: "monthlyPayment",
      key: "monthlyPayment",
      align: "center",
      render: (_, record) => numberFormatBasic(getMonthlyPayment(record))
    },
    {
      title: "APR",
      dataIndex: "interestRate",
      key: "interestRate",
      align: "center",
      render: (_, record) => (record?.interestRate||0)?.toFixed(2)+"%"
    },
    {
      title: "Monthly Property Taxes",
      dataIndex: "annualTaxes",
      key: "annualTaxes",
      align: "center",
      render: (_, record) => numberFormatBasic(record?.annualTaxes/12)
    },
    {
      title: "Monthly Dues",
      dataIndex: "annualFees",
      key: "annualFees",
      align: "center",
      render: (_, record) => numberFormatBasic(record?.annualFees/12)
    },
  ];

  const getSpendingAndAccountData = async () => {
    showLoading(true);
    try {
      const response = await postRequest(SPENDING_AND_ACCOUNT_API, {
        szReference,
      });
      showLoading(false);

      if (response?.code === 200) {
        setDataSource(response.data);
      }
    } catch (error) {
      showLoading(false);
      console.error(error);
    }
  };

  return (
    <>
      {dataSource && (
        <div className="planned-section">
          <div className="plan-inner">
            <h3>
              Income planned:{" "}
              <span>
                {numberFormatBasic(Math.abs(dataSource?.incomePlannedTotal))}
              </span>
            </h3>
            <div className="planned">
              <div className="planned-graph">
                <div
                  className={`actual income ${
                    Math.abs(dataSource?.incomePlannedActual) < 1 && "d-none"
                  }`}
                  style={{
                    width: `${
                      dataSource?.incomePlannedActual > 0
                        ? (dataSource?.incomePlannedActual /
                            dataSource?.incomePlannedTotal) *
                            100 >
                          11
                          ? (dataSource?.incomePlannedActual /
                              dataSource?.incomePlannedTotal) *
                            100
                          : 12
                        : 0
                    }%`,
                  }}
                >
                  <p>
                    Actual:{" "}
                    <span>
                      {numberFormatBasic(
                        Math.abs(dataSource?.incomePlannedActual)
                      )}
                    </span>
                  </p>
                </div>
                <div
                  className="remaining"
                  style={{
                    width:
                      Math.abs(dataSource?.incomePlannedActual) < 1
                        ? "100%"
                        : `${
                            (dataSource?.incomePlannedRemaining /
                              dataSource?.incomePlannedTotal) *
                              100 >
                            15
                              ? (dataSource?.incomePlannedRemaining /
                                  dataSource?.incomePlannedTotal) *
                                100
                              : 15
                          }%`,
                  }}
                >
                  <p>
                    Remaining:{" "}
                    <span>
                      {numberFormatBasic(
                        Math.abs(dataSource?.incomePlannedRemaining)
                      )}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="plan-inner">
            <h3>
              Bills planned:{" "}
              <span>
                {numberFormatBasic(Math.abs(dataSource?.billsPlannedTotal))}
              </span>
            </h3>
            <div className="planned ">
              <div className="planned-graph">
                <div
                  className={`actual bill ${
                    Math.abs(dataSource?.billsPlannedActual) < 1 && "d-none"
                  }`}
                  style={{
                    width: `${
                      (dataSource?.billsPlannedActual /
                        dataSource?.billsPlannedTotal) *
                        100 >
                      11
                        ? (dataSource?.billsPlannedActual /
                            dataSource?.billsPlannedTotal) *
                          100
                        : 12
                    }%`,
                  }}
                >
                  <p>
                    Actual:{" "}
                    <span>
                      {numberFormatBasic(
                        Math.abs(dataSource?.billsPlannedActual)
                      )}
                    </span>
                  </p>
                </div>
                <div
                  className="remaining"
                  style={{
                    width:
                      Math.abs(dataSource?.billsPlannedActual) < 1
                        ? "100%"
                        : `${
                            (dataSource?.billsPlannedRemaining /
                              dataSource?.billsPlannedTotal) *
                              100 >
                            15
                              ? (dataSource?.billsPlannedRemaining /
                                  dataSource?.billsPlannedTotal) *
                                100
                              : 15
                          }%`,
                  }}
                >
                  <p>
                    Remaining:{" "}
                    <span>
                      {numberFormatBasic(
                        Math.abs(dataSource?.billsPlannedRemaining)
                      )}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="plan-inner">
            <h3>
              Spending planned:{" "}
              <span>
                {numberFormatBasic(Math.abs(dataSource?.spendingPlannedTotal))}
              </span>
            </h3>
            <div className="planned ">
              <div className="planned-graph">
                <div
                  className={`actual spending ${
                    Math.abs(dataSource?.spendingPlannedActual) < 1 && "d-none"
                  }`}
                  style={{
                    width: `${
                      (dataSource?.spendingPlannedActual /
                        dataSource?.spendingPlannedTotal) *
                        100 >
                      11
                        ? (dataSource?.spendingPlannedActual /
                            dataSource?.spendingPlannedTotal) *
                          100
                        : 12
                    }%`,
                  }}
                >
                  <p>
                    Actual:{" "}
                    <span>
                      {numberFormatBasic(
                        Number(Math.abs(dataSource?.spendingPlannedActual))
                      )}
                    </span>
                  </p>
                </div>
                <div
                  className="remaining"
                  style={{
                    width:
                      Math.abs(dataSource?.spendingPlannedActual) < 1
                        ? "100%"
                        : `${
                            (dataSource?.spendingPlannedRemaining /
                              dataSource?.spendingPlannedTotal) *
                              100 >
                            15
                              ? (dataSource?.spendingPlannedRemaining /
                                  dataSource?.spendingPlannedTotal) *
                                100
                              : 15
                          }%`,
                  }}
                >
                  <p>
                    Remaining:{" "}
                    <span>
                      {numberFormatBasic(
                        Math.abs(dataSource?.spendingPlannedRemaining)
                      )}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="divider"></div>
      <div className="formdiv current-spending-table">
        <div className="table-heading">Cash Accounts</div>
        <Table
          dataSource={dataSource?.cashAccounts}
          columns={columnCashAccount}
          pagination={false}
          size="small"
          className="cash-table"
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell
                  index={0}
                  align="left"
                  className="cash-total"
                >
                  {" "}
                  <span>Total:</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={0} align="center" colSpan={2}>
                  {" "}
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={0}
                  align="center"
                  className="cash-total"
                >
                  <span>
                    {numberFormatBasic(dataSource?.cashAccountsTotal || 0)}
                  </span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={0} align="center" colSpan={3}>
                  {" "}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </div>
      <div className="formdiv current-spending-table">
        <div className="table-heading">Investment Accounts</div>
        <Table
          dataSource={dataSource?.investmentAccounts}
          columns={columnInvestmentAccount}
          pagination={false}
          size="small"
          className="cash-table"
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell
                  index={0}
                  align="left"
                  className="cash-total"
                >
                  {" "}
                  <span>Total:</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={0}
                  align="center"
                  colSpan={2}
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={0}
                  align="center"
                  className="cash-total"
                >
                  <span>
                    {numberFormatBasic(
                      dataSource?.investmentAccountsCurrentBalance || 0
                    )}
                  </span>
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={0}
                  align="center"
                  colSpan={2}
                ></Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </div>
      <div className="formdiv current-spending-table">
        <div className="table-heading">Credit Card/Line of Credit</div>
        <Table
          className="scroll credit-table"
          dataSource={dataSource?.creditAccounts}
          columns={columnCreditAccount}
          pagination={false}
          size="small"
          summary={() =>
            dataSource?.creditAccounts?.length > 0 && (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell
                    index={0}
                    align="center"
                    className="cash-total"
                  >
                    <span>Total:</span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="center" colSpan={2}>
                    {" "}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="center" className="cash-total">
                    <span>
                      {numberFormatBasic(
                        dataSource?.creditAccountsCurrentBalance || 0
                      )}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={0} align="center">
                    {" "}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={0}
                    align="center"
                    className="cash-total"
                  >
                    <span>
                      {numberFormatBasic(
                        dataSource?.creditAccountsPaymentMinAmount || 0
                      )}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={0} align="center">
                    {" "}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={0}
                    align="center"
                    className="cash-total"
                  >
                    <span>
                      {numberFormatBasic(
                        dataSource?.creditAccountsAvailableAmount || 0
                      )}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={0}
                    align="center"
                    className="cash-total"
                  >
                    <span>
                      {numberFormatBasic(
                        dataSource?.creditAccountsMaxAmount || 0
                      )}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={0}
                    align="center"
                    className="cash-total"
                  ></Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )
          }
        />
      </div>
      <div className="formdiv current-spending-table">
        <div className="table-heading">Mortgage/Loan</div>
        <Table
          className="scroll credit-table"
          dataSource={dataSource?.mortgageAccounts}
          columns={columnLoanAccount}
          pagination={false}
          size="small"
          summary={() =>
            dataSource?.mortgageAccounts?.length > 0 && (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell
                    index={0}
                    align="center"
                    className="cash-total"
                  >
                    {" "}
                    <span>Total:</span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="center" colSpan={2}>
                    {" "}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={3}
                    align="center"
                    className="cash-total"
                  >
                    <span>
                      {numberFormatBasic(
                        dataSource?.mortgageAccountsCurrentBalance || 0
                      )}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={0} align="center">
                    {" "}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={0}
                    align="center"
                    className="cash-total"
                  >
                    <span>
                      {numberFormatBasic(
                        dataSource?.mortgageAccountsPaymentMinAmount || 0
                      )}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={0} align="center" colSpan={5}>
                    {" "}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )
          }
        />
      </div>
      <div className="formdiv current-spending-table">
        <div className="table-heading">
          Other Debt/Assets Accounts Not Connected
        </div>
        <Table
          className="scroll"
          dataSource={dataSource?.debtassetNotConnectedAccounts}
          columns={columnOtherAssetAccount}
          pagination={false}
          size="small"
        />
      </div>
    </>
  );
}

export default CurrentBalance;
