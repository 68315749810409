import React, { useEffect, useState } from 'react';
import { Button, Select, Spin, Space, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { postRequest } from '../../services/apiService';
import { CLIENT_LIST_API, UPDATE_CLIENT_STATUS_API, UPDATE_CLIENT_FINANCE_STATUS_API } from '../../services/apiConstant';
import { useNavigate } from 'react-router-dom';
import { useApiMessages } from '../../context/ApiMessageContext';
import { showLoading } from 'react-global-loading';
import { maskedEmail } from '../../utils/helper';
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal';

const Dashboard = () => {
    const { setErrorMessage, setSuccessMessage } = useApiMessages();
    const [clientList, setClientList] = useState([]);
    const navigate = useNavigate();
    const [szRef, setSzRef] = useState();
    const [isInviteOpen, setIsInviteOpen] = useState(false);
    const [sortField, setSortField] = useState(localStorage.getItem('sortField') || 'first_name');
    const [sortValue, setSortValue] = useState(localStorage.getItem('sortValue') || 0);
    const [isConnectedSort, setConnectedSort] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    console.log('dashboard reloading');

    useEffect(() => {
        document.title = 'Client Dashboard';
        window.scrollTo(0, 0)
        !isConnectedSort && getClientList();
        sessionStorage.setItem("activeDetails", 0)

    }, [sortField, sortValue]);

    const getClientList = async () => {


        console.log(sortField, sortValue)
        try {
            showLoading(true)
            const szReference = localStorage.getItem('szReference');
            const response = await postRequest(CLIENT_LIST_API, {
                szReference,
                page: 1,
                sortField: sortField,
                sortValue: sortValue
            });

            if (response?.code === 200) {
                setClientList(response?.data);
            }
            showLoading(false)
        } catch (error) {
            console.error(error);
        }
    };

    const sort = (field, value) => {
        localStorage.setItem("sortField", field);
        localStorage.setItem("sortValue", value ? 1 : 0);
        setSortField(field);
        setSortValue(value ? 1 : 0);

        if (field === 'connected_account') {
            setConnectedSort(true);

            showLoading(true);

            if (value) {
                let clList = clientList.sort((a, b) => a?.connectedAccount?.charCodeAt(0) - b?.connectedAccount?.charCodeAt(0));
                console.log(clList);
                setClientList(clList);
                showLoading(false);
            } else {
                let clList = clientList.sort((a, b) => b?.connectedAccount?.charCodeAt(0) - a?.connectedAccount?.charCodeAt(0));
                console.log(clList);
                setClientList(clList);
                showLoading(false);
            }

        } else {
            setConnectedSort(false);
        }

    }

    const handleOk = () => {
        setIsInviteOpen(false);
    };

    const handleCancel = () => {
        setIsInviteOpen(false);
    };

    useEffect(() => {
        if (!isDelete) {
            setSzRef()
        }
    }, [isDelete])

    const statusProps = {
        items: [
            {
                label: 'New Client',
                value: 'new'
            },
            {
                label: 'Under Review',
                value: 'review',
            },
            {
                label: 'Plan Completed',
                value: 'completed',
            },
            {
                label: 'Plan Review',
                value: 'plan-review',
            },
            {
                label: 'Needs Updating',
                value: 'needs-updating',
            },
        ],
        mains: [
            {
                label: 'Enable',
                value: 'enable'
            },
            {
                label: 'Disable',
                value: 'disable',
            },
            {
                label: 'Delete',
                value: 'delete',
            }
        ],
    };

    const updateFinanceStatus = async (szReference, status) => {
        try {
            const response = await postRequest(UPDATE_CLIENT_FINANCE_STATUS_API, {
                szReference: szReference,
                financeStatus: status,
            });
            if (response.code === 200) {
                setSuccessMessage("Updated Successfully");
                getClientList();
            }
            else {
                setErrorMessage("Something went wrong!");
            }
        } catch (error) {
            console.error(error);
        }
    }
    const goToClientDetails = (client) => {
        sessionStorage.setItem('currentClient', client.szReference);
        navigate('/client-details/' + client.szReference);
    }

    const onUpdate = async (szReference, status) => {
        if (status === "delete" && !isDelete) {
            setIsDelete(true)
            setSzRef(szReference);
        }
        else {
            try {
                setSzRef(szReference);

                showLoading(true)
                const response = await postRequest(UPDATE_CLIENT_STATUS_API, {
                    szReference,
                    status,
                });

                if (response.code === 200) {
                    setIsDelete(false)
                    setSuccessMessage("Updated Successfully");
                    getClientList();
                }
                else {
                    setErrorMessage("Something went wrong!");
                }
                showLoading(false);
                setSzRef();

            } catch (error) {
                console.error(error);
            }
        }

    };

    const orderUp = (down = 0) => {
        if (down) {
            return <svg xmlns="http://www.w3.org/2000/svg" width="13" height="7" viewBox="0 0 13 7" fill="none" style={{ "transform": "rotate(180deg)" }}>
                <line x1="4" y1="6.5" x2="9" y2="6.5" stroke="#24516D" strokeOpacity="0.8" />
                <line x1="2" y1="3.5" x2="11" y2="3.5" stroke="#24516D" strokeOpacity="0.8" />
                <line y1="0.5" x2="13" y2="0.5" stroke="#24516D" strokeOpacity="0.8" />
            </svg>;
        } else {
            return <svg xmlns="http://www.w3.org/2000/svg" width="13" height="7" viewBox="0 0 13 7" fill="none"  >
                <line x1="4" y1="6.5" x2="9" y2="6.5" stroke="#24516D" strokeOpacity="0.8" />
                <line x1="2" y1="3.5" x2="11" y2="3.5" stroke="#24516D" strokeOpacity="0.8" />
                <line y1="0.5" x2="13" y2="0.5" stroke="#24516D" strokeOpacity="0.8" />
            </svg>;
        }

    }

    return (

        <div className='bodymaincontainer dashboard-section'>
            <div className='bodyContainer'>
                <div className='bodycontainer2'>
                    <p className='clienttext'>Client Dashboard</p>
                </div>
                <div className='bodybutton'>
                    <Button
                        type='primary'
                        block
                        className='primary-medium-btn'
                        onClick={() => setIsInviteOpen(true)}
                    >Invite a client
                    </Button>
                </div>
            </div>
            <div className='divider-heading'></div>
            <div className='client-table'>
                <table>
                    <thead>
                        <tr>
                            <th onClick={() => sort('first_name', !sortValue)} > <p className='headertext'>CLIENT NAME<span className="sort-icon" >{sortField === 'first_name' && sortValue ? orderUp() : orderUp(1)}</span></p></th>
                            <th onClick={() => sort('connected_account', !sortValue)}>
                                <p className='headertext'>CONNECTED ACCOUNTS<span className="sort-icon" >{sortField === 'connected_account' && sortValue ? orderUp() : orderUp(1)}</span></p>
                            </th>
                            <th onClick={() => sort('questionnaireStatus', !sortValue)}>
                                <p className='headertext'>COMPLETED QUESTIONNAIRE<span className="sort-icon" >{sortField === 'questionnaireStatus' && sortValue ? orderUp() : orderUp(1)}</span></p>
                            </th>
                            <th onClick={() => sort('financeStatus', !sortValue)}>
                                <p className='headertext'>CURRENT STATUS<span className="sort-icon" >{sortField === 'financeStatus' && sortValue ? orderUp() : orderUp(1)}</span> </p>
                            </th>
                            <th> <p className='headertext'>Actions</p></th>
                        </tr>
                    </thead>

                    {clientList?.map((item, index) => (
                        <tbody key={index}>
                            <tr >
                                <td onClick={() => goToClientDetails(item)}>
                                    {item.first_name ? (
                                        <p className='nametext'>
                                            {item.first_name} {item.last_name}
                                        </p>
                                    ) : (
                                        <p className='nametext'> {maskedEmail(item.email)} </p>
                                    )}
                                </td>
                                <td>
                                    <p className='accounttext'>
                                        {item.connectedAccount}
                                    </p>
                                </td>
                                <td>
                                    <p className='accounttext'>
                                        {item.questionnaireStatus === 1 ? 'Yes' : 'No'}
                                    </p>
                                </td>
                                <td>
                                    <Space wrap>
                                        <Select
                                            value={item.financeStatus}
                                            style={{ minWidth: 160 }}
                                            onChange={(e) => { updateFinanceStatus(item.szReference, e) }}
                                            options={statusProps?.items}
                                            className={`buttonWithStyles review-dropdown ${item.financeStatus}`}
                                        />
                                    </Space>
                                </td>
                                <td>
                                    {(szRef === item?.szReference) ? (
                                        <div className='part5'>
                                            <Spin indicator={<LoadingOutlined />} />
                                        </div>
                                    ) : (
                                        <Select placeholder="Actions" onChange={(e) => { onUpdate(item?.szReference, e) }} className='part5' value={item?.is_deleted ? 'delete' : (item?.is_active ? 'enable' : 'disable')} options={statusProps.mains}></Select>
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    ))}

                </table>
            </div>
            <Modal title="Invite your Client" className='invite-modal' open={isInviteOpen} onOk={handleOk} onCancel={handleCancel} footer={[]}>
                <div className='modal-text'> <p>Send the below  link to your Clients to create their unique profile:</p>
                    <a href="https://app.fisecal.com/login" target='_newtab'>https://app.fisecal.com/login</a>
                </div>

            </Modal>
            {isDelete && <DeleteConfirmationModal desc={'client'} isModalVisible={isDelete} setIsModalVisible={setIsDelete} handleDelete={() => onUpdate(szRef, "delete")} />}
        </div>
    );
};

export default Dashboard;