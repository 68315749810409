import React, { useEffect, useState } from 'react';
import { Radio, Button, Form, Input } from 'antd';
import { UPDATE_CLIENT } from '../../../services/apiConstant';
import { useApiMessages } from '../../../context/ApiMessageContext';
import { useAppContext } from '../../../context/AppContext';
import { useParams } from 'react-router-dom';
import { postRequest } from '../../../services/apiService';
import { showLoading } from 'react-global-loading';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

import { validateEmail, OnlyCharAndSpace, onlyNumber, maskedEmail } from '../../../utils/helper';

function PersonalInformation({ data }) {
    const [value, setValue] = useState('');
    const [rentoption, setRentOption] = useState("");
    const [form] = Form.useForm();
    const [dependent, setDependent] = useState(0);

    const { setErrorMessage, setSuccessMessage } = useApiMessages();
    const { activeDetails, setActiveDetails, setToggle, toggle } = useAppContext();
    const [rentLabel, setRentLabel] = useState("Do you rent or own?")
    const params = useParams();


    const szReference = params?.id;

    const handleIncrement = () => {
        setDependent(dependent + 1);
    };

    const handleDecrement = () => {
        if (dependent > 0) {
            setDependent(dependent - 1);
        }
    };

    const onChangerent = (e) => {
        setRentOption(e.target.value);
    };

    useEffect(() => {
        setActiveDetails(0);
        console.log(activeDetails);
    }, [activeDetails])

    const handleSave = async () => {
        showLoading(true);

        try {
            const values = await form.validateFields();
            delete values['email'];
            const response = await postRequest(UPDATE_CLIENT, {
                szReference,
                ...values,
                no_of_dep_for_tax_return: dependent
            });

            showLoading(false);

            if (response?.code === 200) {
                setSuccessMessage("Saved Successfully");
                setToggle(!toggle);
                // navigate(-1);
            } else {
                setErrorMessage("Something went wrong");
            }
        } catch (error) {
            showLoading(false);
            setErrorMessage("Something went wrong");
            console.log('Form Validation Error:', error);
        }
    };
    const InputField = ({ name, label, required, className, disabled = false }) => (
        <Form.Item
            className={'formtitle'}
            name={name}
            label={label}
            validateFirst={false}
            rules={[
                {
                    required: required,
                    message: `Please enter your ${label?.toLowerCase()?.replace("your", "")}`,
                    whitespace: true
                },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (name === "age" || name === 'partner_age') {
                            if (!value || onlyNumber(value)) {

                                return Promise.resolve(value);
                            } else if (!(onlyNumber(value))) {
                                value = value.replace(/[^+0-9]/g, '');
                                form.setFieldValue(name, value);
                                return Promise.resolve();
                            } else {
                                return Promise.reject(new Error('Please enter a valid number for age.'));
                            }

                        }
                        if (name === "partner_email") {
                            if (!value || validateEmail(value)) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Please enter a valid email.'));
                        }
                        if (['first_name', 'last_name', 'partner_first_name', 'partner_last_name'].includes(name)) {

                            if (!value || OnlyCharAndSpace(value)) {
                                return Promise.resolve();
                            } else if (!(OnlyCharAndSpace(value))) {

                                value = value.replace(/[^+A-Za-z\s]/g, '');
                                form.setFieldValue(name, value);
                                return Promise.resolve();
                            } else {
                                return Promise.reject(new Error('Please enter a valid value.'));
                            }

                        }
                        return Promise.resolve();

                    },
                }),
            ]}

        >
            <Input className={className || 'input1 w-100'} disabled={disabled} />
        </Form.Item>
    );

    const onFormValueChange = ({ is_info_sharable }) => {
        console.log(is_info_sharable);
        if (is_info_sharable) {
            if (is_info_sharable === 1) {
                setRentLabel("Do you and your partner rent or own?")
            }
            else {
                setRentLabel("Do you rent or own?")
            }
        }

    }

    useEffect(() => {
        document.title = 'Personal Information';
        window.scrollTo(0, 0)
        if (data) {
            console.log("===", data)
            form.setFieldsValue({ ...data, age: data?.age?.toString(), partner_age: data?.partner_age?.toString(), email: maskedEmail(data?.email) });
            setDependent(data?.no_of_dep_for_tax_return || 0);
            setValue(data?.is_info_sharable ? data?.is_info_sharable : '');
            onFormValueChange({ is_info_sharable: data?.is_info_sharable });
        }
    }, [data]);
    const SharableHandle = (e) => {
        console.log(e);
    }

    return (
        <>
            <div className='personalmainheader'>
                <div className='personaltextbackground'>
                    <p className='personaltext'>Personal Information</p>
                </div>
                <div className='line'></div>
                <div className='from-section'>
                    <div className='formdiv '>
                        <Form form={form} onValuesChange={onFormValueChange} name="personalInfoForm" layout="vertical" autoComplete="off" onFinish={handleSave} scrollToFirstError={{ behavior: 'smooth', block: 'center', inline: 'center' }} >
                            <Form.Item
                                className='formtitle rodio-btn-title'
                                label="Do you share your finances with someone else?"
                                name="is_info_sharable"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select an option',
                                    },
                                ]}

                            >
                                <Radio.Group onChange={(e) => setValue(e.target.value)}>
                                    <Radio value={1}>Yes</Radio>
                                    <Radio value={2}>No</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <div className='line5'></div>
                            <div className='name-input'>
                                <InputField name="first_name" label="Your first name" required />
                            </div>
                            <div className='name-input'>
                                <InputField name="last_name" label="Your last name" required />
                            </div>
                            <div className='name-input'>
                                <InputField name="age" label="Your age" required />
                            </div>
                            <div className='name-input'>
                                <InputField name="email" label="Your email" required className="personnel-form-email" disabled />
                            </div>
                            <div className='line2'></div>
                            {value !== 2 && <><div className='name-input'>
                                <InputField name="partner_first_name" label="Your partner's first name" required />
                            </div>
                                <div className='name-input'>
                                    <InputField name="partner_last_name" label="Your partner's last name" required />
                                </div>
                                <div className='name-input'>
                                    <InputField name="partner_age" label="Your partner's age" required />
                                </div>
                                <div className='name-input2'>
                                    <InputField name="partner_email" label="Your partner's email" />
                                    <p className='formtext'>If you want to share the Financial Plan with them</p>
                                </div>

                                <div className='line7'></div>
                            </>}
                            <div className='numbertext'>
                                <p className='formtext2'>Number of Dependents Claimed on Tax Return</p>
                            </div>

                            <div className='tongle-btn'>
                                <Button onClick={handleDecrement} className='d-flex align-items-center' icon={<MinusOutlined />} />
                                <p>{dependent}</p>
                                <Button onClick={handleIncrement} className='d-flex align-items-center' icon={<PlusOutlined style={{ fontSize: 12 }} />} />
                            </div>

                            <Form.Item
                                className='formtitle radio-text'
                                label={rentLabel}
                                name="rent_or_own"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select an option',
                                    },
                                ]}
                                initialValue={rentoption}
                            >
                                <Radio.Group onChange={onChangerent}>
                                    <Radio value={"rent"}><p className='formtext4'>Rent</p></Radio>
                                    <Radio value={"own"}><p className='formtext4'>Own</p></Radio>
                                    <Radio value={'neither'}><p className='formtext4'>Neither</p></Radio>
                                </Radio.Group>
                            </Form.Item>

                            <div className='line3'></div>
                            <div className='informationtext'>
                                <p className='formtext3'>Any Other Info</p>
                            </div>
                            <div className='lablediv'>
                                <p className='labletext'>Is there anything else we should know about you or your unique financial picture?</p>
                            </div>
                            <Form.Item
                                name="other_info"
                            >
                                <Input.TextArea rows={4} className='inputtext' style={{ resize: 'none' }} />
                            </Form.Item>

                            <div className='reasonsdiv'>
                                <p className='reasontext'>Reasons for Financial Guidance</p>
                            </div>
                            <div className='reasonstextdiv'>
                                <p className='reasontexts'>Why are you seeking financial guidance? Give us as many details as you'd like-the more we know the more we can help!</p>
                            </div>
                            <Form.Item
                                name="reason_for_finance_guide"
                            >
                                <Input.TextArea rows={4} className='inputtext' style={{ resize: 'none' }} />
                            </Form.Item>
                            <div className='line6'></div>

                            <Form.Item>
                                <Button className='primary-medium-btn' htmlType="submit">
                                    Save
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                    {/* right-section */}
                    <div className='ant-notification-notice-content'>
                        <div className='alert'>
                            Key personal information - please check contact details to ensure the client receives their financial plan.
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default PersonalInformation;
