import React from 'react';
function Header() {
    return (
        <div className='headercontainer'>
             <div className='login-header' >
                <img  src={require('../assets/images/logo.png')} alt="react logo" height={40} width={120} />

            </div>
        </div>
    )
}
export default Header;