import React from 'react';
import { Modal } from 'antd';

function DeleteConfirmationModal({isModalVisible, setIsModalVisible,handleDelete,desc}) {
  
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <Modal
        title="Delete Confirmation"
        open={isModalVisible}
        onOk={handleDelete}
        onCancel={handleCancel}
        okText="Delete"
        cancelText="Cancel"
      >
        <p>Are you sure you want to delete this {desc}?</p>
      </Modal>
    </div>
  );
}

export default DeleteConfirmationModal;
