import React, { useState, useEffect } from 'react';
import './Layout.css';
import SideNavComponent from './components/SideNavComponent';
import HeaderAfterLogin from './components/HeaderAfterLogin';
import { Drawer } from 'antd';

const Layout = ({ children }) => {
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  // Add a state variable to track whether the viewport is in mobile mode
  const [isMobile, setIsMobile] = useState(false);

  // Function to handle window resize and update the isMobile state
  const handleResize = () => {
    setIsMobile(window.innerWidth < 1130); // Adjust the breakpoint as needed
  };

  useEffect(() => {
    // Set the initial isMobile state when the component mounts
    handleResize();

    // Attach a resize event listener to update isMobile state
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='main-container'>
      <HeaderAfterLogin setOpen={setOpen} isMobile={isMobile} />

      <div className="layout-container">
        {isMobile ? (
          <Drawer contentWrapper title="" closeIcon={true} headerStyle={{ display: 'none' }} placement="left" onClose={onClose} open={open}>
            <SideNavComponent setOpen={setOpen} isMobile={isMobile} />
          </Drawer>
        ):(
            <SideNavComponent />
        )}

        <div className="content">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
