
import { Tabs } from 'antd';
import WelcomeMessage from './WelcomeMessage';
import Conclusion from './Conclusion';
import Disclosure from './Disclosure';
function LanguageSettings() {
    document.title = 'Financial Plan Language';
    window.scrollTo(0, 0);
    const items = [
        {
            key: '1',
            label: 'Welcome Message',
            children: <WelcomeMessage fieldName="firm_welcome_message" />,
        },
        {
            key: '8',
            label: 'Conclusion',
            children: <Conclusion fieldName="firm_conclusion" />,
        },
        {
            key: '9',
            label: 'Disclosure',
            children: <Disclosure fieldName="firm_disclosure" />,
        },
    ];
    return (
        <>
            <div className='personalmainheader'>
                <div className='personaltextbackground'>
                    <p className='personaltext'>Financial Plan Language</p>
                </div>
                <Tabs defaultActiveKey="1" items={items} onChange={() => { }} />
            </div>
        </>
    )
}

export default LanguageSettings;