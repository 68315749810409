import React, { useEffect, useState } from 'react';
import { Tabs} from 'antd';
import CurrentBalance from './CurrentBalance';
import MonthlySpend from './MonthlySpend';

function SpendingAndAccount() {
    const [tabKey, setTabKey] = useState(1);
    const [width, setWidth] = useState(0);

    const items = [
        {
            key: '1',
            label: 'Current Balances',
            children: <CurrentBalance active={tabKey}/>,
        },
        {
            key: '2',
            label: 'Monthly Spending Table',
            children: <MonthlySpend active={tabKey}/>,
        },
    ];

    const onChange = (key, e) => {
        setTabKey(key);
    };

    useEffect(() => {
        document.title = 'Current Spending and Account Balances';
        window.scrollTo(0, 0);
        // getData();
        if(window.innerWidth<1024){
            setWidth('unset')
        }
        else{
            setWidth(window.screen.width-464)

        }
    }, [tabKey]);

    return (
        <>
            <div className='personalmainheader' style={{width}}>
                <div className='personaltextbackground'>
                    <p className='personaltext'>Current Spending and Account Balances</p>
                </div>
                <div className='line'></div>
                <Tabs defaultActiveKey="1" items={items} onTabClick={onChange} />

            </div>
        </>
    );
}

export default SpendingAndAccount;
