import React, { useEffect, useState } from 'react';

import PersonalInformation from './PersonalInformation';
import SpendingAndAccount from './SpendingAndAccount';
import { useAppContext } from '../../context/AppContext';
import MeetingHistory from './MeetingHistory';
import { postRequest } from '../../services/apiService';
import { GET_CLIENT_DETAIL } from '../../services/apiConstant';
import { useParams } from 'react-router-dom';
import { Avatar, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { maskedEmail } from '../../utils/helper';
import FinancialGoals from './FinancialGoals';
import InsuranceInformation from './InsuranceInfomation';
import Questionnaire from './Questionnaire';
import Recommendations from './Recommendations';
import FinancialPlan from './FinancialPlan';

function ClientDetails() {

    const { activeDetails, toggle, setActiveDetails, setToggle } = useAppContext();
    const [data, setData] = useState({})
    const [name, setName] = useState();
    const [firstName, setFirstName] = useState('')
    const params = useParams();
    const szReference = params?.id;


    const getData = async () => {
        try {
            const response = await postRequest(GET_CLIENT_DETAIL, {
                szReference
            });

            if (response?.code === 200) {
                let fName = response?.data?.first_name || "";
                let lName = response?.data?.last_name || "";
                if (!fName && !lName) {
                    setName(`${maskedEmail(response?.data?.email)}`);
                    setFirstName("Client's")
                } else {
                    setName(`${fName} ${lName}`);
                    setFirstName(fName + "'s")
                }
                setData(response?.data)
            }
        } catch (error) {
            console.error(error);
        }
    };


    useEffect(() => {
        setActiveDetails(parseInt(sessionStorage.getItem("activeDetails") || 0))
        return () => {
            setActiveDetails(0);
            setToggle(0);
        }
    }, [])
    useEffect(() => {
        getData();
    }, [activeDetails, toggle])
    return (
        <div className='bodymaincontainer'>
            <div className='personalmaindev'>
                <div className='personalheader'>

                    <div className='personalheadertext'>
                        <Space wrap size={16}>
                            {data?.avatar && <Avatar size={40} icon={require(data?.avatar)} />}
                            {!data?.avatar && <Avatar size={40} icon={<UserOutlined />} />}
                        </Space>
                        <p>{name}</p></div>
                </div>
                {activeDetails === 0 && (<PersonalInformation {...{ data, setData }} userData={data} />)}
                {activeDetails === 2 && (<SpendingAndAccount userData={data} />)}
                {activeDetails === 1 && (<MeetingHistory userData={data} />)}
                {activeDetails === 3 && (<FinancialGoals firstName={firstName} userData={data} />)}
                {activeDetails === 4 && (<InsuranceInformation {...{ data }} userData={data} />)}
                {activeDetails === 5 && (<Questionnaire userData={data} />)}
                {activeDetails === 6 && (<Recommendations userData={data} />)}
                {activeDetails === 7 && (<FinancialPlan firstName={firstName} userData={data} />)}
            </div>
        </div>
    );
}

export default ClientDetails;