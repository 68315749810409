import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { sidenav } from '../Constant';
import { useAppContext } from '../context/AppContext';

function SideNavComponent({ setOpen, isMobile }) {
    const location = useLocation();
    let pathname = location?.pathname?.replace('/', "") || "";
    const navigate = useNavigate();
    if (pathname?.includes("client-details")) {
        pathname = "personal"
    }
    const data = sidenav[pathname || 'dashboard'];
    const { activeDetails, setActiveDetails } = useAppContext();
    const onNavSelect = (item) => {
        if (item.name === 'Logout') {
            localStorage.clear();
            window.location.href = '/login';
        } else {
            sessionStorage.setItem("activeDetails", item.active);
            setActiveDetails(item.active);
            item.navigator && navigate(item.navigator);
            isMobile && setOpen(false);
        }
    };

    const onBackNavigate = () => {
        isMobile && setOpen(false);
        navigate('/dashboard');
    };

    return (
        <div className='sidenavContainer'>
            {pathname !== "dashboard" && (
                <>
                    <div className='w-100 d-flex align-items-center cursor-pointer' onClick={onBackNavigate}>
                        <ArrowLeftOutlined style={{ color: '#fff', fontSize: 24, marginRight: 12 }} />
                        <p className='navtext'>Back to Client Dashboard</p>
                    </div>
                    <div style={{ width: '100%', marginBottom: 15, height: 0.1, opacity: 0.20, border: '1px white solid' }}></div>
                </>
            )}
            <div className={`sidebar thin-scroll-x ${pathname === 'dashboard' && 'main-sidebar-height'}`}>
                <div className='nav-contain'>
                    {data.map((item, index) => (
                        <div onClick={() => onNavSelect(item)} key={index} className={`${activeDetails === item.active ? "active" : ""} sidenavinput`} >
                            {item.icon}
                            <p className={`navtext ${!item.icon && 'sub-nav-text'}`}>{item.name}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className='sidebar-footer-logo'>
                <div onClick={() => onNavSelect({ name: 'Logout', active: 2 })} className={`sidenavinput`} >
                    <img src={require('../assets/images/log-out-icon.png')} alt="" />
                    <p className='navtext'>Logout</p>
                </div>
            </div>
        </div>
    );
}

export default SideNavComponent;
