function PlanSettings(){
    return (
        <>
        <div className='personalmainheader'>
            <div className='personaltextbackground'>
                <p className='personaltext'>Plan Setting</p>
            </div>
            <div className='line'></div>
            <div className='from-section'>
            <div className='formdiv'>

            </div>
            </div>
        </div>
    </>
    )
}

export default PlanSettings;