import React, { useState, useEffect } from "react";
import { Button, Form } from "antd";
import { postRequest } from '../../../../services/apiService';
import { showLoading } from 'react-global-loading';
import { useApiMessages } from '../../../../context/ApiMessageContext';
import './index.scss';
import { UPDATE_FIRM_SETTING_API, GET_FIRM_SETTING_API } from "../../../../services/apiConstant";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
function WelcomeMessage() {
    const [form] = Form.useForm();
    const [data, setData] = useState(null);
    const { setErrorMessage, setSuccessMessage } = useApiMessages();

    const szReference = localStorage.getItem('szReference');

    const getWelcomeMessage = async () => {
        showLoading(true);
        try {
            const response = await postRequest(GET_FIRM_SETTING_API, {
                szReference,
            });

            showLoading(false);

            if (response?.code === 200) {
                setData(response?.data);
                form.setFieldsValue(response?.data);
            } else {
                setErrorMessage("Something went wrong");
            }
        } catch (error) {
            showLoading(false);
            setErrorMessage("Something went wrong");
        }
    }
    useEffect(() => {
        getWelcomeMessage();
    }, []);
    const saveHandler = async () => {
        showLoading(true);
        try {
            const values = await form.validateFields();
            console.log(values);
            const response = await postRequest(UPDATE_FIRM_SETTING_API, {
                szReference,
                ...values,
            });

            showLoading(false);

            if (response?.code === 200) {
                setSuccessMessage("Content updated successfully.");
                getWelcomeMessage();
                window.scrollTo(0, 0);
            } else {
                setErrorMessage("Something went wrong");
            }
        } catch (error) {
            showLoading(false);
            setErrorMessage("Something went wrong");
            console.log('Form Validation Error:', error);
        }
    }
    return (
        <div className="form-container">
            <Form
                form={form}
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                onFinish={saveHandler}
                onFinishFailed={() => { }}
                autoComplete="off"
                className="form-section"
            >
                <Form.Item
                    name="firm_welcome_message"
                    rules={[{ required: true, message: "Please Enter welcome message" }]}
                    initialValue={data?.firm_welcome_message}
                >
                    <CKEditor
                        editor={ClassicEditor}
                        data={data?.firm_welcome_message || ''}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            form.setFieldValue('firm_welcome_message', data);
                        }}
                    />
                    <span style={{ color: '#516E80' }}>To ensure correct formatting, please Paste as Plain Text</span>
                </Form.Item>
                <Form.Item>
                    <Button className="primary-large-btn" htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </div>

    );
}

export default WelcomeMessage;
