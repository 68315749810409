// ApiMessageContext.js
import React, { createContext, useContext, useState } from 'react';

const ApiMessageContext = createContext();

const ApiMessageProvider = ({ children }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const clearMessages = () => {
    setErrorMessage('');
    setSuccessMessage('');
  };

  return (
    <ApiMessageContext.Provider
      value={{
        errorMessage,
        successMessage,
        setErrorMessage,
        setSuccessMessage,
        clearMessages,
      }}
    >
      {children}
    </ApiMessageContext.Provider>
  );
};

const useApiMessages = () => {
  return useContext(ApiMessageContext);
};

export { ApiMessageProvider, useApiMessages };