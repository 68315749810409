import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Radio,
  Select,
  InputNumber,
  Checkbox,
} from "antd";
import { useParams } from "react-router-dom";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { postRequest } from "../../../services/apiService";
import {
  DELETE_FINANCIAL_GOAL_API,
  GET_FINANCIAL_GOAL_API,
  GET_IMPORTANT_DATA,
  SAVE_FINANCIAL_GOAL_API,
  UPDATE_RECOMMENDATION,
} from "../../../services/apiConstant";
import { showLoading } from "react-global-loading";
import { useApiMessages } from "../../../context/ApiMessageContext";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal";
import {
  getOrdinalNumber,
  getInflationRate,
  getReturnRate,
  numberFormatBasic,
} from "../../../utils/helper";
import { debounce } from "lodash";

function FinancialGoals({ firstName, userData }) {
  const { id } = useParams();
  const [goals, setGoals] = useState([]);
  const [form] = Form.useForm();
  const [selectedGoal, setSelectedGoal] = useState(""); // Track selected goal type
  const [clickedIndex, setClickedIndex] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const { Option } = Select;
  const [deleteID, setDeleteId] = useState();
  const [deletedPensionSource, setDeletedPensionSource] = useState({});
  const [deletedOtherSource, setDeletedOtherSource] = useState({});
  const [isSaveInvalid, setIsSaveInvalid] = useState(false);
  const { setErrorMessage, setSuccessMessage } = useApiMessages();
  const [recText, setRecText] = useState({});
  const [annualRetiredIncome, setAnnualRetiredIncome] = useState({});
  const [monthlyRetiredIncome, setMonthlyRetiredIncome] = useState({});
  const [lumSumAmt, setLumsumAmt] = useState({});
  const [monthlyAmt, setMonthlyAmt] = useState({});
  const InvestTotal = 0;
  const [toalIncome, setTotalIncome] = useState();
  const [usedBudRag, setUsedBudRag] = useState({});
  const [loading, setIsLoading] = useState(true);
  const [cashFlow, setCashFlow] = useState({});
  const [fvSalary, setFvSalary] = useState({});
  const [pvRetirement, setPvRetirement] = useState({});
  const [fvRetirement, setFvRetirement] = useState({});
  const [netNeedMoney, setNetNewMoney] = useState({});

  const [minMonPmtMon, setMinMonPmtMon] = useState({});
  const [principalAfterFin, setPrincipalAfterFin] = useState({});
  const [goalMonthlyPayment, setGoalMonthlyPayment] = useState({});
  const [maxMonthlyPmt, setMaxMonthlyPmt] = useState({});
  const [actMonPayOff, setActMonPayOff] = useState({});
  const [roundedActMonPay, setRoundedActMonPay] = useState({});
  const [goalPmtAmt, setGoalPmtAmt] = useState({});

  const [isAdditional, setIsAdditional] = useState({});

  const items = [
    {
      label: "Debt Repayment - Credit Card",
      key: "1",
      value: "debtrepayment-creditcard",
    },
    {
      label: "Debt Repayment - Student Loan",
      key: "2",
      value: "debtrepayment-studentloan",
    },
    {
      label: "Debt Repayment - Personal Loan",
      key: "3",
      value: "debtrepayment-personalloan",
    },
    {
      label: "Retirement(Self)",
      key: "4",
      value: "retirement-self",
    },
    {
      label: "Retirement(Partner)",
      key: "5",
      value: "retirement-partner",
    },
    {
      label: "Car Purchase",
      key: "6",
      value: "carpurchase",
    },
    {
      label: "Wedding",
      key: "7",
      value: "wedding",
    },
    {
      label: "Dream Home/Apartment",
      key: "8",
      value: "dreamhome-apartment",
    },
    {
      label: "2nd/Investment/Vacation Property",
      key: "9",
      value: "2nd-investment-vacation-property",
    },
    {
      label: "Home Renovation",
      key: "10",
      value: "homerenovation",
    },
    {
      label: "Travel",
      key: "11",
      value: "travel",
    },
    {
      label: "Start a Business",
      key: "12",
      value: "start-a-business",
    },
    {
      label: "Medical Expenses",
      key: "13",
      value: "medical-expenses",
    },
    {
      label: "Other Major Purchase",
      key: "14",
      value: "other-major-purchase",
    },
  ];

  const ClientRateOfReturnLookup = 1; //hoardcoded

  const riskTolorenceScore = userData?.importantValues?.RiskTolorenceScore;

  const getPresetValueOfGoal = (M22, M23, M25, M16, M19) => {
    console.log({ M22, M23, M25, M16, M19 });

    return (M22 - M23) / Math.pow(1 + M25 / 12, M16 + M19);
  };

  useEffect(() => {
    console.log("lod===", loading);
    showLoading(loading);
  }, [loading]);

  const onChange = (e, name) => {
    setIsAdditional({ ...isAdditional, [name]: e.target.checked });
    console.log({ ...isAdditional, [name]: e.target.checked });
  };

  useEffect(() => {
    document.title = "Financial Goals";
    window.scrollTo(0, 0);
    if (userData?.accounts) {
      getImportantData();
    }
  }, [id, userData]);

  useEffect(() => {
    fetchData();
  }, [toalIncome]);

  const getCVofSavings = (
    LengthInYears2,
    PresentValueOfGoal,
    CurrentAssetsAvailableForGoals
  ) => {
    const Invest = InvestTotal;

    if (
      LengthInYears2 <= 2 &&
      CurrentAssetsAvailableForGoals >= PresentValueOfGoal
    ) {
      return PresentValueOfGoal;
    } else if (
      LengthInYears2 <= 2 &&
      CurrentAssetsAvailableForGoals < PresentValueOfGoal
    ) {
      return CurrentAssetsAvailableForGoals;
    } else if (
      LengthInYears2 > 2 &&
      Invest < PresentValueOfGoal &&
      Invest + CurrentAssetsAvailableForGoals >= PresentValueOfGoal
    ) {
      return PresentValueOfGoal - Invest;
    } else if (
      LengthInYears2 > 2 &&
      Invest < PresentValueOfGoal &&
      Invest + CurrentAssetsAvailableForGoals < PresentValueOfGoal
    ) {
      return CurrentAssetsAvailableForGoals;
    } else {
      return 0;
    }
  };

  function calculateMonthsToPayOff(
    loanAmount,
    annualInterestRate,
    monthlyPayment
  ) {
    const monthlyInterestRate = annualInterestRate / 12 / 100;

    const numerator = Math.log(
      1 - (loanAmount * monthlyInterestRate) / monthlyPayment
    );
    const denominator = Math.log(1 + monthlyInterestRate);

    console.log({ numerator, denominator });
    const numberOfPayments = Math.ceil(-numerator / denominator);

    return numberOfPayments || 0;
  }

  const getAstRecSuf = (
    MonthlyGoalSavingsAmount,
    CVInvestments,
    CVOfSavings,
    GoalType
  ) => {
    if (
      (MonthlyGoalSavingsAmount >= 0 &&
        getGoalType(GoalType) === 0 &&
        CVOfSavings > 0) ||
      (MonthlyGoalSavingsAmount >= 0 &&
        getGoalType(GoalType) === 0 &&
        CVInvestments > 0) ||
      (MonthlyGoalSavingsAmount >= 0 &&
        getGoalType(GoalType) === 0 &&
        CVOfSavings > 0 &&
        CVInvestments > 0)
    ) {
      return 1;
    } else {
      return 0;
    }
  };

  const getRGPmtRecSuf = (
    AstRecSuf,
    FutureValueOfGoal,
    GoalType,
    MonthlyGoalPaymentRequired,
    PtPmtRecSuf
  ) => {
    if (
      (FutureValueOfGoal > 0 &&
        MonthlyGoalPaymentRequired > 0.01 &&
        GoalType === 0 &&
        AstRecSuf === 1) ||
      (FutureValueOfGoal > 0 &&
        MonthlyGoalPaymentRequired > 0.01 &&
        GoalType === 0 &&
        PtPmtRecSuf === 1) ||
      (FutureValueOfGoal > 0 &&
        MonthlyGoalPaymentRequired > 0.01 &&
        GoalType === 0 &&
        AstRecSuf === 1 &&
        PtPmtRecSuf === 1)
    ) {
      return 1;
    } else {
      return 0;
    }
  };

  const calcRec = (values, val) => {
    const changeItem = val?.goals?.[values?.goals?.length - 1];
    const index = values?.goals?.length - 1;
    console.log("changeItem===", changeItem);
    console.log({ values, val });

    // getLumSumGoal(changeItem, values?.goals?.length - 1);

    setTimeout(() => {
      let initialValues = form.getFieldsValue();
      let allGoal = initialValues?.goals;

      let LengthInMonths2 =
        initialValues?.goals?.[index]?.repayType === "months"
          ? initialValues?.goals?.[index]?.repayIn || 0
          : (initialValues?.goals?.[index]?.repayIn || 0) * 12;

      console.log("in===", initialValues);
      if (
        values?.goals?.[index]?.repayIn ||
        values?.goals?.[index]?.repayType
      ) {
        LengthInMonths2 =
          initialValues?.goals?.[index]?.repayType === "months"
            ? values?.goals?.[index]?.repayIn || 0
            : (values?.goals?.[index]?.repayIn || 0) * 12;

        if (LengthInMonths2 > 12) {
          allGoal[index].inflationRate = getInflationRate(
            allGoal[index]?.szType
          );

          form.setFieldsValue({ goals: allGoal });
          setGoals(allGoal);
        }

        form.setFieldsValue({ goals: allGoal });
        setGoals(allGoal);
      }

      if (values?.goals?.[index]?.szType) {
        allGoal[index].rateofReturn = 4.4;
        allGoal[index].curSavingAccRate =
          getInflationRate("currentSavingRate")?.toString();
        allGoal[index].desiredTermYears = 30;
        allGoal[index].perTotalIncomeDesired = 70;
        allGoal[index].plannedAgeYears = 67;
        allGoal[index].inflationRate = getInflationRate(allGoal[index]?.szType);
        // allGoal[index].perTotalIncomeDesired = 70

        console.log("in===2", LengthInMonths2);

        if (!allGoal?.[index]?.szType?.includes("retirement")) {
          allGoal[index].inflationRate = 0;
        }

        form.setFieldsValue({ goals: allGoal });
        setGoals(allGoal);
      }

      var reccomendationText = getRecText(initialValues?.goals?.[index], index);

      if (reccomendationText !== "undefined ") {
        setRecText({ ...recText, [index]: reccomendationText });
      } else {
        setRecText({ ...recText, [index]: "" });
      }
    }, 500);
  };

  const getGoalType = (FinancialGoal) => {
    const debtRepaymentGoals = [
      "debtrepayment-creditcard",
      "debtrepayment-studentloan",
      "debtrepayment-personalloan",
    ];
    const retirementGoals = ["retirement-self", "retirement-partner"];

    if (
      debtRepaymentGoals.includes(FinancialGoal) ||
      retirementGoals.includes(FinancialGoal)
    ) {
      return 1;
    } else {
      return 0;
    }
  };

  const getImportantData = () => {
    setIsLoading(true);
    postRequest(GET_IMPORTANT_DATA, {
      szReference: id,
    }).then((res) => {
      setIsLoading(false);
      if (res?.data?.goalImportantData?.totalBudget?.length > 0) {
        const filterIncome = res?.data?.goalImportantData?.totalBudget?.filter(
          (itm) => itm?.subcatKey === "Income" || itm?.subcatKey === "Paycheck"
        );
        const total = filterIncome?.reduce(
          (accumulator, currentValue) =>
            accumulator + (currentValue?.["budget"] || 0),
          0
        );
        setTotalIncome(total * 12);
      } else {
        setIsLoading(false);
      }
    });
  };

  const getRegPmtText = (
    LengthInMonths4,
    MonGoalPmt,
    BudRAGSuff,
    ActMonPmt
  ) => {
    if (LengthInMonths4 <= 1) {
      return `Save ${numberFormatBasic(MonGoalPmt?.toFixed(2))} for one month`;
    } else if (BudRAGSuff === 1 && LengthInMonths4 > 1) {
      return `Save ${numberFormatBasic(
        MonGoalPmt?.toFixed(2)
      )} per month for ${LengthInMonths4} months`;
    } else if (BudRAGSuff === 0 && LengthInMonths4 > 1) {
      return `Save ${numberFormatBasic(
        ActMonPmt?.toFixed(2)
      )} per month for ${LengthInMonths4} months. Given the info provided, we've updated the time to accomplish this goal based on your available cash flow.`;
    } else {
      return null;
    }
  };

  function getTotalInflatedCost(M14, M16, M19, M20, M27) {
    console.log({ M14, M16, M19, M20, M27 });
    const exponent = (M16 + M19) / 12;
    const base = 1 + M27;

    if (M20 > 0) {
      return M14 * M20 * Math.pow(base, exponent);
    } else {
      return M14 * Math.pow(base, exponent);
    }
  }

  const getFVofCurrentSaving = (b6, b14, b5, b8) => {
    const b6Value = parseFloat(b6);
    const b14Value = parseFloat(b14);
    const b5Value = parseFloat(b5);
    const b8Value = parseFloat(b8);

    if (
      !isNaN(b6Value) &&
      !isNaN(b14Value) &&
      !isNaN(b5Value) &&
      !isNaN(b8Value)
    ) {
      const calculationResult =
        b6Value * Math.pow(1 + b14Value / 12, b5Value + b8Value);
      return calculationResult.toFixed(2);
    } else {
      return 0;
    }
  };

  const getFVofPartialPmt = (M18, M25, M16) => {
    console.log({ M18, M25, M16 });
    const exponent = M16;
    const base = 1 + M25 / 12;

    return M18 * Math.pow(base, exponent);
  };

  const getPlannedRetirementAgeText = (PlannedRetirementAge) => {
    return Math.floor(PlannedRetirementAge) === PlannedRetirementAge
      ? Math.round(PlannedRetirementAge, 0)
      : Math.round(
          (PlannedRetirementAge - Math.floor(PlannedRetirementAge)) * 12,
          0
        ) === 12
      ? Math.floor(PlannedRetirementAge) + 1
      : Math.round(
          (PlannedRetirementAge - Math.floor(PlannedRetirementAge)) * 12,
          0
        ) === 0
      ? Math.round(PlannedRetirementAge, 0)
      : Math.floor(PlannedRetirementAge) +
        " and " +
        Math.round(
          (PlannedRetirementAge - Math.floor(PlannedRetirementAge)) * 12,
          0
        ) +
        " months";
  };

  const getRecText = (values, index, resultObject2) => {
    const FinancialGoal = values?.szType;
    const currentRetireMentsaving = getMiniMonPmt(FinancialGoal, "ira");

    const PartialPmtAftrFincFoundation = parseFloat(
      userData?.importantValues?.PartialPaymentAfterFinancialFoundation || 0
    );
    const inflationCost = parseFloat(values?.inflationRate) / 100;

    let downPaymentPercent = 0;
    if (
      values?.szType === "carpurchase" ||
      values?.szType === "dreamhome-apartment" ||
      values?.szType === "2nd-investment-vacation-property"
    ) {
      downPaymentPercent = values?.downPaymentPercent;
    }

    console.log("values===", values);
    const yearlySalaryIncrese = 2;

    const LengthInMonths2 =
      values?.repayType === "months"
        ? values?.repayIn || 0
        : (values?.repayIn || 0) * 12;

    let averagePreTax = "";

    if (
      FinancialGoal === "retirement-self" ||
      FinancialGoal === "retirement-partner"
    ) {
      averagePreTax = parseFloat(values?.rateofReturn) / 100;
    } else {
      averagePreTax =
        LengthInMonths2 > 24
          ? parseFloat(values?.rateofReturn) / 100
          : parseFloat(values?.curSavingAccRate) / 100;
    }

    console.log("averagePreTax==", averagePreTax);
    const MonthsToSaveForFinancialSecurity =
      userData?.importantValues?.TTLFinancialFoundation;
    const PlannedRetirementAge = values?.plannedAgeYears || 0;

    const Age = userData?.age;
    const YearsUntilRetirement =
      PlannedRetirementAge - Age - 1 - MonthsToSaveForFinancialSecurity / 12;

    console.log({
      PlannedRetirementAge,
      Age,
      MonthsToSaveForFinancialSecurity,
    });
    const monthlyTotalAnuality =
      values?.goaladditionalpensionData?.length > 0
        ? values?.goaladditionalpensionData?.reduce(
            (accumulator, currentValue) =>
              accumulator + (currentValue?.["amount"] || 0),
            0
          )
        : 0;
    const monthlyOtherIncome =
      values?.goaladditionalpensionData?.length > 0
        ? values?.goaladditionalotherData?.reduce(
            (accumulator, currentValue) =>
              accumulator + (currentValue?.["amount"] || 0),
            0
          )
        : 0;

    const yearBorn = getYearBorn(Age);

    const ssiYearDate = new Date(yearBorn);
    let ssiyr = 0;

    if (!isNaN(ssiYearDate.getTime())) {
      const yearResult = ssiYearDate.getFullYear();
      ssiyr = yearResult;
    }

    const socialSecurityRetireAge = calculateSocialSecurity(ssiyr);
    const monthOfEarlyRetire = getMonthOfEarlyRetire(
      PlannedRetirementAge,
      socialSecurityRetireAge
    );
    const actualSocialSecurityIncome = calculatePresetValueAtAge(
      values?.monthlySocialSecurityIncome || 0,
      monthOfEarlyRetire
    );
    const yearsUntilRetirement = parseFloat(
      PlannedRetirementAge - Age - 1 - MonthsToSaveForFinancialSecurity / 12
    );

    const RetirementDelayYears = getDelayInYear(yearsUntilRetirement);

    const perTotalIncomeDesired = values?.perTotalIncomeDesired / 100 || 0;
    const desiredTermYears = values?.desiredTermYears || 0;

    console.log({
      perTotalIncomeDesired,
      toalIncome,
      monthlyTotalAnuality,
      monthlyOtherIncome,
      actualSocialSecurityIncome,
    });
    const CashFlowNeededAtRetirement =
      perTotalIncomeDesired * toalIncome -
      (monthlyTotalAnuality + monthlyOtherIncome + actualSocialSecurityIncome) *
        12;
    const FVOfSalary = getFVSalary(
      CashFlowNeededAtRetirement,
      yearlySalaryIncrese / 100,
      yearsUntilRetirement
    );
    // const postRateOfReturn = averagePreTax - 0.025;
    const postRateOfReturnInflation =
      (1 + (averagePreTax - 0.025)) / (1 + inflationCost) - 1;
    // (1 + postRateOfReturn) / (1 + inflationCost) - 1;
    console.log({
      FVOfSalary,
      postRateOfReturnInflation,
      desiredTermYears,
      averagePreTax,
      inflationCost,
    });

    const presentValueAtAgeRtr = getPresetValue(
      FVOfSalary,
      postRateOfReturnInflation,
      desiredTermYears
    );
    // const presentValueAtAgeRtr = getPresetValue(182547.12, 0.2989, 30);

    const futureValueOfRtrSavings = getFutureValueOfRtrSavings(
      currentRetireMentsaving,
      averagePreTax,
      yearsUntilRetirement
    );

    let sum = 0;
    if (Object.keys(usedBudRag)?.length > 0) {
      sum = Object.keys(usedBudRag)
        .filter((key) => key < index) // Exclude key 1
        .reduce((acc, key) => acc + usedBudRag[key], 0);
    } else {
      sum = Object.keys(resultObject2 || {})
        .filter((key) => key < index) // Exclude key 1
        .reduce((acc, key) => acc + resultObject2[key], 0);
    }

    console.log(
      "BudAmtAvailableForGoals===",
      (userData?.importantValues?.budRAG || 0) - sum,
      userData?.importantValues?.budRAG,
      sum,
      resultObject2,
      Object.keys(usedBudRag)?.length
    );
    const BudAmtAvailableForGoals = Math.max(
      (userData?.importantValues?.budRAG || 0) - sum,
      0
    );

    const NetNeedOfRetirementMoney =
      presentValueAtAgeRtr - futureValueOfRtrSavings;
    const annualRtrSavingNeeded = getAnnualRetirementSaving(
      NetNeedOfRetirementMoney,
      averagePreTax,
      yearlySalaryIncrese / 100,
      yearsUntilRetirement
    );
    const MonthlyRetirementNeed = getMonthlyRetireSavingNeed(
      NetNeedOfRetirementMoney,
      averagePreTax,
      yearlySalaryIncrese / 100,
      yearsUntilRetirement
    );
    setCashFlow({
      ...cashFlow,
      [index]: CashFlowNeededAtRetirement,
    });
    setFvSalary({
      ...fvSalary,
      [index]: FVOfSalary,
    });
    setPvRetirement({
      ...pvRetirement,
      [index]: presentValueAtAgeRtr,
    });
    setFvRetirement({
      ...fvRetirement,
      [index]: futureValueOfRtrSavings,
    });
    setNetNewMoney({
      ...monthlyRetiredIncome,
      [index]: NetNeedOfRetirementMoney,
    });

    setMonthlyRetiredIncome({
      ...monthlyRetiredIncome,
      [index]: MonthlyRetirementNeed,
    });
    setAnnualRetiredIncome({
      ...netNeedMoney,
      [index]: annualRtrSavingNeeded,
    });

    console.log("BudAmtAvailableForGoals===", BudAmtAvailableForGoals);

    const ExcessNeedAmount = Math.round(
      NetNeedOfRetirementMoney <= 0 ? 0 : MonthlyRetirementNeed,
      2
    );

    console.log({
      averagePreTax,
      inflationCost,
      socialSecurityRetireAge,
      FVOfSalary,
      CashFlowNeededAtRetirement,
      ExcessNeedAmount,
      MonthlyRetirementNeed,
      NetNeedOfRetirementMoney,
      averagePreTax,
      yearlySalaryIncrese,
      yearsUntilRetirement,
      presentValueAtAgeRtr,
      futureValueOfRtrSavings,
    });

    console.log("PlannedRetirementAge==", PlannedRetirementAge);
    const PlannedRetirementAgeText =
      getPlannedRetirementAgeText(PlannedRetirementAge);

    const LengthInMonths4 = LengthInMonths2; // as discussed to use same value for all places of LengthInMonths
    const MonGoalPmt = MonthlyRetirementNeed;
    const ActMonPmt =
      BudAmtAvailableForGoals >= MonGoalPmt
        ? MonGoalPmt
        : BudAmtAvailableForGoals;
    const ActRetireAgeText = calculateYearsMonthsUntilRetirement(
      LengthInMonths2,
      YearsUntilRetirement,
      PlannedRetirementAge
    );
    const YourPartnersNameForm2 =
      userData?.first_name + " " + userData?.last_name;

    const MonthlyGoalSavingsAmount = monthlyAmt?.[index];

    const totalInflatedCost = getTotalInflatedCost(
      values?.currentCost,
      parseFloat(LengthInMonths2),
      parseFloat(MonthsToSaveForFinancialSecurity),
      downPaymentPercent / 100,
      parseFloat(values?.inflationRate) / 100
    );
    console.log(
      "val====",
      values,
      values?.currentCost,
      parseFloat(LengthInMonths2),
      downPaymentPercent / 100,
      parseFloat(MonthsToSaveForFinancialSecurity),
      parseFloat(values?.inflationRate)
    );
    const FVofSaving = getFVofCurrentSaving(
      currentRetireMentsaving,
      averagePreTax,
      parseFloat(LengthInMonths2),
      parseFloat(MonthsToSaveForFinancialSecurity)
    );
    const partialSaving = getFVofPartialPmt(
      PartialPmtAftrFincFoundation,
      averagePreTax,
      parseFloat(LengthInMonths2)
    );
    const PresentValueOfGoal = getPresetValueOfGoal(
      totalInflatedCost,
      FVofSaving,
      averagePreTax,
      parseFloat(LengthInMonths2),
      parseFloat(MonthsToSaveForFinancialSecurity)
    );
    setLumsumAmt({ ...lumSumAmt, [index]: PresentValueOfGoal });
    console.log("PresentValueOfGoal==", {
      PresentValueOfGoal,
      totalInflatedCost,
      FVofSaving,
      partialSaving,
      averagePreTax,
      LengthInMonths2: parseFloat(LengthInMonths2),
      MonthsToSaveForFinancialSecurity: parseFloat(
        MonthsToSaveForFinancialSecurity
      ),
    });
    const LengthInYears =
      values?.repayType === "years"
        ? values?.repayIn || 0
        : (values?.repayIn || 0) / 12; // getting in many places 'Length In Years'

    const LengthInYears2 = LengthInYears;

    console.log({
      totalInflatedCost,
      FVofSaving,
      partialSaving,
      averagePreTax,
      LengthInMonths2,
    });

    const monPmt = getMonthlyGoalSavingAmt(
      totalInflatedCost,
      FVofSaving,
      partialSaving,
      averagePreTax,
      parseFloat(LengthInMonths2)
    );
    if (monPmt > 0) {
      setMonthlyAmt({ ...monthlyAmt, [index]: monPmt });
    }

    const CVInvestments =
      LengthInYears2 > 2 && InvestTotal >= PresentValueOfGoal
        ? PresentValueOfGoal
        : LengthInYears2 > 2 && InvestTotal < PresentValueOfGoal
        ? InvestTotal
        : 0;

    const EF = parseFloat(userData?.importantValues?.emergencyFund)?.toFixed(2);
    const RainyDayFund2 = parseFloat(
      userData?.importantValues?.rainyDayFundAmount
    )?.toFixed(2);
    const CAst = parseFloat(userData?.importantValues?.CAst);
    const NDE = userData?.importantValues?.NDE;
    const EFSuff = userData?.importantValues?.EFSuff;
    const CurrentAssetsAvailableForGoals =
      EFSuff === 1 ? CAst - NDE * 0.5 - RainyDayFund2 - EF : 0;

    const CVOfSavings = getCVofSavings(
      LengthInYears2,
      PresentValueOfGoal,
      CurrentAssetsAvailableForGoals
    );
    const AstRecSuf = getAstRecSuf(
      MonthlyGoalSavingsAmount,
      CVInvestments,
      CVOfSavings,
      values?.szType
    );

    const CurrentSavingsAcctRate = values?.curSavingAccRate;

    const PartialEFMonPmt = PartialPmtAftrFincFoundation;
    const BudRAGSuff = BudAmtAvailableForGoals >= MonGoalPmt ? 1 : 0;

    const FutureValueOfGoal = PartialPmtAftrFincFoundation;
    const GoalType = getGoalType(values?.szType);

    const ClientRateOfReturn =
      LengthInYears <= 2
        ? CurrentSavingsAcctRate
        : parseFloat(ClientRateOfReturnLookup);
    const FVOfPartialPmt = monPmt;
    const MonthlyGoalPaymentRequired =
      FutureValueOfGoal <= 0
        ? 0
        : ((FutureValueOfGoal - FVOfPartialPmt) * (ClientRateOfReturn / 12)) /
          (Math.pow(1 + ClientRateOfReturn / 12, LengthInMonths2) - 1);

    const PtPmtRecSuf =
      FinancialGoal === null
        ? 0
        : GoalType === 0 && PartialEFMonPmt > 0
        ? 1
        : 0;
    const RGPmtRecSuf = getRGPmtRecSuf(
      AstRecSuf,
      FutureValueOfGoal,
      GoalType,
      MonthlyGoalPaymentRequired,
      PtPmtRecSuf
    );

    const RegPmtText = getRegPmtText(
      LengthInMonths4,
      monPmt,
      BudRAGSuff,
      ActMonPmt
    );

    const AssestUseSaveText = getAssestUseSaveText(
      CVOfSavings,
      CVInvestments,
      MonthlyGoalSavingsAmount,
      values?.szType
    );
    const PartialPmtCalc =
      PartialEFMonPmt > 0 && FVOfPartialPmt >= FutureValueOfGoal
        ? "Save " +
          numberFormatBasic(PresentValueOfGoal?.toFixed(2)) +
          ", which is the rest of the monthly amount available for saving after you’ve fully funded your Emergency Fund, to accomplish your goal"
        : PartialEFMonPmt > 0 && FVOfPartialPmt < FutureValueOfGoal
        ? "Save " +
          numberFormatBasic(PartialEFMonPmt?.toFixed(2)) +
          ", which is the rest of the monthly amount available for saving after you’ve fully funded your Emergency Fund"
        : "";
    const fiannacialGoalText = getFinancialGoalMessage(
      socialSecurityRetireAge,
      NetNeedOfRetirementMoney,
      averagePreTax,
      MonthlyRetirementNeed,
      FinancialGoal,
      values,
      MonthlyGoalSavingsAmount,
      LengthInMonths2,
      MonGoalPmt,
      CVInvestments,
      CVOfSavings,
      AstRecSuf,
      PartialEFMonPmt,
      BudRAGSuff,
      FutureValueOfGoal,
      RGPmtRecSuf,
      ActMonPmt,
      BudAmtAvailableForGoals,
      ExcessNeedAmount,
      ActRetireAgeText,
      YourPartnersNameForm2,
      RetirementDelayYears,
      YearsUntilRetirement,
      PlannedRetirementAgeText,
      MonthsToSaveForFinancialSecurity,
      index,
      PresentValueOfGoal
    );

    return (
      fiannacialGoalText +
      " " +
      (AstRecSuf == "1" && AssestUseSaveText !== fiannacialGoalText
        ? AssestUseSaveText + " "
        : "") +
      (PtPmtRecSuf == "1" ? PartialPmtCalc + " " : "") +
      (RGPmtRecSuf == "1" ? RegPmtText : "")
    );
  };

  function getFinancialGoalMessage(
    socialSecurityRetireAge,
    NetNeedOfRetirementMoney,
    averagePreTax,
    MonthlyRetirementNeed,
    FinancialGoal,
    values,
    MonthlyGoalSavingsAmount,
    LengthInMonths2,
    MonGoalPmt,
    CVInvestments,
    CVOfSavings,
    AstRecSuf,
    PartialEFMonPmt,
    BudRAGSuff,
    FutureValueOfGoal,
    RGPmtRecSuf,
    ActMonPmt,
    BudAmtAvailableForGoals,
    ExcessNeedAmount,
    ActRetireAgeText,
    YourPartnersNameForm2,
    RetirementDelayYears,
    YearsUntilRetirement,
    PlannedRetirementAgeText,
    MonthsToSaveForFinancialSecurity,
    index,
    PresentValueOfGoal
  ) {
    const LengthInMonths3 = LengthInMonths2;
    const LengthInMonths4 = LengthInMonths2;
    if (
      FinancialGoal === "debtrepayment-creditcard" ||
      FinancialGoal === "debtrepayment-studentloan" ||
      FinancialGoal === "debtrepayment-personalloan"
    ) {
      return getDebtRpmtText(
        values?.interestRate,
        values?.curMinMonthlyPayment,
        values?.szType,
        LengthInMonths2,
        BudAmtAvailableForGoals,
        MonthsToSaveForFinancialSecurity,
        values?.amountToRepaid,
        index
      );
    } else if (
      FinancialGoal === "retirement-self" ||
      FinancialGoal === "retirement-partner"
    ) {
      return getSavLanguage(
        values,
        index,
        socialSecurityRetireAge,
        NetNeedOfRetirementMoney,
        averagePreTax,
        MonthlyRetirementNeed,
        FinancialGoal,
        ExcessNeedAmount,
        ActRetireAgeText,
        YourPartnersNameForm2,
        BudAmtAvailableForGoals,
        ActMonPmt,
        LengthInMonths3,
        RetirementDelayYears,
        YearsUntilRetirement,
        PlannedRetirementAgeText
      );
    } else if (
      MonGoalPmt === 0 &&
      CVInvestments + CVOfSavings === PresentValueOfGoal
    ) {
      return getAssestUseSaveText(
        CVOfSavings,
        CVInvestments,
        MonthlyGoalSavingsAmount,
        values?.szType
      );
    } else if (BudAmtAvailableForGoals <= 0) {
      return `To save for your ${getGoalName(
        FinancialGoal
      )} Goal, you’ll need to free up cash by reducing expenses`;
    } else if (
      (AstRecSuf === 1 && PartialEFMonPmt === 1) ||
      (AstRecSuf === 1 && RGPmtRecSuf === 1) ||
      (RGPmtRecSuf === 1 && PartialEFMonPmt === 1) ||
      (AstRecSuf === 1 && PartialEFMonPmt === 1 && RGPmtRecSuf === 1)
    ) {
      return `To save for your ${getGoalName(FinancialGoal)} Goal, you should:`;
    } else if (
      BudRAGSuff === 1 &&
      FutureValueOfGoal > 0 &&
      PartialEFMonPmt === 0 &&
      MonthlyGoalSavingsAmount > 0 &&
      AstRecSuf === 0 &&
      LengthInMonths4 <= 1
    ) {
      return `To save for your ${getGoalName(
        FinancialGoal
      )} Goal, you should save ${MonGoalPmt?.toFixed(2)} for one month`;
    } else if (
      BudRAGSuff === 0 &&
      FutureValueOfGoal > 0 &&
      PartialEFMonPmt === 0 &&
      MonthlyGoalSavingsAmount > 0 &&
      AstRecSuf === 0 &&
      LengthInMonths3 <= 1
    ) {
      return `To save for your ${getGoalName(
        FinancialGoal
      )} Goal, you should save ${ActMonPmt?.toFixed(
        2
      )} for one month. Given the info provided, we've updated the time to accomplish this goal based on your available cash flow.`;
    } else if (
      BudRAGSuff === 0 &&
      FutureValueOfGoal > 0 &&
      PartialEFMonPmt === 0 &&
      MonthlyGoalSavingsAmount > 0 &&
      AstRecSuf === 0 &&
      LengthInMonths3 > 1
    ) {
      return `To save for your ${getGoalName(
        FinancialGoal
      )} Goal, you should save ${ActMonPmt?.toFixed(2)} for ${Math.abs(
        LengthInMonths3
      )?.toFixed(
        2
      )} months. Given the info provided, we've updated the time to accomplish this goal based on your available cash flow.`;
    } else {
      return `To save for your ${getGoalName(FinancialGoal)} Goal, you should:`;
    }
  }

  const getAssestUseSaveText = (
    CVOfSavings,
    CVInvestments,
    MonthlyGoalSavingsAmount,
    FinancialGoal
  ) => {
    if (
      (CVOfSavings > 0 &&
        CVInvestments === 0 &&
        MonthlyGoalSavingsAmount > 0) ||
      (CVOfSavings > 0 &&
        CVInvestments === null &&
        MonthlyGoalSavingsAmount > 0)
    ) {
      return `Set aside ${CVOfSavings?.toFixed(
        2
      )} from your Cash/Savings Accounts`;
    } else if (
      (CVOfSavings === 0 &&
        CVInvestments > 0 &&
        MonthlyGoalSavingsAmount > 0) ||
      (CVOfSavings === null &&
        CVInvestments > 0 &&
        MonthlyGoalSavingsAmount > 0)
    ) {
      return `Set aside ${CVInvestments?.toFixed(
        2
      )} from your Investment Account`;
    } else if (
      CVOfSavings > 0 &&
      CVInvestments > 0 &&
      MonthlyGoalSavingsAmount > 0
    ) {
      return `Set aside ${CVOfSavings?.toFixed(
        2
      )} of your Cash/Savings Accounts and ${CVInvestments} of your Investment Account`;
    } else if (
      (CVOfSavings > 0 &&
        CVInvestments === 0 &&
        MonthlyGoalSavingsAmount === 0) ||
      (CVOfSavings > 0 &&
        CVInvestments === null &&
        MonthlyGoalSavingsAmount === 0)
    ) {
      return `To achieve your ${getGoalName(
        FinancialGoal
      )} Goal, you should set aside ${CVOfSavings?.toFixed(
        2
      )} from your Cash/Savings Accounts to reach this goal.`;
    } else if (
      (CVOfSavings === 0 &&
        CVInvestments > 0 &&
        MonthlyGoalSavingsAmount === 0) ||
      (CVOfSavings === null &&
        CVInvestments > 0 &&
        MonthlyGoalSavingsAmount === 0)
    ) {
      return `To achieve your ${getGoalName(
        FinancialGoal
      )} Goal, you should set aside ${CVInvestments?.toFixed(
        2
      )} from your Investment Account to reach this goal.`;
    } else if (
      CVOfSavings > 0 &&
      CVInvestments > 0 &&
      MonthlyGoalSavingsAmount === 0
    ) {
      return `To achieve your ${getGoalName(
        FinancialGoal
      )} Goal, you should set aside ${CVOfSavings?.toFixed(
        2
      )} of your Cash/Savings Accounts and ${CVInvestments} of your Investment Account to reach this goal.`;
    } else {
      return "";
    }
  };

  const calculateYearsMonthsUntilRetirement = (
    LengthInMonths3,
    YearsUntilRetirement,
    PlannedRetirementAge
  ) => {
    const totalYears = Math.floor(
      LengthInMonths3 / 12 - YearsUntilRetirement + PlannedRetirementAge
    );
    const remainingMonths = Math.round(
      (LengthInMonths3 / 12 -
        YearsUntilRetirement +
        PlannedRetirementAge -
        totalYears) *
        12
    );

    if (remainingMonths === 12) {
      return `${totalYears + 1} years`;
    } else if (remainingMonths === 0) {
      return `${totalYears} years`;
    } else {
      return `${totalYears} years and ${remainingMonths} months`;
    }
  };

  const calculateSocialSecurity = (b13) => {
    const b13Value = parseFloat(b13);

    if (!isNaN(b13Value)) {
      let calculationResult;

      if (b13Value < 1954) {
        calculationResult = 66;
      } else if (b13Value === 1955) {
        calculationResult = 66.16666667;
      } else if (b13Value === 1956) {
        calculationResult = 66.3333333;
      } else if (b13Value === 1957) {
        calculationResult = 66.5;
      } else if (b13Value === 1958) {
        calculationResult = 66.66666667;
      } else if (b13Value === 1959) {
        calculationResult = 66.8333333;
      } else {
        calculationResult = 67;
      }

      return calculationResult.toFixed(8);
    } else {
      return 0;
    }
  };

  const getMonthOfEarlyRetire = (b6, b14) => {
    const b6Value = parseFloat(b6);
    const b14Value = parseFloat(b14);

    if (!isNaN(b6Value) && !isNaN(b14Value)) {
      const roundedResult = Math.ceil(
        (Math.max(62, Math.min(70, b6Value)) - b14Value) * 12
      );
      return roundedResult;
    } else {
      return 0;
    }
  };

  const calculatePresetValueAtAge = (b9, b15) => {
    const b15Value = parseFloat(b15);
    const b9Value = parseFloat(b9);

    if (!isNaN(b15Value) && !isNaN(b9Value)) {
      let calculationResult;

      if (b15Value === 0) {
        calculationResult = b9Value;
      } else if (b15Value > 0) {
        calculationResult = (0.0066666666667 * b15Value + 1) * b9Value;
      } else if (b15Value < 0 && b15Value >= -36) {
        calculationResult = (1 + 0.0055555555556 * b15Value) * b9Value;
      } else {
        calculationResult =
          (1 + 0.0055555555556 * b15Value + 0.004166666667 * (b15Value + 36)) *
          b9Value;
      }

      return calculationResult?.toFixed(2);
    } else {
      return 0;
    }
  };

  const getYearBorn = (age) => {
    const yearsToSubtract = parseFloat(age);

    if (!isNaN(yearsToSubtract)) {
      const today = new Date();
      const newDate = new Date(
        today.getFullYear() - yearsToSubtract,
        today.getMonth(),
        today.getDate()
      );

      // Format the result as a string (e.g., "YYYY-MM-DD")
      const formattedResult = `${newDate.getFullYear()}-${String(
        newDate.getMonth() + 1
      ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`;
      return formattedResult;
    } else {
      return 0;
    }
  };

  const getDelayInYear = (b36) => {
    const b36Value = parseFloat(b36);

    if (!isNaN(b36Value)) {
      let calculationResult;

      if (b36Value < 0) {
        calculationResult = Math.ceil(Math.abs(b36Value) + 1, 2);
      } else {
        calculationResult = 0;
      }

      return calculationResult.toFixed(2);
    } else {
      return 0;
    }
  };

  const getFVSalary = (b21, b33, b36) => {
    const b21Value = parseFloat(b21);
    const b33Value = parseFloat(b33);
    const b36Value = parseFloat(b36);

    if (!isNaN(b21Value) && !isNaN(b33Value) && !isNaN(b36Value)) {
      const calculationResult = b21Value * Math.pow(1 + b33Value, b36Value);
      return calculationResult.toFixed(2);
    } else {
      return 0;
    }
  };

  const getPresetValue = (b22, b35, b7) => {
    const b22Value = parseFloat(b22);
    const b35Value = parseFloat(b35);
    const b7Value = parseFloat(b7);

    if (!isNaN(b22Value) && !isNaN(b35Value) && !isNaN(b7Value)) {
      const calculationResult =
        b22Value *
        (1 + b35Value) *
        ((1 - Math.pow(1 / (1 + b35Value), b7Value)) / b35Value);
      return calculationResult.toFixed(2);
    } else {
      return 0;
    }
  };

  const getFutureValueOfRtrSavings = (b18, b30, b36) => {
    const b18Value = parseFloat(b18);
    const b30Value = parseFloat(b30);
    const b36Value = parseFloat(b36);

    if (!isNaN(b18Value) && !isNaN(b30Value) && !isNaN(b36Value)) {
      const calculationResult = b18Value * Math.pow(1 + b30Value, b36Value);
      return calculationResult.toFixed(2);
    } else {
      return 0;
    }
  };

  const getAnnualRetirementSaving = (B25, B30, B33, B36) => {
    console.log({ B25, B30, B33, B36 });
    const condition = B25 <= 0;
    const numerator = B25 * (B30 - B33);
    const denominator = Math.pow(1 + B30, B36) - Math.pow(1 + B33, B36);
    const result = condition ? 0 : numerator / denominator;

    return result;
  };

  const getMonthlyRetireSavingNeed = (B25, B30, B33, B36) => {
    const condition = B25 <= 0;
    const numerator = B25 * ((B30 - B33) / 12);
    const denominator =
      Math.pow(1 + B30 / 12, 12 * B36) - Math.pow(1 + B33 / 12, 12 * B36);
    const result = condition ? 0 : numerator / denominator;

    return result;
  };

  function calculateUpdateMonthtoSave(G41, G12, G55, G38, G49, G43) {
    if (G41 <= G12) {
      return G55 * 12;
    } else {
      return -(
        Math.log((1 - (-G38 * (G49 / 12)) / G43) ** -1) / Math.log(1 + G49 / 12)
      );
    }
  }

  const calculateMonthlyDebtRepayment = (B25, B21, B17, B30) => {
    const monthlyInterestRate = B17 / 12 / 100;
    const numerator = (B25 - B21) * monthlyInterestRate;
    const denominator = 1 - Math.pow(1 + monthlyInterestRate, -B30);

    const loanRepayment = numerator / denominator;

    return loanRepayment || 0;
  };

  const getApr = (goal) => {
    let type = [];

    if (goal === "debtrepayment-creditcard") {
      type = ["creditCard"];
    } else if (goal === "debtrepayment-studentloan") {
      type = ["studentLoan", "studentLoanAccount", "studentLoanGroup"];
    } else if (goal === "debtrepayment-personalloan") {
      type = ["personalLoan", "personalLoanAccount", "personalLoanGroup"];
    }

    const filterDebtType = userData?.accounts?.filter((item) =>
      type?.includes(item?.type)
    );

    const balanceSheetH5 = filterDebtType?.reduce(
      (accumulator, currentValue) =>
        accumulator + (currentValue?.["balance"] || 0),
      0
    );

    const sum = filterDebtType?.reduce(
      (accumulator, currentValue) =>
        accumulator +
        (currentValue?.["currentBalance"] / -balanceSheetH5) *
          currentValue?.["interestRate"],
      0
    );

    return Math.abs(parseFloat(sum || 0)?.toFixed(2));
  };

  const calculateActMonthsToPayOff = (B25, B21, B17, B27) => {
    const innerExpression = 1 - ((B25 - B21) * (B17 / 12 / 100)) / B27;
    const numerator = -Math.log(Math.pow(innerExpression, -1));
    const denominator = Math.log(1 + B17 / 12 / 100);
    const result = numerator / denominator;

    return result;
  };

  function calculateGoalMonPayment(B25, B17, B18) {
    const monthlyInterestRate = B17 / 12 / 100;

    // Calculate the denominator
    const denominator = 1 - Math.pow(1 + monthlyInterestRate, -B18);

    // Calculate the result
    const loanRepayment = (B25 * monthlyInterestRate) / denominator;

    return loanRepayment;
  }

  const PMT = (rate, nper, pv, fv, type) => {
    let pmt, pvif;

    fv || (fv = 0);
    type || (type = 0);

    if (rate === 0) return -(pv + fv) / nper;

    pvif = Math.pow(1 + rate, nper);
    pmt = (-rate * (pv * pvif + fv)) / (pvif - 1);

    if (type === 1) pmt /= 1 + rate;
    return pmt;
  };

  function calculateNPER(rate, payment, presentValue, futureValue, type) {
    if (rate === 0) {
      return -(presentValue + futureValue) / payment;
    }

    const numerator = Math.log(1 + rate * (type + futureValue / presentValue));
    const denominator = Math.log(1 + rate);
    const result = -(numerator / denominator);

    return result;
  }

  function getNewRetireAge(G45, G55, G19) {
    if (G45 / 12 === G55) {
      return G19;
    } else {
      var yearsDifference = Math.floor(G45 / 12 - G55 + G19);
      var monthsDifference = Math.round(
        (G45 / 12 - G55 + G19 - yearsDifference) * 12
      );

      if (monthsDifference === 1) {
        return yearsDifference + " year and " + monthsDifference + " month";
      } else {
        return yearsDifference + " years and " + monthsDifference + " months";
      }
    }
  }

  const getDebtRpmtText = (
    APR,
    CurrentMinimumMonthlyPayment,
    DebtRepaymentType,
    RepayIn,
    BudAmtAvailableForGoals,
    MonthsToSaveForFinancialSecurity,
    amountToRepaid,
    index
  ) => {
    const PartialPmtAftrFincFoundation = parseFloat(
      userData?.importantValues?.PartialPaymentAfterFinancialFoundation || 0
    );
    const MinMonthlyPaymentMonthsToPayoff =
      APR > 0
        ? calculateMonthsToPayOff(
            amountToRepaid,
            APR,
            CurrentMinimumMonthlyPayment
          )
        : (amountToRepaid / CurrentMinimumMonthlyPayment)?.toFixed(2);
    const MinMonthlyPaymentMonthsToPayoff2 = Math.ceil(
      MinMonthlyPaymentMonthsToPayoff
    );

    const BudRag = BudAmtAvailableForGoals;

    // const BudRag = userData?.importantValues?.budRAG || 0;
    const monthlyInterestRate = APR / 12 / 100;

    const base = 1 + monthlyInterestRate;
    const exponent = MonthsToSaveForFinancialSecurity;
    const compoundFactor = Math.pow(base, exponent);
    const principleAfterFinFoundSaved =
      APR > 0
        ? amountToRepaid * compoundFactor -
          (
            (CurrentMinimumMonthlyPayment * (compoundFactor - 1)) /
            monthlyInterestRate
          )?.toFixed(2)
        : amountToRepaid -
          CurrentMinimumMonthlyPayment * MonthsToSaveForFinancialSecurity;
    const GoalMonPmt =
      APR > 0
        ? calculateGoalMonPayment(principleAfterFinFoundSaved, APR, RepayIn)
        : PMT(APR, RepayIn, principleAfterFinFoundSaved, 0, 0);
    const maxMonpmt =
      GoalMonPmt > CurrentMinimumMonthlyPayment + BudRag
        ? BudRag + CurrentMinimumMonthlyPayment
        : GoalMonPmt;
    const ActMonDebt =
      APR > 0
        ? calculateActMonthsToPayOff(
            principleAfterFinFoundSaved,
            PartialPmtAftrFincFoundation,
            APR,
            maxMonpmt
          )
        : calculateNPER(
            APR,
            GoalMonPmt,
            -principleAfterFinFoundSaved + PartialPmtAftrFincFoundation,
            0,
            0
          );
    const GoalPaymentAmount =
      APR > 0
        ? calculateMonthlyDebtRepayment(
            principleAfterFinFoundSaved,
            PartialPmtAftrFincFoundation,
            APR,
            Math.ceil(Math.abs(ActMonDebt))
          )
        : PMT(
            0,
            Math.ceil(Math.abs(ActMonDebt)),
            -principleAfterFinFoundSaved + PartialPmtAftrFincFoundation,
            0,
            0
          );
    const increaseMonPmt = GoalPaymentAmount - CurrentMinimumMonthlyPayment;

    setUsedBudRag({ ...usedBudRag, [index]: increaseMonPmt });
    const PartialEFMonPmt = PartialPmtAftrFincFoundation;

    console.log({
      BudRag,
      increaseMonPmt,
      ActMonDebt,
      GoalMonPmt,
      maxMonpmt,
      monthlyInterestRate,
      principleAfterFinFoundSaved,
      PartialEFMonPmt,
      GoalPaymentAmount,
      MonthsToSaveForFinancialSecurity,
      MinMonthlyPaymentMonthsToPayoff2,
    });
    setMinMonPmtMon({
      ...minMonPmtMon,
      [index]: MinMonthlyPaymentMonthsToPayoff,
    });
    setPrincipalAfterFin({
      ...principalAfterFin,
      [index]: principleAfterFinFoundSaved,
    });
    setGoalMonthlyPayment({ ...goalMonthlyPayment, [index]: GoalMonPmt });
    setMaxMonthlyPmt({ ...maxMonthlyPmt, [index]: maxMonpmt });
    setActMonPayOff({ ...actMonPayOff, [index]: Math.abs(ActMonDebt) });
    setRoundedActMonPay({
      ...roundedActMonPay,
      [index]: Math.ceil(Math.abs(ActMonDebt)),
    });
    setGoalPmtAmt({ ...goalPmtAmt, [index]: GoalPaymentAmount });

    if (MonthsToSaveForFinancialSecurity === MinMonthlyPaymentMonthsToPayoff) {
      return (
        "You’ll pay off your " +
        getGoalName(DebtRepaymentType) +
        " Debt in " +
        Math.abs(MinMonthlyPaymentMonthsToPayoff) +
        " months, which is the same amount of time to save for your financial foundation"
      );
    } else if (
      MonthsToSaveForFinancialSecurity > MinMonthlyPaymentMonthsToPayoff
    ) {
      return (
        "You’ll pay off your " +
        getGoalName(DebtRepaymentType) +
        " Debt in " +
        Math.round(MinMonthlyPaymentMonthsToPayoff || 0) +
        " months, which is less than the amount of time needed to save for your financial foundation"
      );
    } else if (BudRag <= 0) {
      return (
        "To pay off your " +
        getGoalName(DebtRepaymentType) +
        " Debt, you’ll need to free up some cash by reducing your expenses."
      );
    } else if (
      PartialEFMonPmt === 0 &&
      MonthsToSaveForFinancialSecurity > 0 &&
      GoalPaymentAmount > 1
    ) {
      return (
        "After saving for Financial Security, you should increase your monthly " +
        getGoalName(DebtRepaymentType) +
        " payment to " +
        numberFormatBasic(Math.abs(GoalPaymentAmount)?.toFixed(2)) +
        " per month for " +
        Math.ceil(Math.abs(ActMonDebt || 0))?.toFixed(0) +
        " months to pay it off"
      );
    } else if (
      (PartialEFMonPmt > 0 &&
        MonthsToSaveForFinancialSecurity > 0 &&
        GoalPaymentAmount > 1) ||
      (PartialEFMonPmt > 0 &&
        MonthsToSaveForFinancialSecurity === 0 &&
        GoalPaymentAmount > 1)
    ) {
      return (
        "After your last month’s partial savings payment to your Emergency Fund, you should pay down your " +
        getGoalName(DebtRepaymentType) +
        " Debt by " +
        numberFormatBasic(PartialEFMonPmt?.toFixed(2)) +
        " and then increase your monthly payment to " +
        numberFormatBasic(Math.abs(GoalPaymentAmount)?.toFixed(2)) +
        " per month for " +
        Math.ceil(Math.abs(ActMonDebt || 0))?.toFixed(0) +
        " months to pay it off"
      );
    } else if (
      MonthsToSaveForFinancialSecurity < MinMonthlyPaymentMonthsToPayoff
    ) {
      return (
        "After your last month’s partial savings payment to your Emergency Fund, you should pay down your " +
        getGoalName(DebtRepaymentType) +
        " Debt by " +
        numberFormatBasic(PartialEFMonPmt?.toFixed(2)) +
        " and then increase your monthly payment to " +
        numberFormatBasic(Math.abs(GoalPaymentAmount)?.toFixed(2)) +
        " per month for " +
        Math.ceil(Math.abs(ActMonDebt || 0))?.toFixed(0) +
        " months to pay it off"
      );
    } else if (
      PartialEFMonPmt === 0 &&
      MonthsToSaveForFinancialSecurity > 0 &&
      GoalPaymentAmount <= 1
    ) {
      return (
        "After saving for Financial Security, you should increase your monthly " +
        getGoalName(DebtRepaymentType) +
        " payment to " +
        numberFormatBasic(Math.abs(GoalPaymentAmount)?.toFixed(2)) +
        " for 1 month to pay it off"
      );
    } else if (
      PartialEFMonPmt === 0 &&
      MonthsToSaveForFinancialSecurity === 0 &&
      GoalPaymentAmount <= 1
    ) {
      return (
        "To pay off your " +
        getGoalName(DebtRepaymentType) +
        " Debt, increase your monthly payment to " +
        numberFormatBasic(Math.abs(GoalPaymentAmount)?.toFixed(2)) +
        " for 1 month"
      );
    } else if (
      (PartialEFMonPmt > 0 &&
        MonthsToSaveForFinancialSecurity > 0 &&
        GoalPaymentAmount <= 1) ||
      (PartialEFMonPmt > 0 &&
        MonthsToSaveForFinancialSecurity === 0 &&
        GoalPaymentAmount <= 1)
    ) {
      return (
        "After your last month’s partial savings payment to your Emergency Fund, you should pay down your " +
        getGoalName(DebtRepaymentType) +
        " Debt by " +
        numberFormatBasic(PartialEFMonPmt?.toFixed(2)) +
        " and then increase your monthly payment to " +
        numberFormatBasic(Math.abs(GoalPaymentAmount)?.toFixed(2)) +
        " for " +
        Math.ceil(Math.abs(ActMonDebt || 0))?.toFixed(0) +
        " month to pay it off"
      );
    } else {
      return "";
    }
  };

  const getSavLanguage = (
    values,
    index,
    socialSecurityRetireAge,
    NetNeedOfRetirementMoney,
    averagePreTax,
    MonthlyRetirementNeed,
    FinancialGoal,
    ExcessNeedAmount,
    ActRetireAgeText,
    YourPartnersNameForm2,
    BudAmtAvailableForGoals,
    ActMonPmt,
    LengthInMonths3,
    RetirementDelayYears,
    YearsUntilRetirement,
    PlannedRetirementAgeText
  ) => {
    const budRag = BudAmtAvailableForGoals;
    const MaxMonthlyPayment =
      budRag >= MonthlyRetirementNeed ? MonthlyRetirementNeed : budRag;
    console.log({
      NetNeedOfRetirementMoney,
      averagePreTax,
      MaxMonthlyPayment,
      budRag,
    });
    const updateMonthToSave = calculateUpdateMonthtoSave(
      MonthlyRetirementNeed,
      budRag,
      YearsUntilRetirement,
      NetNeedOfRetirementMoney,
      averagePreTax,
      MaxMonthlyPayment
    );
    const retireText = getNewRetireAge(
      updateMonthToSave,
      YearsUntilRetirement,
      parseFloat(socialSecurityRetireAge)
    );

    console.log("update===", { updateMonthToSave, retireText });

    setUsedBudRag({ ...usedBudRag, [index]: budRag });

    if (FinancialGoal === "retirement-self") {
      if (ExcessNeedAmount > budRag) {
        return `To achieve your Retirement Goal, you should save ${numberFormatBasic(
          Math.abs(MaxMonthlyPayment)?.toFixed(2)
        )} per month for ${Math.abs(
          Math.ceil(updateMonthToSave)
        )} months, but plan to retire at age ${retireText} instead of at age ${PlannedRetirementAgeText}. Based on the info provided, we’ve updated your retirement assumptions to ensure you can achieve your Retirement Goal.`;
      } else if (budRag === 0) {
        return "You should reduce your expenses to create additional cash flow to begin saving for Retirement";
      } else if (
        FinancialGoal === "retirement-self" &&
        YearsUntilRetirement <= 0
      ) {
        return (
          "You should reduce your expenses to create additional cash flow to begin saving for Retirement and possibly delay retirement by " +
          Math.abs(RetirementDelayYears) +
          " years"
        );
      } else if (
        FinancialGoal === "retirement-self" &&
        ExcessNeedAmount === 0
      ) {
        return (
          "You currently have enough saved to Retire at " +
          PlannedRetirementAgeText
        );
      } else if (
        FinancialGoal === "retirement-self" &&
        ExcessNeedAmount <= budRag
      ) {
        return (
          "To Retire at " +
          PlannedRetirementAgeText +
          ", you'll need to save " +
          numberFormatBasic(Math.abs(ExcessNeedAmount)?.toFixed(2)) +
          " per month for " +
          Math.abs(updateMonthToSave)?.toFixed(0) +
          " months"
        );
      }
    } else if (FinancialGoal === "retirement-partner") {
      if (YearsUntilRetirement <= 0) {
        return `${YourPartnersNameForm2} should reduce expenses to create additional cash flow to begin saving for Retirement and possibly delay retirement by ${Math.abs(
          RetirementDelayYears
        )} years`;
      } else if (ExcessNeedAmount === 0) {
        return `${YourPartnersNameForm2} currently has enough saved to Retire at ${PlannedRetirementAgeText}`;
      } else if (ExcessNeedAmount <= budRag) {
        return `For ${YourPartnersNameForm2} to Retire at ${PlannedRetirementAgeText}, they’ll need to save ${numberFormatBasic(
          Math.abs(ExcessNeedAmount)?.toFixed(2)
        )} per month for ${Math.abs(values?.desiredTermYears)} months`;
      } else if (ExcessNeedAmount > budRag) {
        return `For ${YourPartnersNameForm2} to achieve their Retirement Goal, they should save ${numberFormatBasic(
          Math.abs(ActMonPmt)?.toFixed(2)
        )} per month for ${Math.abs(
          values?.desiredTermYears
        )} months, but they should plan to retire at age ${ActRetireAgeText} instead of at age ${PlannedRetirementAgeText}. Based on the info provided, we’ve updated their retirement assumptions to ensure they can achieve their Retirement Goal.`;
      } else if (budRag === 0) {
        return `${YourPartnersNameForm2} should reduce their expenses to create additional cash flow to begin saving for Retirement`;
      }
    }
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      setIsSaveInvalid(false);
      const response = await postRequest(GET_FINANCIAL_GOAL_API, {
        szReference: id,
      });
      console.log("Response:", response);
      if (
        response?.code === 200 &&
        response?.data?.financialgoals?.length > 0
      ) {
        const { data } = response;
        const filteredValues = data?.financialgoals?.map((obj) => ({
          ...obj,
          amountToRepaid:
            obj.amountToRepaid !== null ? obj.amountToRepaid : getBalance(),
          curMinMonthlyPayment:
            obj.curMinMonthlyPayment !== null
              ? obj.curMinMonthlyPayment
              : getMiniMonPmt(obj?.szType, "paymentMinAmount"),
          interestRate:
            obj.interestRate !== null ? obj.interestRate : getApr(obj?.szType),
          inflationRate:
            obj.inflationRate !== null
              ? obj.inflationRate
              : getInflationRate(obj?.szType),
          rateofReturn:
            obj.rateofReturn !== null
              ? obj.rateofReturn
              : getReturnRate(riskTolorenceScore),
          curSavingAccRate:
            obj.curSavingAccRate !== null
              ? obj.curSavingAccRate
              : getInflationRate("currentSavingRate"),
          postRateOfReturn:
            obj.postRateOfReturn !== null ? obj.postRateOfReturn : 4.01,
          plannedAgeYears:
            obj.plannedAgeYears !== null ? obj.plannedAgeYears : 67,
          plannedAgeMonths:
            obj.plannedAgeMonths !== null ? obj.plannedAgeMonths : 0,
          perTotalIncomeDesired:
            obj.perTotalIncomeDesired !== null ? obj.perTotalIncomeDesired : 70,
          desiredTermYears:
            obj.desiredTermYears !== null ? obj.desiredTermYears : 30,
        }));

        const resultObject2 = data?.financialgoals?.reduce(
          (acc, obj, index) => {
            acc[index] = obj.remainingBudRag;
            return acc;
          },
          {}
        );

        // setUsedBudRag(resultObject2)

        const resultObject = data?.financialgoals?.reduce((acc, obj, index) => {
          const { remainingBudRag } = obj;

          acc[index] = remainingBudRag;

          const reccomendationText = getRecText(obj, index, resultObject2);

          console.log("called===1", index, recText);

          const updatedRecText =
            reccomendationText !== "undefined" ? reccomendationText : "1";

          setRecText((prevRecText) => ({
            ...prevRecText,
            [index]: updatedRecText,
          }));

          postRequest(UPDATE_RECOMMENDATION, {
            szReference: id,
            id: obj.id,
            szRecommendation: updatedRecText,
          }).then((res) => {
            console.log(res);
          });

          return acc;
        }, {});

        setUsedBudRag(resultObject);

        // setInitialValues(filteredValues);
        console.log("resultObject===", resultObject);
        form.setFieldsValue({ goals: filteredValues });
        setGoals(filteredValues);
      } else {
        setGoals([]);
        form.setFieldsValue({ goals: [] });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const addGoal = async () => {
    try {
      if (goals.length > 0) {
        if (goals[goals?.length - 1]?.id > 0) {
          setGoals([
            ...goals,
            {
              id: 0,
              goaladditionalotherData: [
                { id: 0, isDeleted: 0, szName: "", amount: "" },
              ],
              goaladditionalpensionData: [
                { id: 0, isDeleted: 0, szName: "", amount: "" },
              ],
            },
          ]);
          form.setFieldsValue({
            goals: [
              ...goals,
              {
                id: 0,
                goaladditionalotherData: [
                  { id: 0, isDeleted: 0, szName: "", amount: "" },
                ],
                goaladditionalpensionData: [
                  { id: 0, isDeleted: 0, szName: "", amount: "" },
                ],
              },
            ],
          });
        } else {
          setIsSaveInvalid(true);
        }
      } else {
        setGoals([
          ...goals,
          {
            id: 0,
            goaladditionalotherData: [
              { id: 0, isDeleted: 0, szName: "", amount: "" },
            ],
            goaladditionalpensionData: [
              { id: 0, isDeleted: 0, szName: "", amount: "" },
            ],
          },
        ]);
        form.setFieldsValue({
          goals: [
            ...goals,
            {
              id: 0,
              goaladditionalotherData: [
                { id: 0, isDeleted: 0, szName: "", amount: "" },
              ],
              goaladditionalpensionData: [
                { id: 0, isDeleted: 0, szName: "", amount: "" },
              ],
            },
          ],
        });
      }
      // Validate the form fields
    } catch (error) {
      console.log("Validation failed:", error);
      form.submit();
    }
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      console.log({
        szReference: id,
        ...values.goals[clickedIndex],
        szRecommendation: recText?.[clickedIndex],
        monthlySocialSecurityIncome:
          values.goals[clickedIndex]?.monthlySocialSecurityIncome || 0,
        annualSavingNeeded: parseFloat(
          annualRetiredIncome?.[clickedIndex]
        )?.toFixed(2),
        monthlySavingNeeded: parseFloat(
          monthlyRetiredIncome?.[clickedIndex] / 12
        )?.toFixed(2),
        monthlyGoalSavingAmount: parseFloat(
          monthlyAmt?.[clickedIndex] || 0
        )?.toFixed(2),
        lumpSumSavingAmount: parseFloat(
          lumSumAmt?.[clickedIndex] || 0
        )?.toFixed(2),
        remainingBudRag: usedBudRag?.[clickedIndex],
      });
      let payload = {
        szReference: id,
        ...values.goals[clickedIndex],
        szRecommendation: recText?.[clickedIndex],
        monthlySocialSecurityIncome:
          values.goals[clickedIndex]?.monthlySocialSecurityIncome || 0,
        annualSavingNeeded: parseFloat(
          annualRetiredIncome?.[clickedIndex] || 0
        )?.toFixed(2),
        monthlySavingNeeded: parseFloat(
          monthlyRetiredIncome?.[clickedIndex] || 0
        )?.toFixed(2),
        monthlyGoalSavingAmount: parseFloat(
          monthlyAmt?.[clickedIndex] || 0
        )?.toFixed(2),
        lumpSumSavingAmount: parseFloat(
          lumSumAmt?.[clickedIndex] || 0
        )?.toFixed(2),
        remainingBudRag: parseFloat(usedBudRag?.[clickedIndex]?.toFixed(2)),
      };
      if (
        payload?.goaladditionalotherData &&
        deletedOtherSource?.[clickedIndex]?.length > 0
      ) {
        payload.goaladditionalotherData = [
          ...payload.goaladditionalotherData,
          ...deletedOtherSource[clickedIndex],
        ];
      }
      if (
        payload?.goaladditionalpensionData &&
        deletedPensionSource?.[clickedIndex]?.length > 0
      ) {
        payload.goaladditionalpensionData = [
          ...payload.goaladditionalpensionData,
          ...deletedPensionSource[clickedIndex],
        ];
      }

      const response = await postRequest(SAVE_FINANCIAL_GOAL_API, payload);

      setIsLoading(false);

      if (response?.code === 200) {
        setSuccessMessage(response?.data?.msg);
        fetchData();
        window.scrollTo(0, 0);
      } else {
        setErrorMessage("Something went wrong");
      }
    } catch (error) {
      setIsLoading(false);
      console.log(JSON.stringify(error));
      setErrorMessage("Something went wrong");
    }
  };

  const handleDelete = async () => {
    setIsDelete(false);
    setIsLoading(true);
    try {
      const response = await postRequest(DELETE_FINANCIAL_GOAL_API, {
        szReference: id,
        id: goals?.[deleteID]?.id,
      });

      if (response?.code === 200) {
        setSuccessMessage(response?.data?.msg);
        fetchData();

        setDeleteId();
      } else {
        setErrorMessage("Something went wrong");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const renderFormBasedOnGoal = (goal, name) => {
    console.log({ goal, name });
    if (goal?.includes("debtrepayment")) {
      return renderDebitForm(name, goal);
    } else if (goal?.includes("retirement")) {
      return renderRetirementForm(name);
    } else if (
      goal === "carpurchase" ||
      goal === "dreamhome-apartment" ||
      goal === "2nd-investment-vacation-property"
    ) {
      return investmentPropertyPurchase(name, goal);
    } else if (
      goal === "travel" ||
      goal === "wedding" ||
      goal === "homerenovation" ||
      goal === "start-a-business" ||
      goal == "medical-expenses" ||
      goal === "other-major-purchase"
    ) {
      return anyOtherGoal(name, goal);
    }
    return null;
  };

  const onRemovePensionData = (remove, index, indx) => {
    let allGoals = goals;
    if (
      allGoals[indx]?.goaladditionalpensionData &&
      allGoals[indx]?.goaladditionalpensionData[index]?.id > 0
    ) {
      let deletePension = deletedPensionSource;
      if (deletePension[indx]?.length > 0) {
        deletePension[indx].push({
          ...allGoals[indx].goaladditionalpensionData[index],
          isDeleted: 1,
        });
      } else {
        deletePension[indx] = [
          { ...allGoals[indx].goaladditionalpensionData[index], isDeleted: 1 },
        ];
      }

      setDeletedPensionSource(deletePension);
    }
    remove(index);
  };
  const onRemoveOtherData = (remove, index, indx) => {
    let allGoals = goals;
    if (
      allGoals[indx]?.goaladditionalotherData &&
      allGoals[indx]?.goaladditionalotherData[index]?.id > 0
    ) {
      let deletePension = deletedOtherSource;
      if (deletePension[indx]?.length > 0) {
        deletePension[indx].push({
          ...allGoals[indx].goaladditionalotherData[index],
          isDeleted: 1,
        });
      } else {
        deletePension[indx] = [
          { ...allGoals[indx].goaladditionalotherData[index], isDeleted: 1 },
        ];
      }

      setDeletedOtherSource(deletePension);
      // setGoals([...goals])
    }
    remove(index);
  };
  const InputField = ({
    name,
    label,
    type,
    formClass,
    className,
    prefix,
    initialValue,
    inputType,
  }) => (
    <Form.Item
      name={name}
      label={label}
      validateFirst={true}
      className={formClass}
      rules={[
        {
          required: true,
          message: `Please enter ${label}`,
        },
        {
          validator: (_, value) => {
            if (/^\d+(\.\d+)?$/.test(value) || type === "text") {
              return Promise.resolve();
            }
            return Promise.reject("Please enter a valid amount.");
          },
        },
      ]}
      initialValue={initialValue}
    >
      {inputType === "percentage" ? (
        <InputNumber
          className="financilaGoals input-parse"
          controls={false}
          step={"0.00"}
          suffix="%"
        />
      ) : (
        <Input
          className={className || "financilaGoals input-parse"}
          prefix={prefix}
        />
      )}
    </Form.Item>
  );
  const InputNumberField = ({
    name,
    label,
    type,
    formClass,
    className,
    prefix,
    initialValue,
    inputType,
    addonAfter,
    suffix,
    required,
  }) => (
    <Form.Item
      name={name}
      label={label}
      validateFirst={true}
      className={formClass}
      rules={[
        {
          required: required === 0 ? false : true,
          message: `Please enter ${label}`,
        },
        {
          validator: (_, value) => {
            if (
              /^\d+(\.\d+)?$/.test(value) ||
              type === "text" ||
              required === 0
            ) {
              return Promise.resolve();
            }
            return Promise.reject("Please enter a valid amount.");
          },
        },
      ]}
      initialValue={initialValue}
    >
      <InputNumber
        className="financilaGoals input-parse"
        controls={false}
        step={"0.00"}
        prefix={prefix}
        suffix={addonAfter || suffix}
      />
    </Form.Item>
  );

  const renderRetirementForm = (name) => {
    return (
      <>
        <InputField
          name={[name, `inflationRate`]}
          label={`Inflation rate for  ${getGoalName(goals?.[name]?.szType)}`}
          formClass={"apr-input"}
          initialValue={getInflationRate()?.toString()}
          inputType="percentage"
        />
        <p className="aprText">
          The current Inflation rate for this type of goal is{" "}
          {getInflationRate()}%
        </p>
        <InputField
          name={[name, "rateofReturn"]}
          label={`Rate of return for  ${getGoalName(goals?.[name]?.szType)}`}
          formClass={"apr-input"}
          initialValue={4.4}
          inputType="percentage"
        />
        <p className="aprText">
          The current Rate of Return for this type of goal is 4.4000%.
        </p>
        <InputField
          name={[name, `curSavingAccRate`]}
          label="Current Savings Account Rate"
          formClass={"apr-input"}
          initialValue={getInflationRate("currentSavingRate")}
          inputType="percentage"
        />
        <p className="aprText">
          The Current online saving account rate is{" "}
          {getInflationRate("currentSavingRate")}%
        </p>
        <InputNumberField
          name={[name, `perTotalIncomeDesired`]}
          label="% of Total Current Income Desired at Retirement"
          initialValue="70"
          addonAfter={"%"}
        />
        <div className="retirement-age-input">
          <Form.Item
            name={[name, "plannedAgeYears"]}
            label="Planned Retirement Age"
            rules={[
              {
                required: true,
                message: "Please select the year",
              },
            ]}
            initialValue={67}
          >
            <Select
              className="dropdownbutton"
              // style={{ width: 150 }}
              placeholder="Select"
              // defaultValue={67}
            >
              {[...Array(76).keys()]
                .filter((item) => item <= 75 && item >= 45)
                .map((ageItem) => {
                  return (
                    <Option key={ageItem} value={ageItem}>
                      {ageItem} years
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <p>and</p>
          <Form.Item
            name={[name, "plannedAgeMonths"]}
            label="Planned Age Months"
            className="planned-age"
            rules={[
              {
                required: false,
                message: "Please select the month",
              },
            ]}
            // noStyle
            initialValue={0}
          >
            <Select
              className="dropdownbutton month-dropdown"
              // style={{ width: 150 }}
              placeholder="Select"
            >
              {[...Array(12).keys()].map((ageItem) => {
                return (
                  <Option key={ageItem} value={ageItem}>
                    {ageItem} months
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
        {/*  */}
        <Form.Item
          name={[name, "desiredTermYears"]}
          label="Desired retirement term"
          rules={[
            {
              required: true,
              message: "Please select desired retirement term",
            },
          ]}
          initialValue={30}
        >
          <Select className="dropdownbutton year-dropdown" placeholder="Select">
            {[...Array(46).keys()]
              .filter((item) => item >= 1)
              .map((ageItem) => {
                return (
                  <Option key={ageItem} value={ageItem}>
                    {ageItem} {ageItem > 1 ? "years" : "year"}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        <div className="bothtextdiv">
          <p className="heading">
            Monthly Pension/Annuity Income Expected at Retirement
          </p>
          <p className="headingtext">
            This is your current benefit amount listed on your pension and/or
            annuity statement. If there is more than one pension and/or annuity
            please combine all benefit amounts.
          </p>
        </div>
        <Form.Item label="">
          <Form.List name={[name, "goaladditionalpensionData"]}>
            {(subFields, { add, remove }) => (
              <>
                {subFields.map((subField) => (
                  <div className="bothdiv mt-1">
                    <div>
                      <CloseOutlined
                        className="cross-sign cursor-pointer"
                        onClick={() =>
                          onRemovePensionData(remove, subField.name, name)
                        }
                      />
                    </div>
                    <Form.Item
                      name={[subField.name, "szName"]}
                      label="Pension/annuity source"
                      type="text"
                      className="formitem"
                    >
                      <Input className={"financilaGoals1"} />
                    </Form.Item>
                    <InputNumberField
                      name={[subField.name, "amount"]}
                      label="Expected monthly amount"
                      prefix={"$"}
                      step={"0.00"}
                      controls={false}
                      required={0}
                    />
                  </div>
                ))}
                <div>
                  <p className="no-margin">
                    +{" "}
                    <span
                      className="addtext"
                      onClick={() => add({ id: 0, isDeleted: 0 })}
                    >
                      Add another source
                    </span>
                  </p>
                </div>
              </>
            )}
          </Form.List>
        </Form.Item>
        <br />
        <InputNumberField
          formClass="apr-input"
          name={[name, `monthlySocialSecurityIncome`]}
          label="Monthly Social Security Income Expected at Retirement"
          prefix={"$"}
          step={"0.00"}
          controls={false}
          required={0}
        />
        <p className="aprText">
          This is your current benefit amount listed on your social security
          statement, which can be obtained at the social security{" "}
          <a
            rel="noreferrer"
            style={{ cursor: "pointer" }}
            target="_blank"
            href="https://www.ssa.gov/"
          >
            website
          </a>
          .
        </p>

        <div className="bothtextdiv">
          <p className="heading">Monthly Other Income Expected at Retirement</p>
          <p className="headingtext">
            This includes any other income you may receive at retirement or
            income you’re receiving now that will continue through retirement,
            such as disability pay through the Veterans Affairs.
          </p>
        </div>

        <Form.Item label="">
          <Form.List name={[name, "goaladditionalotherData"]}>
            {(subFields, { add, remove }) => (
              <>
                {subFields.map((subField) => (
                  <div className="bothdiv mt-1">
                    <div
                      onClick={() =>
                        onRemoveOtherData(remove, subField.name, name)
                      }
                    >
                      <CloseOutlined className="cross-sign" />
                    </div>

                    <Form.Item
                      label="Other income source"
                      type="text"
                      className="formitem"
                      name={[subField.name, `szName`]}
                    >
                      <Input
                        className={"financilaGoals1"}
                        // prefix={"$"}
                      />
                    </Form.Item>
                    <InputNumberField
                      name={[subField.name, `amount`]}
                      label="Monthly other income amount"
                      prefix={"$"}
                      step={"0.00"}
                      controls={false}
                      required={0}
                    />
                  </div>
                ))}
                <div onClick={() => add({ id: 0, isDeleted: 0 })}>
                  <p>
                    + <span className="addtext">Add another income</span>
                  </p>
                </div>
              </>
            )}
          </Form.List>
        </Form.Item>

        <InputNumberField
          name={[name, "postRateOfReturn"]}
          label="Post-retirement rate of return"
          suffix={"%"}
          initialValue={4.01}
        />

        <label className="label-text">Annual Retirement Savings Needed</label>
        <p className="aprText">
          {numberFormatBasic(
            annualRetiredIncome?.[name] ||
              goals?.[name]?.annualSavingNeeded ||
              0
          )}
        </p>

        <label className="label-text">Monthly Retirement Savings Needed</label>
        <p className="aprText">
          {numberFormatBasic(
            monthlyRetiredIncome?.[name] ||
              goals?.[name]?.monthlySavingNeeded ||
              0
          )}
        </p>

        <Checkbox
          onChange={(e) => onChange(e, name)}
          checked={isAdditional?.[name]}
        >
          Show Additional Values
        </Checkbox>
        <br />
        {isAdditional?.[name] && (
          <>
            <label className="label-text">Cash Flow Needed At Retirement</label>
            <p className="aprText">
              {numberFormatBasic(cashFlow?.[name] || 0)}
            </p>

            <label className="label-text">Future Value of Salary</label>
            <p className="aprText">
              {numberFormatBasic(fvSalary?.[name] || 0)}
            </p>

            <label className="label-text">
              Present Value at age of Retirement
            </label>
            <p className="aprText">
              {numberFormatBasic(pvRetirement?.[name] || 0)}
            </p>

            <label className="label-text">
              Future Value of Retirment Savings
            </label>
            <p className="aprText">
              {numberFormatBasic(fvRetirement?.[name] || 0)}
            </p>

            <label className="label-text">Net Need of Retirement Money</label>
            <p className="aprText">
              {numberFormatBasic(netNeedMoney?.[name] || 0)}
            </p>
          </>
        )}

        <div className="recommendationdiv">
          <p className="repaytext">Recommendation</p>
          <div className="ptext">
            <p className="debttext">{recText?.[name]}</p>
          </div>
        </div>
      </>
    );
  };

  const getMonthlyGoalSavingAmt = (B11, B12, B13, B14, B5) => {
    const numerator = (B11 - B12 - B13) * (B14 / 12);
    const denominator = Math.pow(1 + B14 / 12, B5) - 1;
    const result = B11 - B12 <= 0 ? 0 : numerator / denominator;

    return result;
  };

  const getMiniMonPmt = (goal, key) => {
    let type = [];

    if (goal === "debtrepayment-creditcard") {
      type = ["creditCard"];
    } else if (goal === "debtrepayment-studentloan") {
      type = ["studentLoan", "studentLoanAccount", "studentLoanGroup"];
    } else if (goal === "debtrepayment-personalloan") {
      type = ["personalLoan", "personalLoanAccount", "personalLoanGroup"];
    }
    const filterDebtType = userData?.accounts?.filter((item) =>
      type?.includes(item?.type)
    );
    const sum = filterDebtType?.reduce(
      (accumulator, currentValue) => accumulator + (currentValue?.[key] || 0),
      0
    );
    console.log("sum===", key, sum);

    return Math.abs(parseFloat(sum));
  };

  const getBalance = () => {
    const sum = userData?.accounts?.reduce(
      (accumulator, currentValue) =>
        accumulator + Math.abs(currentValue?.["currentBalance"] || 0),
      0
    );

    return Math.abs(sum)?.toFixed(2);
  };

  // currentBalance
  const renderDebitForm = (name, goal) => {
    return (
      <>
        <Form.Item
          name={[name, `curMinMonthlyPayment`]}
          label="Current Minimum Monthly Payment"
          validateFirst={true}
          rules={[
            {
              required: true,
              message: "Please enter the minimum monthly payment",
            },
            {
              validator: (_, value) => {
                if (/^\d+(\.\d+)?$/.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject("Please enter a valid amount.");
              },
            },
          ]}
          initialValue={getMiniMonPmt(goal, "paymentMinAmount")?.toFixed(2)}
        >
          <InputNumber
            step={"0.00"}
            className="financilaGoals input-parse"
            prefix={"$"}
            controls={false}
          />
        </Form.Item>

        <Form.Item
          name={[name, `interestRate`]}
          label="APR (Interest Rate)"
          validateFirst={true}
          className="apr-input"
          rules={[
            {
              required: true,
              message: "Please enter the APR (Interest Rate)",
            },
            {
              validator: (_, value) => {
                if (/^\d+(\.\d+)?$/.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject("Please enter a valid amount.");
              },
            },
          ]}
          initialValue={getApr(goal)}
        >
          <InputNumber
            className="financilaGoals input-parse"
            step={"0.00"}
            controls={false}
            suffix={"%"}
          />
        </Form.Item>
        <p className="aprText">
          Enter your actual interest rate - we've prefilled this field with the
          current average.
        </p>

        <Form.Item
          name={[name, `amountToRepaid`]}
          label="Amount of debt to be repaid"
          validateFirst={true}
          initialValue={getMiniMonPmt(goal, "balance")?.toFixed(2)}
          rules={[
            {
              required: true,
              message: "Please enter the amount of debt to be repaid",
            },
            {
              validator: (_, value) => {
                if (/^\d+(\.\d+)?$/.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject("Please enter a valid amount.");
              },
            },
          ]}
        >
          <InputNumber
            step={"0.00"}
            className="financilaGoals input-parse"
            prefix={"$"}
            controls={false}
          />
        </Form.Item>

        <div className="repay-input-section">
          <Form.Item
            name={[name, `repayIn`]}
            label="Repay in"
            validateFirst={true}
            rules={[
              {
                required: true,
                message: "Please enter the amount of repay in",
              },
              {
                validator: (_, value) => {
                  if (/^\d+(\.\d+)?$/.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Please enter a valid amount.");
                },
              },
            ]}
          >
            <Input className="financilaGoals" />
          </Form.Item>

          <Form.Item
            name={[name, `repayType`]}
            label=""
            rules={[
              {
                required: true,
                message: "Please select the repayment type",
              },
            ]}
            initialValue={"months"}
            className="d-flex radio-optional"
          >
            <Radio.Group className="radio">
              <Radio value={"months"}>Months</Radio>
              <Radio value={"years"}>Years</Radio>
            </Radio.Group>
          </Form.Item>
        </div>

        <Checkbox
          onChange={(e) => onChange(e, name)}
          checked={isAdditional?.[name]}
        >
          Show Additional Values
        </Checkbox>
        <br />
        {isAdditional?.[name] && (
          <>
            <label className="label-text">
              Months To Save For Financial Foundation
            </label>
            <p className="aprText">
              {parseFloat(
                userData?.importantValues?.TTLFinancialFoundation || 0
              )}
            </p>

            <label className="label-text">
              Min Monthly Payment Months To Payoff
            </label>
            <p className="aprText">
              {numberFormatBasic(minMonPmtMon?.[name] || 0)}
            </p>
            <label className="label-text">
              Principal After Fin Foundation is Saved
            </label>
            <p className="aprText">
              {numberFormatBasic(principalAfterFin?.[name] || 0)}
            </p>
            <label className="label-text">Goal Monthly Payment</label>
            <p className="aprText">
              {numberFormatBasic(goalMonthlyPayment?.[name] || 0)}
            </p>
            <label className="label-text">Max Monthly Payment</label>
            <p className="aprText">
              {numberFormatBasic(maxMonthlyPmt?.[name] || 0)}
            </p>
            <label className="label-text">Actual Months To Payoff Debt</label>
            <p className="aprText">
              {parseFloat(actMonPayOff?.[name] || 0)?.toFixed(2)}
            </p>
            <label className="label-text">
              Rounded Actual Months To Payoff Debt
            </label>
            <p className="aprText">
              {parseFloat(roundedActMonPay?.[name] || 0)?.toFixed(2)}
            </p>
            <label className="label-text">Goal Payment Amount</label>
            <p className="aprText">
              {numberFormatBasic(goalPmtAmt?.[name] || 0)}
            </p>
          </>
        )}
        <div className="recommendationdiv">
          <p className="repaytext">Recommendation</p>
          <div className="ptext">
            <p className="debttext">{recText?.[name]}</p>
          </div>
        </div>
      </>
    );
  };

  const getGoalName = (goal = "") => {
    switch (goal?.toLowerCase()) {
      case "carpurchase":
        return "Car Purchase";
      case "dreamhome-apartment":
        return "Dream Home/Apartment";
      case "dreamhome apartment":
        return "Dream Home/Apartment";
      case "2nd investment-vacation-property":
        return "2nd/Investment/Vacation Property";
      case "2nd-investment-vacation-property":
        return "2nd/Investment/Vacation Property";
      case "retirement-partner":
        return "Retirement (Partner)";
      case "retirement-self":
        return "Retirement (Self)";
      case "start-a-business":
        return "Start a Business";
      case "start a-business":
        return "Start a Business";
      case "Wedding":
        return "Wedding";
      case "homerenovation":
        return "Home Renovation";
      case "medical-expenses":
        return "Medical Expenses";
      case "other-major-purchase":
        return "Other Major Purchase";
      case "other major-purchase":
        return "Other Major Purchase";
      case "debtrepayment-creditcard":
        return "Debt Repayment Credit Card";
      case "debtrepayment-studentloan":
        return "Debt Repayment Student Loan";
      case "debtrepayment-personalloan":
        return "Debt Repayment Personal Loan";
      case "travel":
        return "Travel";

      default:
        return goal;
    }
  };

  const investmentPropertyPurchase = (name, goal = "carpurchase") => {
    let goalOriginal = goal;
    goal = goal.charAt(0).toUpperCase() + goal.slice(1).replace("-", " ");
    const LengthInMonths2 =
      goals?.[name]?.repayType === "months"
        ? goals?.[name]?.repayIn || 0
        : (goals?.[name]?.repayIn || 0) * 12;
    return (
      <>
        <div className="inputs-radios time-save-1">
          <Form.Item
            name={[name, `repayIn`]}
            label="Length of Time To Save For Your Goal"
            rules={[
              {
                required: true,
                message:
                  "Please enter the length of time to save for your goal",
              },
            ]}
          >
            <Input className="financilaGoals" />
          </Form.Item>

          <Form.Item
            name={[name, `repayType`]}
            label="Months or Years?"
            rules={[
              {
                required: true,
                message:
                  "Please select if you want to save for this goal in months or years",
              },
            ]}
            initialValue={"months"}
            className="d-flex radio-optional"
          >
            <Radio.Group className="radio">
              <Radio value={"months"}>Months</Radio>
              <Radio value={"years"}>Years</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <Form.Item
          name={[name, `currentCost`]}
          label={`Current Cost of ${getGoalName(goal)}`}
          rules={[
            {
              required: true,
              message: `Please enter the current cost of ${getGoalName(goal)}.`,
            },
          ]}
        >
          <InputNumber
            className="financilaGoals input-parse"
            prefix={"$"}
            step={"0.00"}
            controls={false}
          />
        </Form.Item>
        <Form.Item
          name={[name, `downPaymentPercent`]}
          label="Down Payment %"
          rules={[
            {
              required: true,
              message: "Please enter the Down Payment %",
            },
          ]}
        >
          <InputNumber
            className="financilaGoals input-parse"
            step={"0.00"}
            suffix="%"
            controls={false}
          />
        </Form.Item>
        <Form.Item
          name={[name, `inflationRate`]}
          label={`Inflation Rate for ${getGoalName(goal)}`}
          className="apr-input"
          rules={[
            {
              required: true,
              message: `Please enter the inflation rate for ${getGoalName(
                goal
              )}`,
            },
          ]}
          initialValue={
            LengthInMonths2 > 12 ? getInflationRate(goalOriginal) : 0
          }
        >
          <InputNumber
            className="financilaGoals input-parse"
            step={"0.00"}
            suffix="%"
            controls={false}
          />
        </Form.Item>
        <p className="aprText">
          The current inflation rate for this type of goal is{" "}
          {LengthInMonths2 > 12 ? getInflationRate(goalOriginal) : 0}%
        </p>

        <Form.Item
          name={[name, `rateofReturn`]}
          label={`Rate of Return for ${getGoalName(goal)}`}
          className="apr-input"
          rules={[
            {
              required: true,
              message: `Please enter the rate of return for ${getGoalName(
                goal
              )}`,
            },
          ]}
          initialValue={4.4}
        >
          <InputNumber
            className="financilaGoals input-parse"
            step={"0.00"}
            controls={false}
            suffix="%"
          />
        </Form.Item>
        <p className="aprText">
          The current Rate of Return for this type of goal is 4.4000%
        </p>

        <Form.Item
          name={[name, `curSavingAccRate`]}
          label="Current Savings Account Rate"
          className="apr-input"
          rules={[
            {
              required: true,
              message: "Please enter the current savings account rate",
            },
          ]}
          initialValue={getInflationRate("currentSavingRate")?.toString()}
        >
          <InputNumber
            className="financilaGoals input-parse"
            step={"0.00"}
            controls={false}
            suffix="%"
          />
        </Form.Item>
        <p className="aprText">
          The current online savings account rate is{" "}
          {getInflationRate("currentSavingRate")}%
        </p>

        <label className="label-text">Monthly Goal Savings Amount</label>
        <p className="aprText">
          {numberFormatBasic(
            (monthlyAmt?.[name] > 0
              ? monthlyAmt?.[name]
              : goals?.[name]?.monthlyGoalSavingAmount) || 0
          )}
        </p>

        <label className="label-text">Lump Sum Savings Amount</label>
        <p className="aprText">
          {numberFormatBasic(
            lumSumAmt?.[name] || goals?.[name]?.lumpSumSavingAmount || 0
          )}
        </p>
        <div className="recommendationdiv">
          <p className="repaytext">Recommendation</p>
          <div className="ptext">
            <p className="debttext">{recText?.[name]}</p>
          </div>
        </div>
      </>
    );
  };
  const anyOtherGoal = (name, goal = "travel") => {
    const goalOriginal = goal;
    goal = goal.charAt(0).toUpperCase() + goal.slice(1).replace("-", " ");
    const LengthInMonths2 =
      goals?.[name]?.repayType === "months"
        ? goals?.[name]?.repayIn || 0
        : (goals?.[name]?.repayIn || 0) * 12;

    const inflationRateInitialValue =
      LengthInMonths2 > 12 ? getInflationRate(goalOriginal) : 0;

    return (
      <>
        <div className="inputs-radios time-save-2">
          <Form.Item
            className="input-radio"
            name={[name, `repayIn`]}
            label="Length of Time To Save For Your Goal"
            rules={[
              {
                required: true,
                message:
                  "Please enter the length of time to save for your goal",
              },
            ]}
          >
            <Input className="financilaGoals" />
          </Form.Item>
          <Form.Item
            name={[name, `repayType`]}
            label="Months or Years?"
            rules={[
              {
                required: true,
                message: "Please select the repayment type",
              },
            ]}
            initialValue={"months"}
            className="d-flex radio-optional"
          >
            <Radio.Group className="radio">
              <Radio value={"months"}>Months</Radio>
              <Radio value={"years"}>Years</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <Form.Item
          name={[name, `currentCost`]}
          label={`Current Cost of ${getGoalName(goal)}`}
          rules={[
            {
              required: true,
              message: `Please enter the current cost of ${getGoalName(goal)}.`,
            },
          ]}
        >
          <InputNumber
            className="financilaGoals input-parse"
            prefix={"$"}
            step={"0.00"}
            controls={false}
          />
        </Form.Item>
        <Form.Item
          name={[name, `inflationRate`]}
          label={`Inflation Rate for ${getGoalName(goal)}`}
          className="apr-input"
          rules={[
            {
              required: true,
              message: `Please enter the inflation Rate for ${getGoalName(
                goal
              )}`,
            },
          ]}
          initialValue={inflationRateInitialValue}
        >
          <InputNumber
            className="financilaGoals input-parse"
            step={"0.00"}
            controls={false}
            suffix="%"
          />
        </Form.Item>
        <p className="aprText">
          The current inflation rate for this type of goal is{" "}
          {inflationRateInitialValue}%
        </p>

        <Form.Item
          name={[name, `rateofReturn`]}
          label={`Rate of Return for ${getGoalName(goal)}`}
          className="apr-input"
          rules={[
            {
              required: true,
              message: `Please enter the rate of return for ${getGoalName(
                goal
              )}`,
            },
          ]}
          initialValue={4.4}
        >
          <InputNumber
            className="financilaGoals input-parse"
            step={"0.00"}
            controls={false}
            suffix="%"
          />
        </Form.Item>
        <p className="aprText">
          The current Rate of Return for this type of goal is 4.4000%
        </p>

        <Form.Item
          name={[name, `curSavingAccRate`]}
          label="Current Savings Account Rate"
          className="apr-input"
          rules={[
            {
              required: true,
              message: "Please enter the current savings account rate",
            },
          ]}
          initialValue={getInflationRate("currentSavingRate")}
        >
          <InputNumber
            className="financilaGoals input-parse"
            step={"0.00"}
            controls={false}
            suffix="%"
          />
        </Form.Item>
        <p className="aprText">
          The current online saving account rate is 4.4000%{" "}
        </p>

        <label className="label-text">Monthly Goal Savings Amount</label>
        <p className="aprText">
          {numberFormatBasic(
            (monthlyAmt?.[name] > 0
              ? monthlyAmt?.[name]
              : goals?.[name]?.monthlyGoalSavingAmount) || 0
          )}
        </p>

        <label className="label-text">Lump Sum Savings Amount</label>
        <p className="aprText">
          {numberFormatBasic(
            lumSumAmt?.[name] || goals?.[name]?.lumpSumSavingAmount || 0
          )}
        </p>

        <div className="recommendationdiv">
          <p className="repaytext">Recommendation</p>
          <div className="ptext">
            <p className="debttext">{recText?.[name]}.</p>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="personalmainheader">
      <div className="personaltextbackground">
        <p className="personaltext">Financial Goals</p>
      </div>
      <div className="line"></div>
      <div className="from-section insurance-form">
        {!loading ? (
          <div className="formdiv">
            {goals?.length > 0 ? (
              <Form
                form={form}
                initialValues={{ goals }}
                onFinish={onFinish}
                onValuesChange={debounce(calcRec, 1000)}
                key={"goals"}
                layout="vertical"
                scrollToFirstError={{
                  behavior: "smooth",
                  block: "center",
                  inline: "center",
                }}
              >
                <Form.List name="goals">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name }, index) => (
                        <div key={key}>
                          <div className="meetingline">
                            <div>
                              <h3 className="meetingText">{`${firstName} ${getOrdinalNumber(
                                index
                              )} goal`}</h3>
                            </div>
                          </div>

                          <div className="finanicial-form-section">
                            <Form.Item
                              formClass={"apr-input"}
                              name={[name, "szType"]}
                              label="Financial Goal"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select the goal",
                                  whitespace: true,
                                },
                              ]}
                            >
                              <Select
                                className="dropdownbutton"
                                placeholder="Select Goal"
                              >
                                {items.map((item) => (
                                  <Option key={item.value} value={item.value}>
                                    {item.label}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>

                            {renderFormBasedOnGoal(
                              form.getFieldsValue()?.goals?.[name]?.szType ||
                                goals?.[name]?.szType,
                              name
                            )}

                            <div className="btm-btn-section">
                              <Form.Item>
                                <Button
                                  type="primary"
                                  className="primary-medium-btn"
                                  htmlType="submit"
                                  onClick={() => setClickedIndex(index)}
                                >
                                  Save changes
                                </Button>
                              </Form.Item>

                              {isSaveInvalid && (
                                <div style={{ color: "#ff4d4f" }}>
                                  Please save the changes
                                </div>
                              )}

                              {goals?.length > 0 && (
                                <div
                                  className="iconText"
                                  onClick={() => {
                                    if (goals?.[index]?.id > 0) {
                                      setDeleteId(index);
                                      setIsDelete(true);
                                    } else {
                                      remove(name);
                                    }
                                  }}
                                >
                                  <p className="deleteText cursor-pointer">
                                    <CloseOutlined className="crossicon" />
                                    Delete goal
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>

                          {fields?.length - 1 === index && (
                            <Form.Item>
                              <Button
                                type="button"
                                icon={<PlusOutlined />}
                                onClick={addGoal}
                                className="mt-2 another-btn"
                              >
                                Add another Goal
                              </Button>
                            </Form.Item>
                          )}
                        </div>
                      ))}
                    </>
                  )}
                </Form.List>
              </Form>
            ) : (
              <div style={{ width: 600 }}>
                <p>No Goal Found.</p>
                <Button
                  type="button"
                  icon={<PlusOutlined />}
                  onClick={addGoal}
                  className="mt-2 another-btn"
                >
                  Add Goal
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div className="formdiv"></div>
        )}
        <div className="ant-notification-notice-content">
          <div className="alert">
            Personal financial goals are individual objectives that focus on
            managing and improving one's financial well-being. These goals
            typically involve various aspects of personal finance, such as
            saving, budgeting, investing, and debt management.
          </div>
        </div>
      </div>
      {isDelete && (
        <DeleteConfirmationModal
          desc={"goal"}
          isModalVisible={isDelete}
          setIsModalVisible={setIsDelete}
          handleDelete={handleDelete}
        />
      )}
    </div>
  );
}

export default FinancialGoals;
