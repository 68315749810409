import React from 'react';
function Footer() {
    return (
        <div className='footer'>
            <div className='footer-img'>
                <div className='footer-imgs'>
                    <img className="colored-image" src={require('../assets/images/logo.png')} alt="react logo" height={30} width={100}  />
                </div>
            </div>
            <div className='footer2'>
                <div className='footer3'>
                    <p className='optiontext'>About</p>
                    <p className='optiontext'>Contact us</p>
                    <p className='optiontext'>FAQ</p>
                    <p className='optiontext'>Terms of Use</p>
                    <p className='optiontext' >Sharing</p>
                    <p className='optiontext'>Privacy Policy</p>
                    <p className='optiontext'>About</p>
                </div>
            </div>
            <div className='footerparacontainent'>
                <div className='footerpara'>
                    <p className='paragraph'>The material contained on this website is for informational purposes only and Fisocal is not saliciting any action based upon such material.Fisecal
                        does not represent that any material provided on the website is accurate or complete and should not be relled on as such. Reference on the website to any
                        product,services,or links to third parties or other information does not neccessarlly constitute or imply endorsement.Sponership or recommendation.Fisecal gives no warranty,express or implied,as to the
                        accuracy,reliability,utility or completeness of any impformation contained in any electronic documents.
                    </p>
                </div>
            </div>
        </div>
    )
}
export default Footer;