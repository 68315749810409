import React, { useState, useEffect } from 'react';
import { Card, Form, Input, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { postRequest } from '../../services/apiService';
import { FORGOT_PASSWORD_API, LOGIN_API } from '../../services/apiConstant';
import Header from '../../components/Header';
import { showLoading } from 'react-global-loading';
import { useApiMessages } from '../../context/ApiMessageContext';
import { maskedEmail } from '../../utils/helper';
import { useAppContext } from '../../context/AppContext';

function Login() {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [isLogin, setIsLogin] = useState(true);
  const { setErrorMessage, setSuccessMessage } = useApiMessages();
  const { setFirmDetails } = useAppContext();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = 'Advisor-Login'; // Set the desired title
    return () => {
      form.resetFields();
    }
  }, []);

  // Validation functions
  const isEmailValid = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailRegex.test(email);
  };

  const isPasswordValid = (password) => {
    return password.length >= 6;
  };

  const validateForm = async () => {
    try {
      const values = await form.validateFields();
      const errors = {};

      if (!isEmailValid(values.email)) {
        errors.email = ['Invalid email'];
      }

      if (!isPasswordValid(values.password)) {
        errors.password = ['Password must be at least 6 characters long'];
      }

      form.setFields([
        {
          name: 'email',
          errors: errors.email,
        },
        {
          name: 'password',
          errors: errors.password,
        },
      ]);

      return Object.keys(errors).length === 0;
    } catch (error) {
      return false;
    }
  };

  const handleLogin = async () => {
    
    if (await validateForm()) {
      setLoading(true);
      try {
        const response = await postRequest(LOGIN_API, {
          email: form.getFieldValue('email'),
          password: form.getFieldValue('password'),
        });
        setLoading(false);
        if (response?.code === 200) {
          const { email, first_name, szReference } = response?.data;
          setFirmDetails(response?.data);
          localStorage.setItem("token", response?.data?.jwtLoginToken);
          localStorage.setItem("szReference",szReference);
          setSuccessMessage(`Welcome ${first_name? first_name: maskedEmail(email)}`);
          form.resetFields();
          navigate('/');
        }
        else{
          setErrorMessage(response?.data?.msg);
        }
      } catch (error) {
        setLoading(false);
        // Handle API call error here
        setErrorMessage(error?.response?.data?.data?.msg);
      }
    }
  };

  const handleForgotPassword = async () => {
    const values = await form.validateFields();
    const errors = {};
    setErrorMessage('');
    showLoading(true);
    if (isEmailValid(values.femail)) {
      errors.femail = ['Invalid email'];
      try {
        const response = await postRequest(FORGOT_PASSWORD_API, {
          email: form.getFieldValue('femail'),
        });
        showLoading(false);
        console.log(response)

        if (response?.code === 200) {
          setSuccessMessage(response?.data?.msg);
          setIsLogin(true);
          form.resetFields();
        }
        else {
          setErrorMessage(response?.data?.msg);
        }
      } catch (error) {
        showLoading(false);
        // Handle API call error here
        console.log(error?.response?.data?.data);
        if (error?.response?.data?.data?.msg) {
          setErrorMessage(error?.response?.data?.data?.msg);
        }
      }
    } else {
      form.setFields([
        {
          name: 'email',
          errors: "Invalid email",
        },
      ]);
    }
  };

  return (
    <>
      <Header/>
      <div className='MainContainer'>
        {isLogin ? (
          <Card bordered={false} className='card'>
            <div className="ordiv">
              <div className='welcometext'>Welcome back!</div>
            </div>
            <br/>
            <br/>
            <Form
              form={form}
              onFinish={handleLogin}
              validateTrigger="onBlur"
            >
              <Form.Item
                name="email"
                validateTrigger={['onBlur']}
                rules={[
                  {
                    required: true,
                    message: 'Please enter your email',
                  },
                  {
                    type: 'email',
                    message: 'Please enter a valid email',
                  },
                ]}
              >
                <Input className='input1' placeholder="Email" />
              </Form.Item>
              <Form.Item
                name="password"
                validateTrigger={['onBlur']}
                rules={[
                  {
                    required: true,
                    message: 'Please enter your password',
                  },
                ]}
              >
                <Input.Password className='input1' placeholder="Password" visibilityToggle={false} />
              </Form.Item>
              <div className='id'>
                <p className='resetText' onClick={() => setIsLogin(false)}>Reset my Password</p>
              </div>
              <Form.Item>
                <Button type="primary" block htmlType="submit" className='primary-medium-btn' loading={loading}>
                Sign in
                </Button>
              </Form.Item>
              <p className='para'>
               By clicking sign in you agree to our
                <a className="text2" href=""> Terms & Conditions </a>and acknowledge our
                <a className="text2" href=""> Privacy Policy</a>
              </p>
            </Form>
          </Card>
        ) : (
          <Card bordered={false} className='card'>
            <div className="ordiv">
              <div className='welcometext'>Forgot Password?</div>
              <p>Enter your email address below and we'll send you a link to reset your password.</p>
            </div>
            <Form
              form={form}
              onFinish={handleForgotPassword}
              validateTrigger="onBlur"
            >
              <Form.Item
                name="femail"
                validateTrigger={['onBlur']}
                rules={[
                  {
                    required: true,
                    message: 'Please enter your email',
                  },
                  {
                    type: 'email',
                    message: 'Please enter a valid email',
                  },
                ]}
              >
                <Input className='input1' placeholder="Email" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" block htmlType="submit" className='primary-medium-btn mt-1'>
                 Reset your Password
                </Button>
              </Form.Item>
              <p className='para'>
                <span className='text1'>Here by mistake?</span>
                <a className='text2' onClick={() => setIsLogin(true)}> Head Back. </a>
              </p>
            </Form>
          </Card>
        )}
      </div>
      {/* <Footer /> */}
    </>
  );
}

export default Login;
