import React, { useState, useEffect } from "react";
import { Button, Form } from "antd";
import { postRequest } from '../../../../services/apiService';
import { showLoading } from 'react-global-loading';
import { useApiMessages } from '../../../../context/ApiMessageContext';
import './index.scss';
import { UPDATE_FIRM_SETTING_API, GET_FIRM_SETTING_API } from "../../../../services/apiConstant";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
function Conclusion() {
  const [formConclusion] = Form.useForm();
  const [data, setData] = useState(null);
  const { setErrorMessage, setSuccessMessage } = useApiMessages();

  const szReference = localStorage.getItem("szReference");

    const getConclusion = async () => {
        showLoading(true);
        try {
            const response = await postRequest(GET_FIRM_SETTING_API, {
                szReference,
            });

      showLoading(false);

      if (response?.code === 200) {
        setData(response?.data);
        formConclusion.setFieldsValue(response?.data);
      } else {
        setErrorMessage("Something went wrong");
      }
    } catch (error) {
      showLoading(false);
      setErrorMessage("Something went wrong");
    }
  };
  useEffect(() => {
    getConclusion();
  }, []);

  const getDefaultConclusion = () => {
    return `And this concludes your ${
      data?.firm_name
    } Financial Plan! On the next few pages, you’ll find the appendices, containing definitions to financial terms${
      data?.iDonotIncludeInFNCGoal
        ? ", plus all plan disclosures (per our lawyers)"
        : ""
    }. Now is the time to schedule your Financial Plan review call with me. I’ll review your Financial Plan with you, talk about additional ways to improve your financial health and reach your goals, as well as answer any questions you may have about your Financial Plan. I look forward to chatting with you!<br><br>

        Your Financial Coach,<br>
        
        ${data?.first_name} ${data?.last_name}`;
  };
  const saveHandler = async () => {
    showLoading(true);
    try {
      const values = await formConclusion.validateFields();
      console.log(values);
      if (!formConclusion.getFieldValue("firm_conclusion")) {
        formConclusion.setFieldValue("firm_conclusion", getDefaultConclusion());
      }
      const response = await postRequest(UPDATE_FIRM_SETTING_API, {
        szReference,
        ...values,
      });

      showLoading(false);

      if (response?.code === 200) {
        setSuccessMessage("Content updated successfully.");
        getConclusion();
        window.scrollTo(0, 0);
      } else {
        setErrorMessage("Something went wrong");
      }
    } catch (error) {
      showLoading(false);
      setErrorMessage("Something went wrong");
      console.log("Form Validation Error:", error);
    }
  };
  return (
    <div className="form-container">
      <Form
        form={formConclusion}
        name="basic-conclusion"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={saveHandler}
        onFinishFailed={() => {}}
        autoComplete="off"
        className="form-section"
      >
        <Form.Item
          name="firm_conclusion"
          initialValue={data?.firm_conclusion}
        >
          <CKEditor
            editor={ClassicEditor}
            data={
              data?.firm_conclusion
                ? data?.firm_conclusion
                : getDefaultConclusion()
            }
            onChange={(event, editor) => {
              const data = editor.getData();
              formConclusion.setFieldValue("firm_conclusion", data);
            }}
          />
          <span style={{ color: "#516E80" }}>
            To ensure correct formatting, please Paste as Plain Text
          </span>
        </Form.Item>
        <Form.Item>
          <Button className="primary-large-btn" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Conclusion;
