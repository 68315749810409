import React, { useEffect, useState } from "react";
import { Space } from "antd";
import { useAppContext } from "../context/AppContext";
import { useApiMessages } from "../context/ApiMessageContext";
import {
  GET_FIRM_SETTING_API,
  BASE_URL,
} from "../services/apiConstant";
import { postRequest } from "../services/apiService";

function HeaderAfterLogin({ setOpen, isMobile }) {
  const { firmDetails, setFirmDetails } = useAppContext();
  const { setErrorMessage } = useApiMessages();


  const szReference = localStorage.getItem("szReference");

  const getFirmDetails = async () => {
    try {
      const response = await postRequest(GET_FIRM_SETTING_API, {
        szReference,
      });

      if (response?.code === 200) {
        let data = response?.data;
        data.firm_logo = data?.firm_logo ? BASE_URL + data?.firm_logo : "";
        setFirmDetails(data);
      }
    } catch (error) {
      setErrorMessage("Something went wrong");
    }
  };

  useEffect(() => {
    getFirmDetails();
  }, []);


  return (
    <div className="headercontainer">
      <div className="logo">
        {isMobile && (
          <div className="hamburger" onClick={() => setOpen(true)}>
            <input type="checkbox" id="check" />
            <label for="check">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  id="menu"
                >
                  <path
                    fill="#000"
                    d="M3 6a1 1 0 0 1 1-1h16a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1zm0 6a1 1 0 0 1 1-1h16a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1zm1 5a1 1 0 1 0 0 2h16a1 1 0 1 0 0-2H4z"
                  ></path>
                </svg>
              </span>
            </label>
          </div>
        )}
        <div
          className="imgheader"
          onClick={() => {
          }}
        >
         
          <Space wrap size={40}>
            {firmDetails?.firm_logo && (
              <img
                src={firmDetails?.firm_logo}
                alt="user images"
              />
            )}
            {!firmDetails?.firm_logo && (
              <img
                src={require("../assets/images/logo.png")}
                alt="react logo"
              />
            )}
          </Space>
        </div>
        <span className="divider"></span>
        <p> Advisor Portal</p>
      </div>

      <div className="right-headr">
        <div className="link-text">
        </div>

        <div className="right-pro-logo">
          <div className="user-name">
            <p>
              {firmDetails?.first_name} {firmDetails?.last_name}
            </p>{" "}
            <span>{firmDetails?.firm_name}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
export default HeaderAfterLogin;
