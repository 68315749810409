import React, { useEffect, useState } from "react";
import { Select, Button, Form, Radio, Input, InputNumber } from "antd";
import { postRequest } from "../../../services/apiService";
import {
  DELETE_NOT_CONNECTED_ACCOUNT,
  GET_NOT_CONNECTED_ACCOUNT,
  GET_QUESTIONNAIRE_DETAILS,
  SAVE_NOT_CONNECTED_ACCOUNT,
  SAVE_QUESTIONNAIRE_DETAILS,
  UPDATE_CLIENT,
} from "../../../services/apiConstant";
import { useParams } from "react-router-dom";
import { useApiMessages } from "../../../context/ApiMessageContext";
import { showLoading } from "react-global-loading";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { capitalizeFirstLetter } from "../../../utils/helper";

const options = [
  { label: "Strongly Agree", value: "5" },
  { label: "Agree", value: "4" },
  { label: "Neutral", value: "3" },
  { label: "Disagree", value: "2" },
  { label: "Strongly Disagree", value: "1" },
];

const Questionnaire = () => {
  const params = useParams();
  const { setErrorMessage, setSuccessMessage } = useApiMessages();
  const szReference = params?.id;
  const [id, setId] = useState(0);
  const [form] = Form.useForm();
  const [data, setData] = useState();
  const [otherAccounts, setOtherAccounts] = useState([]);
  const [isShowAccount, setIsShowAccount] = useState(false);
  const assetOption = [
    { label: "Personal Property", value: "personalProperty" },
    { label: "Car", value: "car" },
    { label: "Primary Home", value: "primaryHome" },
    { label: "2nd and/or Vacation Property", value: "2ndVacationProperty" },

    { label: "Business", value: "business" },
    { label: "Investment Property", value: "investmentProperty" },
    { label: "Checking Account", value: "checkingAccount" },
    { label: "Savings/​Money Market Account", value: "savings" },
    {
      label: "Certificates of Deposit (CD) Account",
      value: "cd",
    },
    { label: "Investment Account", value: "investment" },
    { label: "Retirement Account", value: "retire" },
    { label: "Education Savings Account", value: "educationSaving" },
    { label: "Other Asset", value: "otherAst" },
    // { label: "Other Account", value: "otherAssets" },
  ];

  const debtOption = [
    { label: "Medical Debt", value: "medicalDebt" },
    { label: "Debt in Collections", value: "debtInCollections" },
    { label: "Family Borrowing", value: "familyBorrowing" },
    { label: "Other Debt", value: "otherDebt" },
    { label: "Credit Card", value: "creditCard" },
    { label: "Student Loan", value: "studentLoan" },
    { label: "Car Loan/Lease", value: "carLoan" },
    { label: "Personal Loan/​​Line of Credit", value: "personalLoan" },
    { label: "Mortgage", value: "mortgage" },
    { label: "Home Equity Loan/​​Line of Credit", value: "homeEquityLoan" },
    {
      label: "2nd and/​​or Vacation Property Mortgage",
      value: "2ndVacationPropertyMortgage",
    },
    {
      label: "Investment Property Mortgage",
      value: "investmentPropertyMortgage",
    },
  ];

  const [formData, setFormData] = useState({});

  useEffect(() => {
    showLoading(true);
    // Fetch questionnaire data here if needed
    showLoading(false);
  }, [szReference]);

  useEffect(() => {
    showLoading(true);
    fetchData();
  }, []);

  const fetchData = () => {
    postRequest(GET_QUESTIONNAIRE_DETAILS, { szReference }).then((res) => {
      if (res.code === 200) {
        form.setFieldsValue(res?.data?.questionnaire);
        form.setFieldValue(
          "hasNonConnectedAccounts",
          res?.data?.hasNonConnectedAccounts
        );
        if (res?.data?.hasNonConnectedAccounts === 1) {
          setIsShowAccount(true);
        } else {
          setIsShowAccount(false);
        }
        res?.data?.questionnaire?.id &&
          setId(res?.data?.questionnaire?.id || 0);
        setData({ ...data, ...res?.data?.questionnaire });
      }
      showLoading(false);
    });

    postRequest(GET_NOT_CONNECTED_ACCOUNT, { szReference }).then((res) => {
      console.log("res====", res);
      if (res.code === 200) {
        form.setFieldsValue({
          additonalrecommendations: res?.data,
          otherConnectedAccount: res?.data?.length > 0 ? "yes" : "no",
        });
        let finalData = res?.data?.map((itm, inx) => {
          return {
            ...itm,
            paymentMinAmount: itm.paymentMinAmount
              ? Math.sign(itm.paymentMinAmount) === -1
                ? -Number(itm.paymentMinAmount)
                : itm.paymentMinAmount
              : "",
          };
        });
        console.log({ finalData });
        setFormData(finalData);

        setData({ ...data, additonalrecommendations: res?.data });
        setOtherAccounts(finalData);
      }
    });
  };
  const SelectField = ({
    name,
    label,
    opts = options,
    onChange,
    initialValue,
  }) => (
    <Form.Item
      name={name}
      label={label}
      rules={[
        {
          required: true,
          message: `Please select the ${label?.toLowerCase()}.`,
        },
      ]}
      initialValue={initialValue}
    >
      <Select className="dropdownbutton" onChange={onChange}>
        {opts?.map((opt) => (
          <Select.Option key={opt.value} value={opt.value}>
            {opt.label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );

  const onRemoveData = async(remove, index) => {
    let allData = data;
    if (allData?.additonalrecommendations?.[index]?.id) {
      await postRequest(DELETE_NOT_CONNECTED_ACCOUNT, {
        szReference,
        accountId: allData?.additonalrecommendations?.[index]?.id,
      });
    }

    remove(index);
  };

  const onFinish = async (values) => {
    showLoading(true);
    try {
      const response = await postRequest(SAVE_QUESTIONNAIRE_DETAILS, {
        szReference,
        id: id,
        ...values,
      });

      if (values?.additonalrecommendations?.length > 0) {
        let payload = {
          szReference,
          accounts: values?.additonalrecommendations?.map((item) => {
            let formItem = { ...item };
            if (item.otherAccountType === "asset") {
              formItem.name = assetOption?.find(
                (itm) => itm.value === item?.type
              )?.label;
            } else {
              formItem.name = debtOption?.find(
                (itm) => itm.value === item?.type
              )?.label;
            }

            return formItem;
          }),
        };


        await postRequest(
          SAVE_NOT_CONNECTED_ACCOUNT,
          payload
        );
      }

      await postRequest(UPDATE_CLIENT, {
        szReference,
        hasNonConnectedAccounts: values?.hasNonConnectedAccounts,
      });

      fetchData();

      showLoading(false);

      if (response?.code === 200) {
        window.scrollTo(0, 0);
        setSuccessMessage("Saved Successfully");
      } else {
        setErrorMessage("Something went wrong");
      }
    } catch (error) {
      showLoading(false);
      setErrorMessage("Something went wrong");
      console.error("Form Validation Error:", error);
    }
  };

  const onAddRecommendation = async (isConnected) => {
    try {
      if (otherAccounts.length > 0 && !isConnected) {
        console.log(form.getFieldValue("additonalrecommendations"));
        const allAccounts = form.getFieldValue("additonalrecommendations");
        // if(otherAccounts[otherAccounts?.length -1]?.id > 0){
        setOtherAccounts([
          ...allAccounts,
          { id: 0, description: "", isDeleted: 0 },
        ]);
        form.setFieldsValue({
          additonalrecommendations: [
            ...allAccounts,
            { id: 0, description: "", isDeleted: 0 },
          ],
        });
        // }
        // else{
        //     // setIsSaveInvalid(true)
        // }
      } else {
        setOtherAccounts([
          ...otherAccounts,
          { id: 0, description: "", isDeleted: 0 },
        ]);
        form.setFieldsValue({
          additonalrecommendations: [
            ...otherAccounts,
            { id: 0, description: "", isDeleted: 0 },
          ],
        });
      }
    } catch (error) {
      console.log("Validation failed:", error);
      form.submit();
      // form.scrollToField(error?.errorFields?.[0]?.type?.[1])
    }
  };

  useEffect(() => {
    document.title = "Questionnaire";
    window.scrollTo(0, 0);
  }, []);

  const onChangeValue = (values, val) => {
    console.log({ values, val });
    let changedData = val?.additonalrecommendations;

    if (val?.hasNonConnectedAccounts === 1) {
      if (otherAccounts?.length === 0) {
        onAddRecommendation(true);
        setIsShowAccount(true);
      } else if (!val?.additonalrecommendations) {
        setIsShowAccount(true);
        changedData = otherAccounts;
      }
    } else if (val?.hasNonConnectedAccounts === 0) {
      setIsShowAccount(false);
    }

    setFormData(changedData);
  };

  const InputNumberField = ({
    name,
    label,
    type,
    formClass,
    className,
    prefix,
    initialValue,
    inputType,
    addonAfter,
    suffix,
    required,
  }) => (
    <Form.Item
      name={name}
      label={label}
      validateFirst={true}
      className={formClass}
      rules={[
        {
          required: required === 0 ? false : true,
          message: `Please enter ${label}`,
        },
        {
          validator: (_, value) => {
            if (
              /^\d+(\.\d+)?$/.test(value) ||
              type === "text" ||
              required === 0
            ) {
              return Promise.resolve();
            }
            return Promise.reject("Please enter a valid amount.");
          },
        },
      ]}
      initialValue={initialValue}
    >
      <InputNumber
        className="financilaGoals input-parse"
        controls={false}
        step={"0.00"}
        prefix={prefix}
        suffix={addonAfter || suffix}
      />
    </Form.Item>
  );

  const getAssetForm = (name, index) => {
    console.log("name===", name);

    return (
      <>
        {(formData?.[name]?.type === "educationSaving" ||
          // formData?.[name]?.type === "otherAst" ||
          formData?.[name]?.type === "investment" ||
          formData?.[name]?.type === "retire" ||
          formData?.[name]?.type === "checkingAccount" ||
          formData?.[name]?.type == "savings" ||
          formData?.[name]?.type === "cd") && (
            <>
              <Form.Item
                label="Bank Name"
                name={[name, "bankName"]}
                rules={[
                  {
                    required: true,
                    message: "Please enter the Bank Name.",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <InputNumberField
                prefix="$"
                label="Current Balance"
                name={[name, "currentBalance"]}
              />
            </>
          )}

        {formData?.[name]?.type &&
          (formData?.[name]?.type === "car" ||
            formData?.[name]?.type == "personalProperty" ||
            formData?.[name]?.type === "otherAst") && (
            <Form.Item
              label={
                (formData?.[name]?.type === "car" ? "car " : "") + "Description"
              }
              name={[name, "description"]}
              rules={[
                {
                  required: true,
                  message: "Please enter the Description.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          )}

        {formData?.[name]?.type === "business" && (
          <Form.Item
            label={"Business Name"}
            name={[name, "businessName"]}
            rules={[
              {
                required: true,
                message: "Please enter the Business Name.",
              },
            ]}
          >
            <Input />
          </Form.Item>
        )}

        {(formData?.[name]?.type === "personalProperty" ||
          formData?.[name]?.type === "otherAst" ||
          formData?.[name]?.type === "car" ||
          formData?.[name]?.type === "business") && (
            <InputNumberField
              prefix="$"
              label="Estimated Value"
              name={[name, "estimatedValue"]}
            />
          )}

        {(formData?.[name]?.type === "2ndVacationProperty" ||
          formData?.[name]?.type === "primaryHome" ||
          formData?.[name]?.type === "investmentProperty") && (
            <>
              <Form.Item
                label="Property Nickname"
                name={[name, "nickName"]}
                rules={[
                  {
                    required: true,
                    message: "Please enter the Property Nickname.",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <InputNumberField
                prefix="$"
                label="Estimated Value"
                name={[name, "estimatedValue"]}
              />

              <InputNumberField
                label="Annual Association Fees/Dues"
                name={[name, "annualFees"]}
                prefix={"$"}
                formClass="mar-btm-8"
              />
              <div>
                <p className="meetingTextes">
                  Your monthly association dues are $
                  {((formData?.[name]?.annualFees || 0) / 12)?.toFixed(2)}
                </p>
              </div>

              <InputNumberField
                prefix={"$"}
                label="Annual Real Estate Taxes"
                name={[name, "annualTaxes"]}
                formClass="mar-btm-8"
              />
              <div>
                <p className="meetingTextes">
                  Your monthly real estate taxes are $
                  {((formData?.[name]?.annualTaxes || 0) / 12)?.toFixed(2)}
                </p>
              </div>
            </>
          )}

      </>
    );
  };

  const getDebtForm = (name) => {
    return (
      <>
        {formData?.[name]?.type === "medicalDebt" && (
          <Form.Item
            label="Healthcare Facility "
            name={[name, "healthcareFacility"]}
            rules={[
              {
                required: true,
                message: "Please enter the Healthcare Facility.",
              },
            ]}
          >
            <Input />
          </Form.Item>
        )}

        {formData?.[name]?.type === "debtInCollections" && (
          <Form.Item
            label="Collection Company "
            name={[name, "collectionCompany"]}
            rules={[
              {
                required: true,
                message: "Please enter the Collection Company .",
              },
            ]}
          >
            <Input />
          </Form.Item>
        )}

        {formData?.[name]?.type === "familyBorrowing" && (
          <Form.Item
            label="Family Member Name"
            name={[name, "familyMemberName"]}
            rules={[
              {
                required: true,
                message: "Please enter the Family Member Name.",
              },
            ]}
          >
            <Input />
          </Form.Item>
        )}

        {formData?.[name]?.type === "otherDebt" && (
          <Form.Item
            label="Debt Name"
            name={[name, "debtName"]}
            rules={[
              {
                required: true,
                message: "Please enter the current value.",
              },
            ]}
          >
            <Input />
          </Form.Item>
        )}

        {(formData?.[name]?.type === "creditCard" ||
          formData?.[name]?.type === "studentLoan" ||
          formData?.[name]?.type === "carLoan" ||
          formData?.[name]?.type === "personalLoan" ||
          formData?.[name]?.type === "mortgage" ||
          formData?.[name]?.type === "homeEquityLoan" ||
          formData?.[name]?.type === "2ndVacationPropertyMortgage" ||
          formData?.[name]?.type === "investmentPropertyMortgage") && (
            <>
              <Form.Item
                label="Bank Name"
                name={[name, "bankName"]}
                rules={[
                  {
                    required: true,
                    message: "Please enter the Bank Name.",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <InputNumberField
                prefix="$"
                label="Outstanding Balance"
                name={[name, "outstandingBalance"]}
              />
            </>
          )}
        {formData?.[name]?.type === "creditCard" && (
          <InputNumberField
            prefix="$"
            label="Minimum Monthly Payment"
            name={[name, "paymentMinAmount"]}
          />
        )}

        {(formData?.[name]?.type === "medicalDebt" ||
          formData?.[name]?.type === "debtInCollections" ||
          formData?.[name]?.type === "familyBorrowing" ||
          formData?.[name]?.type === "otherDebt") && (
            <InputNumberField
              prefix="$"
              label="Amount Owed "
              name={[name, "amountOwed"]}
            />
          )}

        {formData?.[name]?.type !== "creditCard" && formData?.[name]?.type && (
          <InputNumberField
            label="Monthly Payment"
            name={[name, "monthlyPayment"]}
            prefix="$"
          />
        )}
        {(formData?.[name]?.type === "mortgage" ||
          formData?.[name]?.type === "2ndVacationPropertyMortgage" ||
          formData?.[name]?.type === "investmentPropertyMortgage") && (
            <div className="sub-text-payment">
              <p className="meetingTextes">
                Be sure that your monthly payment doesn't include taxes,
                insurance, or association dues
              </p>
            </div>
          )}
        {formData?.[name]?.type && (
          <InputNumberField
            required={0}
            label="Current Annual Percentage Rate (APR)"
            name={[name, "interestRate"]}
            suffix="%"
          />
        )}
      </>
    );
  };

  return (
    <div className="personalmainheader">
      <div className="personaltextbackground">
        <p className="personaltext">Questionnaire</p>
      </div>
      <div className="line"></div>
      <div className="meetingline">
        <div>
          <h3 className="meetingText">{`Risk assessment`}</h3>
        </div>
      </div>
      <div className="from-section">
        <Form
          form={form}
          name="questionnaire"
          style={{ maxWidth: 650 }}
          onFinish={onFinish}
          layout="vertical"
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
            inline: "center",
          }}
          onFinishFailed={() => {
            const firstErrorField = document.querySelector(
              ".ant-form-item-has-error"
            );
            if (firstErrorField) {
              const offset = 150; // Set your desired offset
              window.scrollTo(0, firstErrorField.offsetTop - offset);
            }
          }}
          // onValuesChange={(values, val) => onChangeValue(values, val)}
          onValuesChange={debounce(onChangeValue, 1000)}
        >
          <SelectField
            name="szEarningInvestmentRate"
            label="Earning an investment rate of higher than inflation is important to me"
          />
          <SelectField
            name="szDeferCapitalGain"
            label="I want to defer any capital gains tax to future years"
          />
          <SelectField
            name="szNotNeededRecurringIncome"
            label="I don't need recurring income from my investments to maintain my current lifestyle"
          />
          <SelectField
            name="szWillingToInvestInRiskierSecurity"
            label="I am willing to invest in riskier securities in order generate higher returns"
          />
          <SelectField
            name="szWillingToRiskShortTermLoss"
            label="I am willing to risk short-term loss in return for a potentially higher long-term rate of return"
          />
          <SelectField
            name="szMoneyInvestNotNeedShortTerm"
            label="Any money I invest won't be needed/withdrawn in the short term"
          />
          <div className="line2"></div>

          <div className="debtdiv">
            <p className="debtText">Other Asset/Debt Accounts</p>
          </div>

          <Form.Item
            name={`hasNonConnectedAccounts`}
            label="Do you have any accounts that couldn’t be connected?"
            rules={[
              {
                required: true,
                message: "Please specify unconnected accounts.",
              },
            ]}
          >
            <Radio.Group /*className='radio'*/>
              <Radio value={1}>
                {" "}
                <span className="yestext">Yes</span>
              </Radio>
              <Radio value={0}>
                <span className="yestext">No</span>
              </Radio>
            </Radio.Group>
          </Form.Item>
          {isShowAccount && (
            <div className="finanicial-form-section">
              <Form.List name="additonalrecommendations">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ name, key, fieldKey }, index) => (
                      <>
                        <div style={{ position: "relative" }}>
                          <CloseOutlined
                            className="cross-sign cursor-pointer"
                            onClick={() => onRemoveData(remove, name)}
                          />
                        </div>
                        <div className="debtdiv">
                          <p
                            className="debtText"
                            style={{
                              color: "#45A8E5",
                              fontFamily: "IBMPlexSans-SemiBold",
                            }}
                          >
                            Other Account {index + 1}
                          </p>
                        </div>
                        <Form.Item
                          name={[name, `otherAccountType`]}
                          label="Is this an asset or debt account? "
                          rules={[
                            {
                              required: true,
                              message: "Please select the account type.",
                            },
                          ]}
                        >
                          <Radio.Group
                            style={{ marginBottom: 16 }}
                            onChange={(e) => {
                              const allAccounts = form.getFieldValue(
                                "additonalrecommendations"
                              );
                              let allOtherAccount = allAccounts;
                              allOtherAccount[name].type = "";
                              form.setFieldsValue({
                                additonalrecommendations: [...allOtherAccount],
                              });
                            }}
                          >
                            <Radio value={"asset"}>
                              {" "}
                              <span className="yestext">Asset</span>
                            </Radio>
                            <Radio value={"debt"}>
                              <span className="yestext">Debt</span>
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                        <SelectField
                          name={[name, `type`]}
                          label={capitalizeFirstLetter(formData?.[name]?.otherAccountType || "")+" Type"}
                          opts={
                            formData?.[name]?.otherAccountType === "asset"
                              ? assetOption
                              : formData?.[name]?.otherAccountType === "debt"
                                ? debtOption
                                : []
                          }
                        />

                        {formData?.[name]?.otherAccountType === "asset"
                          ? getAssetForm(name)
                          : getDebtForm(name)}


                      </>
                    ))}
                  </>
                )}
              </Form.List>
            </div>
          )}
          {isShowAccount && (
            <Form.Item>
              <Button
                type="button"
                icon={<PlusOutlined />}
                onClick={() => onAddRecommendation()}
                className="mt-2 another-btn"
              >
                Add another Account
              </Button>
            </Form.Item>
          )}
          <Button
            type="primary"
            className="primary-medium-btn"
            htmlType="submit"
          >
            Save
          </Button>
        </Form>

        <div className="ant-notification-notice-content">
          <div className="alert">
            Please answer these general finance questions so we can create the
            perfect MoneyPlan for you.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Questionnaire;
