import React, { useEffect, useState } from "react";
import { Button, Input, Form, InputNumber } from "antd";
import { postRequest } from "../../../services/apiService";
import { useParams } from "react-router-dom";
import { useApiMessages } from "../../../context/ApiMessageContext";
import { showLoading } from "react-global-loading";
import { CloseOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";
import {
  GET_RECOMMENDATION_API,
  SAVE_RECOMMENDATION_API,
} from "../../../services/apiConstant";
import {
  getOrdinalNumber,
  numberFormatBasic,
  getRecommendation,
} from "../../../utils/helper";

import "./index.scss";

import { debounce } from "lodash";

const Recommendations = ({ userData }) => {
  const [form] = Form.useForm();
  const params = useParams();
  const { TextArea } = Input;
  const { setErrorMessage, setSuccessMessage } = useApiMessages();
  const [deletedDataSource, setDeletedDataSource] = useState([]);

  const [data, setData] = useState({});
  const [fund, setFund] = useState(6);

  const szReference = params?.id;
  const [id, setId] = useState(0);
  const [loadingRec, setLoadingRec] = useState(false);

  useEffect(() => {
    showLoading(true);
    document.title = "Recommendations";
    window.scrollTo(0, 0);
    getRecommendations();
  }, []);


  const getRecommendations = () => {
    postRequest(GET_RECOMMENDATION_API, { szReference })
      .then((res) => {
        console.log(res.data);
        if (res.code === 200) {
          res.data.recommendations.szLivingExpRecommendation = res?.data
            ?.recommendations?.szLivingExpRecommendation
            ? res.data.recommendations.szLivingExpRecommendation
            : res?.data?.livingExpenseRecommendation;
          res.data.recommendations.szBillAccRecommendation = res?.data
            ?.recommendations?.szBillAccRecommendation
            ? res.data.recommendations.szBillAccRecommendation
            : res?.data?.billRecommendation;
          res.data.recommendations.szSpendAccRecommendation = res?.data
            ?.recommendations?.szSpendAccRecommendation
            ? res.data.recommendations.szSpendAccRecommendation
            : res?.data?.spendingRecommendation;
          res.data.recommendations.szHomeInsuranceRecommendation = res?.data
            ?.recommendations?.szHomeInsuranceRecommendation
            ? res.data.recommendations.szHomeInsuranceRecommendation
            : res?.data?.homeRecommendation;
          res.data.recommendations.rainyDayFundAmount = res?.data
            ?.recommendations?.rainyDayFundAmount
            ? res.data.recommendations.rainyDayFundAmount
            : res?.data?.rainyDayFundAmount;
          form.setFieldsValue({
            ...res?.data?.recommendations,
            additonalrecommendations: res?.data?.additonalrecommendations,
            billRecommendation: res?.data?.billRecommendation,
            spendingRecommendation: res?.data?.spendingRecommendation,
            healthRecommendation: res?.data?.healthRecommendation,
            _2ndVacationPropRecommendation:
              res?.data?._2ndVacationPropRecommendation,
          });
          setData({
            ...res?.data?.recommendations,
            additonalrecommendations: res?.data?.additonalrecommendations,
            billRecommendation: res?.data?.billRecommendation,
            spendingRecommendation: res?.data?.spendingRecommendation,
            healthRecommendation: res?.data?.healthRecommendation,
            disablityRecommendation: res?.data?.disablityRecommendation,
            liabilityRecommendation: res?.data?.liabilityRecommendation,
            rentersRecommendation: res?.data?.rentersRecommendation,
            otherRecommendation: res?.data?.otherRecommendation,
            lifeInsuranceRecommendation: res?.data?.lifeInsuranceRecommendation,
            _2ndVacationPropRecommendation:
              res?.data?._2ndVacationPropRecommendation,
            carRecommendation: res?.data?.carRecommendation,
            rainyDayFundAmount: res?.data?.rainyDayFundAmount,
            monthlyEmergencyFundAmount:
              res?.data?.recommendations?.monthlyEmergencyFundAmount ||
              res?.data?.NDEMonthly,
            NDEMonthly: res?.data?.NDEMonthly,
            emergencyFundRecommendation: res?.data?.EFRecommendation,
            RFRec: res?.data?.importantValues?.textRDFRec?.trim(),
            insuranceRecommendation: res?.data?.insuranceRecommendation,
          });
          setId(res?.data?.recommendations?.id || 0);
          setFund(res?.data?.recommendations?.monthsEmergencyCoverage || fund);
          setLoadingRec(true);
        }
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  };

  const InputField = ({
    name,
    label,
    formClass,
    initialValue = "",
  }) => (
    <Form.Item
      name={name}
      label={label}
      validateFirst={true}
      className={formClass}
      style={{ marginTop: 33 }}
      initialValue={initialValue}
    >
      <TextArea rows={4} style={{ resize: "none" }} />
    </Form.Item>
  );


  const InputNumberField = ({
    name,
    label,
    formClass,
    prefix,
    initialValue,
    shouldUpdate = false,
    onBlur = () => {},
  }) => (
    <Form.Item
      shouldUpdate={shouldUpdate}
      name={name}
      label={label}
      validateFirst={true}
      className={formClass}
      style={{ marginTop: 20 }}
      initialValue={initialValue}
    >
      <InputNumber
        prefix={prefix}
        step={"0.00"}
        className="input-parse"
        controls={false}
        onBlur={onBlur}
      />
    </Form.Item>
  );

  const onRemoveData = (remove, index) => {
    let allData = data;
    if (allData.additonalrecommendations[index].id > 0) {
      let deleteData = deletedDataSource;
      deleteData.push({
        ...allData.additonalrecommendations[index],
        isDeleted: 1,
      });

      setDeletedDataSource(deleteData);
    }
    allData.additonalrecommendations?.splice(index, 1);
    setData(allData);
    form.setFieldsValue(allData);
  };

  const onAddRecommendation = async () => {
    try {
      let allData = data;

      if (data?.additonalrecommendations?.length > 0) {
        // Create a new recommendation object
        const newRecommendation = {
          id: "0",
          szDescription: "",
          isDeleted: "0",
        };

        // Add the new recommendation to the data
        allData.additonalrecommendations.push(newRecommendation);

        // Validate only the szDescription field for the newly added recommendation
        await form.validateFields([
          ["additonalrecommendations", newRecommendation.id, "szDescription"],
        ]);
      } else {
        allData.additonalrecommendations = [
          { id: "0", szDescription: "", isDeleted: "0" },
        ];
      }
      setData(allData);
      form.setFieldsValue(allData);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const handleIncrement = () => {
    setFund(fund + 1);
  };

  const handleDecrement = () => {
    if (fund > 1) {
      setFund(fund - 1);
    }
  };
  useEffect(() => {
    setData({
      ...data,
      emergencyFund:
        fund * (form.getFieldValue("monthlyEmergencyFundAmount") || 0),
    });
    calcRec(null, form.getFieldsValue());
  }, [fund]);

  useEffect(() => {
    console.log("loading rec");
    if (loadingRec) {
      console.log("loading activate rec");
      calcRec(null, form.getFieldsValue());
    }
    //
  }, [loadingRec]);

  const onFinish = async (values) => {
    showLoading(true);
    try {
      let payload = {
        szReference,
        id: id,
        ...values,
        monthsEmergencyCoverage: fund,
        emergencyFundRecommendation: data?.emergencyFundRecommendation,
        rainyDayFund: data?.rainyDayFundAmount || 0,
        rainyDayFundRecommendation: data?.RFRec,
        emergencyFund: data?.emergencyFund || 0,
      };

      if (payload?.additonalrecommendations && deletedDataSource?.length > 0) {
        payload.additonalrecommendations = [
          ...payload.additonalrecommendations,
          ...deletedDataSource,
        ];
      }
      const response = await postRequest(SAVE_RECOMMENDATION_API, payload);

      showLoading(false);
      if (response?.code === 200) {
        window.scrollTo(0, 0);
        setSuccessMessage(response?.data.msg);
        getRecommendations();
        showLoading(false);
        // navigate(-1);
      } else {
        showLoading(false);
        setErrorMessage("Something went wrong");
      }
      // Handle response
    } catch (error) {
      showLoading(false);
      setErrorMessage("Something went wrong");
      console.log("Form Validation Error:", error);
    }
  };
  const calcRec = async (val, values) => {
    console.log(val, values);
    let NDE = values?.monthlyEmergencyFundAmount || data?.NDEMonthly;
    let onlyNDE = data?.NDEMonthly;
    let rainyDayFundAmount = values?.rainyDayFundAmount || 0;
    let EF = NDE * fund;
    let CAst = userData?.importantValues?.CAst || 0;
    let EFMons = userData?.importantValues?.EFMons || 0;
    let AmtNeededToFullyFundEF =
      userData?.importantValues?.AmtNeededToFullyFundEF || 0;
    let RDFMons = userData?.importantValues?.RDFMons || 0;
    let EFSav = userData?.importantValues?.EFSav || 0;
    let budRag = userData?.importantValues?.budRAG || 0;
    let recommendation = await getRecommendation(
      NDE,
      rainyDayFundAmount,
      EF,
      CAst,
      EFMons,
      RDFMons,
      AmtNeededToFullyFundEF,
      EFSav,
      budRag,
      onlyNDE
    );
    console.log({ recommendation });
    setData({
      ...data,
      emergencyFundRecommendation: recommendation?.EFRecommendation,
      rainyDayFundAmount: rainyDayFundAmount,
      monthlyEmergencyFundAmount: NDE,
      monthsEmergencyCoverage: fund,
      emergencyFund: NDE * fund,
      RFRec: recommendation?.RFRecommendation?.trim(),
    });
  };

  return (
    <div className="personalmainheader">
      <div className="personaltextbackground">
        <p className="personaltext">Recommendations</p>
      </div>
      <div className="line"></div>
      <Form
        form={form}
        name="recommendation"
        style={{ maxWidth: 650, position: "relative" }}
        onFinish={onFinish}
        layout="vertical"
        formClass={"mr-btm8"}
        onValuesChange={debounce(calcRec, 1000)}
        scrollToFirstError={{
          behavior: "smooth",
          block: "center",
          inline: "center",
        }}
        className="mr-btn8"
      >
        <InputField
          label="Bill account recommendation"
          name="szBillAccRecommendation"
          className="mr-btm8"
          formClass={"mr-btm8"}
        />
        <div className="current-text recom-current-text">
          <strong>Current recommendation:</strong> {data?.billRecommendation}
        </div>
        <InputField
          label="Spend account recommendation"
          formClass="recommendation-account"
          name="szSpendAccRecommendation"
        />
        <div className="current-text recom-current-text">
          <strong>Current recommendation:</strong>{" "}
          {data?.spendingRecommendation}
        </div>
        <InputField
          label="Living expense recommendation"
          name="szLivingExpRecommendation"
          formClass="recommendation-expense"
        />
        <div className="current-text recom-current-text">
          <strong>Current recommendation:</strong>{" "}
          {data?.szLivingExpRecommendation}
        </div>
        {data?.lifeInsuranceRecommendation && (
          <>
            <InputField
              label="Life insurance recommendation"
              name="szLifeInsuranceRecommendation"
              formClass={"mr-btm8 recommendation-insurance"}
              initialValue={data?.lifeInsuranceRecommendation}
            />
            <div className="current-text recom-current-text">
              <strong>Current recommendation:</strong>{" "}
              {data?.lifeInsuranceRecommendation}
            </div>
          </>
        )}
        {data?.healthRecommendation && (
          <>
            <InputField
              label="Health insurance recommendation"
              name="szHomeInsuranceRecommendation"
              formClass={"mr-btm8 recommendation-insurance"}
              initialValue={data?.healthRecommendation || ""}
            />
            <div className="current-text recom-current-text">
              <strong>Current recommendation:</strong>{" "}
              {data?.healthRecommendation}
            </div>
          </>
        )}
        {data?.carRecommendation && (
          <>
            <InputField
              label="Car insurance recommendation"
              name="szCarInsuranceRecommendation"
              formClass={"mr-btm8 recommendation-insurance"}
              initialValue={data?.carRecommendation || ""}
            />
            <div className="current-text recom-current-text">
              <strong>Current recommendation:</strong> {data?.carRecommendation}
            </div>
          </>
        )}

        {data?.rentersRecommendation && (
          <>
            <InputField
              label={`${
                userData?.rent_or_own === "own" ? "Home" : "Renters"
              } insurance recommendation`}
              name="szRentersInsuranceRecommendation"
              formClass={"mr-btm8 recommendation-insurance"}
              initialValue={data?.rentersRecommendation}
            />
            <div className="current-text recom-current-text">
              <strong>Current recommendation:</strong>{" "}
              {data?.rentersRecommendation}
            </div>
          </>
        )}
       
        {data?.liabilityRecommendation && (
          <>
            <InputField
              label="Liability insurance recommendation"
              name="szLiabilityInsuranceRecommendation"
              formClass={"mr-btm8 recommendation-insurance"}
              initialValue={data?.liabilityRecommendation}
            />
            <div className="current-text recom-current-text">
              <strong>Current recommendation:</strong>{" "}
              {data?.liabilityRecommendation}
            </div>
          </>
        )}
        {data?._2ndVacationPropRecommendation && (
          <>
            <InputField
              label="2nd/vacation/investment property insurance recommendation"
              name="sz2ndVacationPropInsuranceRecommendation"
              formClass={"mr-btm8 recommendation-insurance"}
              initialValue={data?._2ndVacationPropRecommendation}
            />
            <div className="current-text recom-current-text">
              <strong>Current recommendation:</strong>{" "}
              {data?._2ndVacationPropRecommendation}
            </div>
          </>
        )}
      
        <Form.List name="additonalrecommendations">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ name }, index) => (
                <>
                  <div
                    style={{ position: "relative", zIndex: "1" }}
                    className=" cursor-pointer cross-icon-section"
                    onClick={() => onRemoveData(remove, name)}
                  >
                    <CloseOutlined className="cross-sign" />
                  </div>

                  <Form.Item
                    name={[name, "szDescription"]}
                    label={`${getOrdinalNumber(
                      index
                    )} Additional Recommendation`}
                    style={{ marginTop: 20, marginBottom: 8 }}
                    formClass={"mr-btm8"}
                    className=""
                    rules={[
                      {
                        required: true,
                        message: `Please enter the recommendation.`,
                      },
                    ]}
                  >
                    <TextArea
                      style={{ resize: "none" }}
                      autoSize={{ minRows: 4, maxRows: 4 }}
                    />
                  </Form.Item>
                </>
              ))}
            </>
          )}
        </Form.List>
        <Form.Item className="mar-top-50">
          <Button
            type="button"
            onClick={() => onAddRecommendation()}
            className="mt-2 add-btn"
          >
            <u>Add another recommendation</u>
          </Button>
        </Form.Item>
        <div className="line2" style={{ marginTop: 62 }}></div>
        <div className="meetingline">
          <div>
            <h3 className="meetingText1">{`Rainy day fund`}</h3>
          </div>
        </div>
        <InputNumberField
          prefix={"$"}
          className="input-parse"
          label="Rainy day fund amount"
          name="rainyDayFundAmount"
          onBlur={(e) => {
            console.log(e);
            setData({ ...data, rainyDayFundAmount: e.target.value });
          }}
          initialValue={data?.rainyDayFundAmount}
        />
        <p className="meetingText2 rainy-text">Rainy day fund recommendation</p>
        {data?.RFRec && (
          <ul>
            {data?.RFRec?.trim()
              ?.split("  ")
              .map((txt) => {
                return txt?.trim() ? (
                  <li className="meetingText3">{txt}</li>
                ) : (
                  ""
                );
              })}
          </ul>
        )}
        <div className="line2"></div>
        <div>
          <p className="meetingText4">{`Emergency fund`}</p>
        </div>
        <InputNumberField
          className="meetingText2"
          formClass="emergency-input mr-btm8"
          label="Monthly emergency fund coverage amounts"
          prefix={"$"}
          name="monthlyEmergencyFundAmount"
          initialValue={data?.monthlyEmergencyFundAmount || data.NDE}
        />
        <Input type="hidden" name="EFMons" value={fund} />
        <div>
          <p className="meetingTextes">
            {" "}
            Based on non-discretionary expenses. Current amount:{" "}
            {numberFormatBasic(
              data?.NDEMonthly ? data?.NDEMonthly : data?.NDEMonthly
            )}
          </p>
        </div>
        <div className="numbertext">
          <p className="meetingText2">Months of emergency fund coverage</p>
        </div>
        <div className="tongle-btn">
          <Button
            onClick={handleDecrement}
            className="d-flex align-items-center"
            icon={<MinusOutlined />}
          />
          <p className="meetingText3">{fund}</p>
          <Button
            onClick={handleIncrement}
            className="d-flex align-items-center"
            icon={<PlusOutlined style={{ fontSize: 12 }} />}
          />
        </div>
        <div className="amountDiv">
          <p className="meetingTextes">
            Emergency fund amount: {numberFormatBasic(data?.emergencyFund || 0)}
          </p>
        </div>
        <div className="numbertext">
          <p className="meetingText2"> Emergency fund recommendation</p>
        </div>
        {data?.emergencyFundRecommendation && (
          <ul>
            {data?.emergencyFundRecommendation
              ?.trim()
              ?.split("  ")
              .map((txt) => {
                return <li className="meetingText3">{txt}</li>;
              })}
          </ul>
        )}
        <div className="line2" style={{ marginTop: 61 }}></div>
        <Button type="primary" className="primary-medium-btn" htmlType="submit">
          Save
        </Button>
      </Form>
    </div>
  );
};

export default Recommendations;
