import { assetAccounts, inflationRate, liabilityAccounts } from "../Constant";

export const maskedEmail = (email) => {
  let maskid = email?.replace(
    /^(.)(.*)(.@.*)$/,
    (_, a, b, c) => a + "****" + c
  );
  return maskid;
};
export const validateEmail = (value) => {
  return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);
};

export const onlyNumber = (value) => {
  return /^[0-9]+$/.test(value);
};

export const OnlyCharAndSpace = (value) => {
  return /^[A-Za-z\\s]g/.test(value);
};

export const getOrdinalNumber = (index) => {
  const n = index + 1;
  const suffixes = ["st", "nd", "rd"];
  const suffix =
    n % 10 <= 3 && (n % 100) - (n % 10) !== 10 ? suffixes[(n % 10) - 1] : "th";
  return n + suffix;
};

export const getInflationRateInsurance = () => {
  let inflation = inflationRate["generalInflation"] * 100;
  return parseFloat(inflation).toFixed(2);
};

export const getRateOfReturnInsurance = (RiskTolorenceScore = 0) => {
  let inflation = getReturnRate(RiskTolorenceScore);
  return parseFloat(inflation).toFixed(2);
};

export const getInflationRate = (goal = "") => {
  let inflation = inflationRate["generalInflation"];
  switch (goal) {
    case "carpurchase":
      inflation = inflationRate["carInflationRate"];
      break;
    case "dreamhome-apartment":
      inflation = inflationRate["housingFlationRate"];
      break;
    case "currentSavingRate":
      inflation = inflationRate["currentSavingRate"];
      break;
    case "homerenovation":
      inflation = inflationRate["housingFlationRate"];
      break;
    case "2nd-investment-vacation-property":
      inflation = inflationRate["housingFlationRate"];
      break;
    case "medical-expenses":
      inflation = inflationRate["medicalInflationRate"];
      break;
    default:
      inflation = inflationRate["generalInflation"];
      break;
  }

  return parseFloat(
    (inflation || inflationRate["generalInflation"]) * 100
  ).toFixed(4);
};
export const getReturnRate = (riskTolorenceScore) => {
  let rateOfReturn = 0;
  if (riskTolorenceScore >= 25 && riskTolorenceScore <= 30) {
    rateOfReturn = 0.0748;
  } else if (riskTolorenceScore >= 19 && riskTolorenceScore <= 24) {
    rateOfReturn = 0.0651;
  } else if (riskTolorenceScore >= 13 && riskTolorenceScore <= 18) {
    rateOfReturn = 0.0604;
  } else if (riskTolorenceScore === 12) {
    rateOfReturn = 0.0579;
  } else if (riskTolorenceScore >= 25 && riskTolorenceScore <= 30) {
    rateOfReturn = 0.0513;
  } else {
    rateOfReturn = 0.044;
  }
  return parseFloat(rateOfReturn * 100)?.toFixed(4);
};

export const numberFormat = (number) => {
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  if (number > 0 || number < 0) {
    if (number > 0) {
      return (number.includes("+") ? "+" : "") + USDollar.format(number) + "";
    } else {
      return "(" + USDollar.format(number) + ")";
    }
  } else {
    return USDollar.format(isNaN(number) ? "0.00" : number.replace("+", ""));
  }
};
export const numberFormatBasic = (number) => {
  if (number > 0 || number < 0 || number == 0) {
    let USDollar = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    return USDollar.format(number);
  } else {
    return number;
  }
};

export const FullMonth = (months) => {
  return Math.trunc(months || 0);
};
export const removeFormat = (string) => {
  return string
    ? string
        .toString()
        ?.replace("(", "")
        ?.replace("$", "")
        ?.replace(")", "")
        ?.replace("-", "")
        ?.replace(",", "")
    : 0;
};
export const removeFormatCurrency = (string) => {
  return string
    ? string
        .toString()
        ?.replace("(", "")
        ?.replace("$", "")
        ?.replace(")", "")
        // ?.replace("-", "")
        ?.replace(",", "")
    : 0;
};

export const checkSpendingCat = (category) => {};

export const getIsEFMonthsIntegerIdent = async (EFMons) => {
  let text = "";
  if (EFMons - Math.trunc(EFMons) === 0 && Math.trunc(EFMons) === 1) {
    text = "NoDec1";
  } else if (EFMons - Math.trunc(EFMons) === 0 && Math.trunc(EFMons) > 1) {
    text = "NoDecG1";
  } else if (EFMons - Math.trunc(EFMons) > 0 && Math.trunc(EFMons) === 0) {
    text = "Dec0";
  } else if (EFMons - Math.trunc(EFMons) > 0 && Math.trunc(EFMons) === 1) {
    text = "Dec1";
  } else if (EFMons - Math.trunc(EFMons) > 0 && Math.trunc(EFMons) > 1) {
    text = "DecG1";
  }
  return text;
};

export const getEFRecSignal = async (
  EFSav = 0,
  EFSuff = 0,
  EFBase = 0,
  EFPartialPmtRemainder = 0,
  IsEFMonthsIntegerIdent = ""
) => {
  console.log({
    EFSav,
    EFSuff,
    EFBase,
    EFPartialPmtRemainder,
    IsEFMonthsIntegerIdent,
  });
  let text = "";
  if (EFSav <= 0 && EFSuff === 0) {
    text = "Z";
  } else if (
    EFBase === 0 &&
    EFSuff === 0 &&
    IsEFMonthsIntegerIdent === "NoDec1" &&
    EFPartialPmtRemainder === 0
  ) {
    text = "AB";
  } else if (
    EFBase === 0 &&
    EFSuff === 0 &&
    IsEFMonthsIntegerIdent === "Dec0" &&
    EFPartialPmtRemainder === 0
  ) {
    text = "BC";
  } else if (
    EFBase === 0 &&
    EFSuff === 0 &&
    IsEFMonthsIntegerIdent === "NoDecG1" &&
    EFPartialPmtRemainder === 0
  ) {
    text = "CD";
  } else if (
    EFBase === 0 &&
    EFSuff === 0 &&
    IsEFMonthsIntegerIdent === "DecG1" &&
    EFPartialPmtRemainder === 0
  ) {
    text = "DE";
  } else if (
    EFBase === 0 &&
    EFSuff === 0 &&
    IsEFMonthsIntegerIdent === "Dec1" &&
    EFPartialPmtRemainder === 0
  ) {
    text = "EF";
  } else if (
    EFBase > 0 &&
    EFSuff === 0 &&
    IsEFMonthsIntegerIdent === "NoDec1" &&
    EFPartialPmtRemainder === 0
  ) {
    text = "FG";
  } else if (
    EFBase > 0 &&
    EFSuff === 0 &&
    IsEFMonthsIntegerIdent === "Dec0" &&
    EFPartialPmtRemainder === 0
  ) {
    text = "GH";
  } else if (
    EFBase > 0 &&
    EFSuff === 0 &&
    IsEFMonthsIntegerIdent === "NoDecG1" &&
    EFPartialPmtRemainder === 0
  ) {
    text = "HI";
  } else if (
    EFBase > 0 &&
    EFSuff === 0 &&
    IsEFMonthsIntegerIdent === "Dec1" &&
    EFPartialPmtRemainder === 0
  ) {
    text = "IJ";
  } else if (
    EFBase > 0 &&
    EFSuff === 0 &&
    IsEFMonthsIntegerIdent === "DecG1" &&
    EFPartialPmtRemainder === 0
  ) {
    text = "JK";
  } else if (
    EFBase === 0 &&
    EFSuff === 0 &&
    IsEFMonthsIntegerIdent === "NoDec1" &&
    EFPartialPmtRemainder > 0
  ) {
    text = "AB1";
  } else if (
    EFBase === 0 &&
    EFSuff === 0 &&
    IsEFMonthsIntegerIdent === "Dec0" &&
    EFPartialPmtRemainder > 0
  ) {
    text = "BC1";
  } else if (
    EFBase === 0 &&
    EFSuff === 0 &&
    IsEFMonthsIntegerIdent === "NoDecG1" &&
    EFPartialPmtRemainder > 0
  ) {
    text = "CD1";
  } else if (
    EFBase === 0 &&
    EFSuff === 0 &&
    IsEFMonthsIntegerIdent === "DecG1" &&
    EFPartialPmtRemainder > 0
  ) {
    text = "DE1";
  } else if (
    EFBase === 0 &&
    EFSuff === 0 &&
    IsEFMonthsIntegerIdent === "Dec1" &&
    EFPartialPmtRemainder > 0
  ) {
    text = "EF1";
  } else if (
    EFBase > 0 &&
    EFSuff === 0 &&
    IsEFMonthsIntegerIdent === "NoDec1" &&
    EFPartialPmtRemainder > 0
  ) {
    text = "FG1";
  } else if (
    EFBase > 0 &&
    EFSuff === 0 &&
    IsEFMonthsIntegerIdent === "Dec0" &&
    EFPartialPmtRemainder > 0
  ) {
    text = "GH1";
  } else if (
    EFBase > 0 &&
    EFSuff === 0 &&
    IsEFMonthsIntegerIdent === "NoDecG1" &&
    EFPartialPmtRemainder > 0
  ) {
    text = "HI1";
  } else if (
    EFBase > 0 &&
    EFSuff === 0 &&
    IsEFMonthsIntegerIdent === "Dec1" &&
    EFPartialPmtRemainder > 0
  ) {
    text = "IJ1";
  } else if (
    EFBase > 0 &&
    EFSuff === 0 &&
    IsEFMonthsIntegerIdent === "DecG1" &&
    EFPartialPmtRemainder > 0
  ) {
    text = "JK1";
  } else if (EFSuff === 1) {
    text = "KL";
  }
  return text;
};

export const getRDFRecSignal = async (
  RDFSuff = 0,
  RDFBase = 0,
  IsRDFMonthsIntegerIdent = ""
) => {
  console.log({ RDFSuff, RDFBase, IsRDFMonthsIntegerIdent });
  let text = "";
  if (RDFBase === 0 && RDFSuff === 0 && IsRDFMonthsIntegerIdent === "NoDec1") {
    text = "AB";
  } else if (
    RDFBase === 0 &&
    RDFSuff === 0 &&
    IsRDFMonthsIntegerIdent === "Dec0"
  ) {
    text = "BC";
  } else if (
    RDFBase === 0 &&
    RDFSuff === 0 &&
    IsRDFMonthsIntegerIdent === "NoDecG1"
  ) {
    text = "CD";
  } else if (
    RDFBase === 0 &&
    RDFSuff === 0 &&
    IsRDFMonthsIntegerIdent === "DecG1"
  ) {
    text = "DE";
  } else if (
    RDFBase === 0 &&
    RDFSuff === 0 &&
    IsRDFMonthsIntegerIdent === "Dec1"
  ) {
    text = "EF";
  } else if (
    RDFBase > 0 &&
    RDFSuff === 0 &&
    IsRDFMonthsIntegerIdent === "NoDec1"
  ) {
    text = "FG";
  } else if (
    RDFBase > 0 &&
    RDFSuff === 0 &&
    IsRDFMonthsIntegerIdent === "Dec0"
  ) {
    text = "GH";
  } else if (
    RDFBase > 0 &&
    RDFSuff === 0 &&
    IsRDFMonthsIntegerIdent === "NoDecG1"
  ) {
    text = "HI";
  } else if (
    RDFBase > 0 &&
    RDFSuff === 0 &&
    IsRDFMonthsIntegerIdent === "Dec1"
  ) {
    text = "IJ";
  } else if (
    RDFBase > 0 &&
    RDFSuff === 0 &&
    IsRDFMonthsIntegerIdent === "DecG1"
  ) {
    text = "JK";
  } else if (RDFBase > 0 && RDFSuff === 1) {
    text = "KL";
  } else {
    text = "KL";
  }
  return text;
};

export const getRecommendation = async (
  NDE,
  rainyDayFund,
  EF = 0,
  CAst,
  EFMons = 0,
  RDFMons = 0,
  AmtNeededToFullyFundEF = 0,
  EFSav = 0,
  budRAG,
  onlyNDE
) => {
  console.log(
    "🚀 ~ file: calculation.helper.js:284 ~ getEFRecommendation: ~ NDE:",
    NDE
  );

  // EFSav = 0.0;
  let EFSuff = 0.0;
  let EFBase = 0.0;
  let IsEFMonthsIntegerIdent = "";
  let EFPartialPmtRemainder = "";
  let AmtNeededToFullyFundRDF = 0;
  let EFBaseSuff = "";
  let NoEFBase1stLineLanguage = "";
  let NoEFBase2ndLineLanguage = "";
  let EFBase1stLineLanguage = "";
  let EFBase2ndLineLanguage = "";
  let EFBase3rdLineLanguage = "";
  let EFPartialPmtRemainderText = "";
  let EFPartialPmtpt2 = EFMons * EFSav;
  let RDFBase = 0;
  let RDFSav = 0;
  let RDFSuff = 0;
  let RDFPartialPmt = 0;
  let RDFPartialPmtpt2 = 0;
  let RDFRecSignal = "";
  let IsRDFMonthsIntegerIdent = "";
  // let RDFPartialPmtRemainder = "";
  let RDFBaseSuff = "";
  let NoRDFBase1stLineLanguage = "";
  let NoRDFBase2ndLineLanguage = "";
  let RDFBase1stLineLanguage = "";
  let RDFBase2ndLineLanguage = "";
  let RDFBase3rdLineLanguage = "";
  // let RDFMons = rainyDayFund;
  let RainyDayFund2 = rainyDayFund;
  console.log(
    "🚀 ~ file: calculation.helper.js:303 ~ getEFRecommendation: ~ EF:",
    EF
  );
  AmtNeededToFullyFundEF =
    CAst - rainyDayFund - onlyNDE * 0.5 > 0
      ? EF - (CAst - RainyDayFund2 - onlyNDE * 0.5)
      : EF;
  console.log({ AmtNeededToFullyFundEF });
  EFSuff = CAst - EF - onlyNDE * 0.5 - rainyDayFund >= 0 ? 1 : 0;
  AmtNeededToFullyFundRDF =
    CAst - onlyNDE * 0.5 - RainyDayFund2 > 0 ? 0 : RainyDayFund2;
  AmtNeededToFullyFundRDF = Number(Number(AmtNeededToFullyFundRDF).toFixed(2));
  if (EFSuff === 1) {
    EFSav = 0;
  } else if (EFSuff === 0 && AmtNeededToFullyFundEF > budRAG) {
    EFSav = budRAG;
  } else if (EFSuff === 0 && AmtNeededToFullyFundEF < budRAG) {
    EFSav = AmtNeededToFullyFundEF;
  }
  EFSav = Number(Number(EFSav).toFixed(2));

  if (Number(Number(CAst - onlyNDE * 0.5).toFixed(2)) - rainyDayFund >= 0) {
    RDFSuff = 1;
  }
  if (RDFSuff === 0 && CAst - onlyNDE * 0.5 > 0) {
    RDFBase = Number(Number(CAst - onlyNDE * 0.5).toFixed(2));
  }
  if (RDFSuff === 0 && AmtNeededToFullyFundRDF >= budRAG) {
    RDFSav = budRAG;
  } else if (RDFSuff === 0 && AmtNeededToFullyFundRDF < budRAG) {
    RDFSav = AmtNeededToFullyFundRDF;
  } else {
    RDFSav = 0;
  }
  RDFSav = Number(Number(RDFSav).toFixed(2));
  if (RDFSuff === 1) {
    RDFMons = 0;
  } else if (RDFSav <= 0 && RDFSuff === 0) {
    RDFMons = 0;
  } else if (
    RDFSuff === 0 &&
    (rainyDayFund - RDFBase) / RDFSav -
      Math.trunc((rainyDayFund - RDFBase) / RDFSav) ===
      0
  ) {
    RDFMons = (rainyDayFund - RDFBase) / RDFSav;
  } else if (RDFSuff === 0 && RDFBase > 0) {
    RDFMons = (rainyDayFund - RDFBase) / RDFSav;
  } else if (RDFSuff === 0 && RDFBase === 0) {
    RDFMons = rainyDayFund / RDFSav;
  } else {
    RDFMons = 0;
  }
  console.log({ RDFMons, RDFSav, RDFSuff, RDFBase });
  // RDFMons = Number(Number(RDFMons).toFixed(3));
  EFSav = Number(Number(EFSav).toFixed(2));
  if (EFSuff === 0 && CAst - rainyDayFund - onlyNDE * 0.5 > 0) {
    EFBase = Number(Number(CAst - rainyDayFund - onlyNDE * 0.5).toFixed(2));
  }
  EFBase = Number(Number(EFBase).toFixed(2));
  EFPartialPmtRemainder =
    RDFMons - Math.trunc(RDFMons) > 0
      ? Number(Number((1 - (RDFMons - Math.trunc(RDFMons))) * EFSav).toFixed(2))
      : 0;

  if (EFSuff === 1) {
    EFMons = 0;
  } else if (EFSav <= 0 && EFSuff === 0) {
    EFMons = 0;
  } else if (
    EFSuff === 0 &&
    (EF - EFBase) / EFSav - Math.trunc((rainyDayFund - EFBase) / EFSav) === 0
  ) {
    EFMons = (rainyDayFund - EFBase) / EFSav;
  } else if (EFSuff === 0 && EFBase > 0 && EFPartialPmtRemainder > 0) {
    EFMons = (EF - EFBase - EFPartialPmtRemainder) / EFSav;
  } else if (EFSuff === 0 && EFBase === 0 && EFPartialPmtRemainder > 0) {
    EFMons = (EF - EFPartialPmtRemainder) / EFSav;
  } else if (EFSuff === 0 && EFBase > 0 && EFPartialPmtRemainder === 0) {
    EFMons = (EF - EFBase) / EFSav;
  } else if (EFSuff === 0 && EFBase === 0 && EFPartialPmtRemainder === 0) {
    EFMons = EF / EFSav;
  } else {
    EFMons = 0;
  }
  // EFMons = Number(EFMons.toFixed(2));

  EFPartialPmtpt2 =
    EFMons >= 1 ? EFSav : Number(Number(EFMons * EFSav).toFixed(2));

  console.log({ RDFMons });

  // EFSav = EF;

  console.log({
    EFSuff,
    // cal: CAst - EF - rainyDayFund - NDE * 0.5,
    // CAst,
    // EF,
    // rainyDayFund,
    // NDE,
  });
  // EFSav = EF / EFMons;
  // RDFMons = EFMons;
  IsEFMonthsIntegerIdent = await getIsEFMonthsIntegerIdent(EFMons);
  EFPartialPmtRemainder =
    RDFMons - Math.trunc(RDFMons) > 0
      ? Number(Number((1 - (RDFMons - Math.trunc(RDFMons))) * EFSav).toFixed(2))
      : 0;
  console.log({ RDFMons, EFPartialPmtRemainder, EFSav });

  let EFRecSignal = await getEFRecSignal(
    EFSav,
    EFSuff,
    EFBase,
    EFPartialPmtRemainder,
    IsEFMonthsIntegerIdent
  );
  console.log({ EFRecSignal });

  // let EFPartialPmt = if EFMons - Math.Truncate(EFMons) > 0 then AmtNeededToFullyFundEF - EFPartialPmtRemainder - (Math.Round(EFSav,2) * Math.Truncate(EFMons)) else 0;

  let EFPartialPmt = 0;

  console.log({
    EFPartialPmtRemainder,
    EFPartialPmt,
    AmtNeededToFullyFundEF,
    EFSav,
  });

  if (EFSav <= 0 && EFSuff === 0) {
    EFMons = 0;
  } else if (EFSuff === 0 && EFBase > 0 && EFPartialPmtRemainder > 0) {
    EFMons = (EF - EFBase - EFPartialPmtRemainder) / EFSav;
  } else if (EFSuff === 0 && EFBase === 0 && EFPartialPmtRemainder > 0) {
    EFMons = (EF - EFPartialPmtRemainder) / EFSav;
  } else if (EFSuff === 0 && EFBase > 0 && EFPartialPmtRemainder === 0) {
    EFMons = (EF - EFBase) / EFSav;
  } else if (EFSuff === 0 && EFBase === 0 && EFPartialPmtRemainder === 0) {
    EFMons = EF / EFSav;
  } else {
    EFMons = 0;
  }
  // EFMons = Number(EFMons.toFixed(2));
  console.log({ EFMons });
  if (EFMons - Math.trunc(EFMons) > 0) {
    EFPartialPmt =
      AmtNeededToFullyFundEF -
      EFPartialPmtRemainder -
      Number(Number(EFSav.toFixed(2))) * Math.trunc(EFMons);
    // EFPartialPmt = (EFMons - Math.trunc(EFMons)) * EFSav;
  }
  EFPartialPmt = Number(Number(EFPartialPmt).toFixed(2));
  console.log({ EFPartialPmt, EFMons });

  if (EFSav <= 0 && EFSuff === 0) {
    EFBaseSuff =
      "You'll need to reduce your expenses so you can save for your Emergency Fund";
  } else if (EFSuff === 1) {
    EFBaseSuff =
      "Put " +
      numberFormatBasic(EF) +
      " into an online savings account to fully fund your Emergency Fund";
  }
  if (EFPartialPmtRemainder > 0) {
    EFPartialPmtRemainderText =
      "Save " +
      numberFormatBasic(EFPartialPmtRemainder) +
      ", which is the rest of the monthly amount available for saving after you’ve fully funded your Rainy Day Fund";
  }

  if (IsEFMonthsIntegerIdent === "Dec0" && EFBase === 0 && EFSuff === 0) {
    NoEFBase1stLineLanguage =
      "Save " +
      numberFormatBasic(EFPartialPmt) +
      " for 1 month to fund your Emergency Fund";
  } else if (
    IsEFMonthsIntegerIdent === "Dec1" &&
    EFBase === 0 &&
    EFSuff === 0
  ) {
    NoEFBase1stLineLanguage =
      "Save " + numberFormatBasic(EFSav) + " for 1 month";
  } else if (
    IsEFMonthsIntegerIdent === "DecG1" &&
    EFBase === 0 &&
    EFSuff === 0
  ) {
    NoEFBase1stLineLanguage =
      "Save " +
      numberFormatBasic(EFSav) +
      " for " +
      FullMonth(EFMons) +
      " months";
  } else if (
    IsEFMonthsIntegerIdent === "NoDec1" &&
    EFBase === 0 &&
    EFSuff === 0
  ) {
    NoEFBase1stLineLanguage =
      "Save " +
      numberFormatBasic(EFSav) +
      " for 1 month to fund your Emergency Fund";
  } else if (
    IsEFMonthsIntegerIdent === "NoDecG1" &&
    EFBase === 0 &&
    EFSuff === 0
  ) {
    NoEFBase1stLineLanguage =
      "Save " +
      numberFormatBasic(EFSav) +
      " for " +
      FullMonth(EFMons) +
      " months to fund your Emergency Fund";
  }

  if (
    (IsEFMonthsIntegerIdent === "Dec1" &&
      EFBase === 0 &&
      EFSuff === 0 &&
      EFPartialPmt > 0) ||
    (IsEFMonthsIntegerIdent === "DecG1" &&
      EFBase === 0 &&
      EFSuff === 0 &&
      EFPartialPmt > 0)
  ) {
    NoEFBase2ndLineLanguage =
      "Plus an additional savings of " +
      numberFormatBasic(EFPartialPmt) +
      " the next month";
  }
  console.log({ EFBase, EFSuff });
  if (EFBase > 0 && EFSuff === 0) {
    EFBase1stLineLanguage =
      "Put " +
      numberFormatBasic(EFBase) +
      " into an online savings account, and";
  }

  if (
    (IsEFMonthsIntegerIdent === "Dec1" && EFBase > 0 && EFSuff === 0) ||
    (IsEFMonthsIntegerIdent === "Dec0" && EFBase > 0 && EFSuff === 0) ||
    (IsEFMonthsIntegerIdent === "NoDec1" && EFBase > 0 && EFSuff === 0)
  ) {
    EFBase2ndLineLanguage =
      "Save " + numberFormatBasic(EFPartialPmtpt2) + " for 1 month";
  } else if (
    IsEFMonthsIntegerIdent === "NoDecG1" &&
    EFBase > 0 &&
    EFSuff === 0
  ) {
    EFBase2ndLineLanguage =
      "Save " +
      numberFormatBasic(EFSav) +
      " for " +
      FullMonth(EFMons) +
      " months";
  } else {
    EFBase2ndLineLanguage =
      "Save " +
      numberFormatBasic(EFSav) +
      " for " +
      FullMonth(EFMons) +
      " months";
  }

  if (EFBase > 0 && EFSuff === 0 && EFPartialPmt > 0) {
    EFBase3rdLineLanguage =
      "Plus an additional savings of " +
      numberFormatBasic(EFPartialPmt) +
      " the next month";
  }
  let text = "";
  if (EFRecSignal === "Z") {
    text = EFBaseSuff;
  } else if (EFRecSignal === "AB") {
    text = NoEFBase1stLineLanguage;
  } else if (EFRecSignal === "BC") {
    text = NoEFBase1stLineLanguage;
  } else if (EFRecSignal === "CD") {
    text = NoEFBase1stLineLanguage;
  } else if (EFRecSignal === "DE") {
    text =
      "Fund your Emergency Fund as follows:  " +
      NoEFBase1stLineLanguage +
      "  " +
      NoEFBase2ndLineLanguage;
  } else if (EFRecSignal === "EF") {
    text =
      "Fund your Emergency Fund as follows:  " +
      NoEFBase1stLineLanguage +
      "  " +
      NoEFBase2ndLineLanguage;
  } else if (EFRecSignal === "FG") {
    text =
      "Establish and Fund your Emergency Funds as follows:  " +
      EFBase1stLineLanguage +
      "  " +
      EFBase2ndLineLanguage;
  } else if (EFRecSignal === "GH") {
    text =
      "Establish and Fund your Emergency Funds as follows:  " +
      EFBase1stLineLanguage +
      "  " +
      EFBase2ndLineLanguage;
  } else if (EFRecSignal === "HI") {
    text =
      "Establish and Fund your Emergency Funds as follows:  " +
      EFBase1stLineLanguage +
      "  " +
      EFBase2ndLineLanguage;
  } else if (EFRecSignal === "IJ") {
    text =
      "Establish and Fund your Emergency Funds as follows:  " +
      EFBase1stLineLanguage +
      "  " +
      EFBase2ndLineLanguage +
      "  " +
      EFBase3rdLineLanguage;
  } else if (EFRecSignal === "JK") {
    text =
      "Establish and Fund your Emergency Funds as follows:  " +
      EFBase1stLineLanguage +
      "  " +
      EFBase2ndLineLanguage +
      "  " +
      EFBase3rdLineLanguage;
  } else if (EFRecSignal === "KL") {
    text = EFBaseSuff;
  } else if (EFRecSignal === "AB1") {
    text =
      "Fund your Emergency Fund as follows:  " +
      EFPartialPmtRemainderText +
      "  " +
      NoEFBase1stLineLanguage;
  } else if (EFRecSignal === "BC1") {
    text =
      "Fund your Emergency Fund as follows:  " +
      EFPartialPmtRemainderText +
      "  " +
      NoEFBase1stLineLanguage;
  } else if (EFRecSignal === "CD1") {
    text =
      "Fund your Emergency Fund as follows:  " +
      EFPartialPmtRemainderText +
      "  " +
      NoEFBase1stLineLanguage;
  } else if (EFRecSignal === "DE1") {
    text =
      "Fund your Emergency Fund as follows:  " +
      EFPartialPmtRemainderText +
      "  " +
      NoEFBase1stLineLanguage +
      "  " +
      NoEFBase2ndLineLanguage;
  } else if (EFRecSignal === "EF1") {
    text =
      "Fund your Emergency Fund as follows:  " +
      EFPartialPmtRemainderText +
      "  " +
      NoEFBase1stLineLanguage +
      "  " +
      NoEFBase2ndLineLanguage;
  } else if (EFRecSignal === "FG1") {
    text =
      "Establish and Fund your Emergency Funds as follows:  " +
      EFPartialPmtRemainderText +
      "  " +
      EFBase1stLineLanguage +
      "  " +
      EFBase2ndLineLanguage;
  } else if (EFRecSignal === "GH1") {
    text =
      "Establish and Fund your Emergency Funds as follows:  " +
      EFPartialPmtRemainderText +
      "  " +
      EFBase1stLineLanguage +
      "  " +
      EFBase2ndLineLanguage;
  } else if (EFRecSignal === "HI1") {
    text =
      "Establish and Fund your Emergency Funds as follows:  " +
      EFPartialPmtRemainderText +
      "  " +
      EFBase1stLineLanguage +
      "  " +
      EFBase2ndLineLanguage;
  } else if (EFRecSignal === "IJ1") {
    text =
      "Establish and Fund your Emergency Funds as follows:  " +
      EFPartialPmtRemainderText +
      "  " +
      EFBase1stLineLanguage +
      "  " +
      EFBase2ndLineLanguage;
  } else if (EFRecSignal === "JK1") {
    text =
      "Establish and Fund your Emergency Funds as follows:  " +
      EFPartialPmtRemainderText +
      "  " +
      EFBase1stLineLanguage +
      "  " +
      EFBase2ndLineLanguage +
      "  " +
      EFBase3rdLineLanguage;
  }

  RDFPartialPmt = (RDFMons - Math.trunc(RDFMons)) * RDFSav;
  RDFPartialPmtpt2 = RDFMons >= 1 ? RDFSav : RDFMons * RDFSav;

  IsRDFMonthsIntegerIdent = await getIsRDFMonthsIntegerIdent(RDFMons);
  console.log({ IsRDFMonthsIntegerIdent, RDFMons, RDFSuff, RDFBase });

  RDFRecSignal = await getRDFRecSignal(
    RDFSuff,
    RDFBase,
    IsRDFMonthsIntegerIdent
  );
  console.log({ RDFRecSignal, IsRDFMonthsIntegerIdent });
  if (RDFSuff === 1) {
    RDFBaseSuff =
      "Put " +
      numberFormatBasic(RainyDayFund2) +
      " into an online savings account to fully fund your Rainy Day Fund";
  }
  if (IsRDFMonthsIntegerIdent === "Dec0" && RDFBase === 0 && RDFSuff === 0) {
    NoRDFBase1stLineLanguage =
      "Save " +
      numberFormatBasic(RDFMons * RDFSav) +
      " for 1 month to fund your Rainy Day Fund";
  } else if (
    IsRDFMonthsIntegerIdent === "Dec1" &&
    RDFBase === 0 &&
    RDFSuff === 0
  ) {
    NoRDFBase1stLineLanguage =
      "Save " + numberFormatBasic(RDFSav) + " for 1 month";
  } else if (
    IsRDFMonthsIntegerIdent === "DecG1" &&
    RDFBase === 0 &&
    RDFSuff === 0
  ) {
    NoRDFBase1stLineLanguage =
      "Save " +
      numberFormatBasic(RDFSav) +
      " for " +
      FullMonth(Math.trunc(RDFMons)) +
      " months";
  } else if (
    IsRDFMonthsIntegerIdent === "NoDec1" &&
    RDFBase === 0 &&
    RDFSuff === 0
  ) {
    NoRDFBase1stLineLanguage =
      "Save " +
      numberFormatBasic(RDFSav) +
      " for 1 month to fund your Rainy Day Fund";
  } else if (
    IsRDFMonthsIntegerIdent === "NoDecG1" &&
    RDFBase === 0 &&
    RDFSuff === 0
  ) {
    NoRDFBase1stLineLanguage =
      "Save " +
      numberFormatBasic(RDFSav) +
      " for " +
      FullMonth(RDFMons) +
      " months to fund your Rainy Day Fund";
  }

  if (
    (IsRDFMonthsIntegerIdent === "Dec1" &&
      RDFBase === 0 &&
      RDFSuff === 0 &&
      RDFPartialPmt > 0) ||
    (IsRDFMonthsIntegerIdent === "DecG1" &&
      RDFBase === 0 &&
      RDFSuff === 0 &&
      RDFPartialPmt > 0)
  ) {
    NoRDFBase2ndLineLanguage =
      "Plus an additional savings of " +
      numberFormatBasic(RDFPartialPmt) +
      " the next month";
  }

  if (RDFBase > 0 && RDFSuff === 0) {
    RDFBase1stLineLanguage =
      "Put " +
      numberFormatBasic(RDFBase) +
      " into an online savings account, and";
  }
  if (
    (IsRDFMonthsIntegerIdent === "Dec1" && RDFBase > 0 && RDFSuff === 0) ||
    (IsRDFMonthsIntegerIdent === "Dec0" && RDFBase > 0 && RDFSuff === 0) ||
    (IsRDFMonthsIntegerIdent === "NoDec1" && RDFBase > 0 && RDFSuff === 0)
  ) {
    RDFBase2ndLineLanguage =
      "Save " + numberFormatBasic(RDFPartialPmtpt2) + " for 1 month";
  } else if (
    IsRDFMonthsIntegerIdent === "DecG1" &&
    RDFBase > 0 &&
    RDFSuff === 0
  ) {
    RDFBase2ndLineLanguage =
      "Save " +
      numberFormatBasic(RDFSav) +
      " for " +
      FullMonth(RDFMons) +
      " months";
  } else if (
    IsRDFMonthsIntegerIdent === "NoDecG1" &&
    RDFBase > 0 &&
    RDFSuff === 0
  ) {
    RDFBase2ndLineLanguage =
      "Save " +
      numberFormatBasic(RDFSav) +
      " for " +
      FullMonth(RDFMons) +
      " months";
  }
  if (
    (IsRDFMonthsIntegerIdent === "DecG1" &&
      RDFBase > 0 &&
      RDFSuff === 0 &&
      RDFPartialPmt > 0) ||
    (IsRDFMonthsIntegerIdent === "Dec1" && RDFBase > 0 && RDFSuff === 0)
  ) {
    RDFBase3rdLineLanguage =
      "Plus an additional savings of " +
      numberFormatBasic(RDFPartialPmt) +
      " the next month";
  } else {
    RDFBase3rdLineLanguage = "";
  }

  let textRDFRec = "";
  if (RDFRecSignal === "AB") {
    textRDFRec = NoRDFBase1stLineLanguage;
  } else if (RDFRecSignal === "BC") {
    textRDFRec = NoRDFBase1stLineLanguage;
  } else if (RDFRecSignal === "CD") {
    textRDFRec = NoRDFBase1stLineLanguage;
  } else if (RDFRecSignal === "DE") {
    textRDFRec =
      "Fund your Rainy Day Fund as follows:  " +
      NoRDFBase1stLineLanguage +
      "  " +
      NoRDFBase2ndLineLanguage;
  } else if (RDFRecSignal === "EF") {
    textRDFRec =
      "Fund your Rainy Day Fund as follows:  " +
      NoRDFBase1stLineLanguage +
      "  " +
      NoRDFBase2ndLineLanguage;
  } else if (RDFRecSignal === "FG") {
    textRDFRec =
      "Establish and Fund your Rainy Day Funds as follows:  " +
      RDFBase1stLineLanguage +
      "  " +
      RDFBase2ndLineLanguage;
  } else if (RDFRecSignal === "GH") {
    textRDFRec =
      "Establish and Fund your Rainy Day Funds as follows:  " +
      RDFBase1stLineLanguage +
      "  " +
      RDFBase2ndLineLanguage;
  } else if (RDFRecSignal === "HI") {
    textRDFRec =
      "Establish and Fund your Rainy Day Funds as follows:  " +
      RDFBase1stLineLanguage +
      "  " +
      RDFBase2ndLineLanguage;
  } else if (RDFRecSignal === "IJ") {
    textRDFRec =
      "Establish and Fund your Rainy Day Funds as follows:  " +
      RDFBase1stLineLanguage +
      "  " +
      RDFBase2ndLineLanguage +
      "  " +
      RDFBase3rdLineLanguage;
  } else if (RDFRecSignal === "JK") {
    textRDFRec =
      "Establish and Fund your Rainy Day Funds as follows:  " +
      RDFBase1stLineLanguage +
      "  " +
      RDFBase2ndLineLanguage +
      "  " +
      RDFBase3rdLineLanguage;
  } else if (RDFRecSignal === "KL") {
    textRDFRec = RDFBaseSuff;
  }
  return {
    EFRecommendation: text,
    RFRecommendation: textRDFRec,
  };
};

export const getIsRDFMonthsIntegerIdent = async (RDFMons) => {
  let text = "";
  if (RDFMons - Math.trunc(RDFMons) === 0 && Math.trunc(RDFMons) === 1) {
    text = "NoDec1";
  } else if (RDFMons - Math.trunc(RDFMons) === 0 && Math.trunc(RDFMons) > 1) {
    text = "NoDecG1";
  } else if (RDFMons - Math.trunc(RDFMons) > 0 && Math.trunc(RDFMons) === 0) {
    text = "Dec0";
  } else if (RDFMons - Math.trunc(RDFMons) > 0 && Math.trunc(RDFMons) === 1) {
    text = "Dec1";
  } else if (RDFMons - Math.trunc(RDFMons) > 0 && Math.trunc(RDFMons) > 1) {
    text = "DecG1";
  }
  return text;
};

export const getTotalAssets = (accounts) => {
  let totalAssets = 0;
  totalAssets = accounts
    .filter((acc) => assetAccounts.includes(acc.type))
    .reduce((prev, cur) => {
      return (
        prev +
        Number(
          cur?.estimatedValue > 0
            ? cur?.estimatedValue
            : cur.balance
            ? cur.balance
            : 0.0
        )
      );
    }, 0);
  return totalAssets || 0;
};
export const getTotalLiability = (accounts) => {
  let totalLiability = 0;
  totalLiability = accounts
    .filter((acc) => liabilityAccounts.includes(acc.type))
    .reduce((prev, cur) => {
      return prev + parseFloat(cur.balance || "0");
    }, 0);
  return Math.abs(totalLiability || 0);
};

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
