import { Table, Modal, Input, Select } from "antd";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { showLoading } from "react-global-loading";
import { CaretRightOutlined } from "@ant-design/icons";
import * as moment from "moment";
import { postRequest } from "../../../../services/apiService";
import {
  MONTHLY_SPENDING_API,
  TRANSACTION_API,
  UPDATE_TRANSACTION_API,
  UPDATE_BUDGET_NUMBER_API,
} from "../../../../services/apiConstant";
import {
  numberFormat,
  numberFormatBasic,
  removeFormat,
  removeFormatCurrency,
} from "../../../../utils/helper";

import "./index.scss";

function MonthlySpend(props) {
  const params = useParams();
  const szReference = params?.id;
  const [isTransactionModel, setTransactionModel] = useState(false);
  const [currentData, setCurrentRecord] = useState([]);
  const [editId, setEditId] = useState(null);
  const [editValue, setEditValue] = useState(null);
  const [editField, setEditField] = useState(null);
  const [dataSourceApi, setDataSourceApi] = useState(null);
  const [columns, setColumns] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [spendingCategories, setSpendingCategories] = useState([]);
  const [budgetNumberList, setBudgetNumberList] = useState([]);

  const [budgetEdit, setBudgetEdit] = useState(null);

  const compareFn = (a, b) => {
    console.log(a, "aaaa");
    a?.toString()
      .replace("($", "")
      .replace("-($", "")
      .replace(")", "")
      .replace(",", "");
    b?.toString()
      .replace("($", "")
      .replace("-($", "")
      .replace(")", "")
      .replace(",", "");
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    }
    // a must be equal to b
    return 0;
  };

  useEffect(() => {
    console.log(editField);
  }, [editField]);
  const getMonthlySpendData = async () => {
    showLoading(true);
    try {
      const response = await postRequest(MONTHLY_SPENDING_API, {
        szReference,
      });
      showLoading(false);

      if (response?.code === 200) {
        setDataSourceApi(response.data);
        setBudgetNumberList(response?.data?.budgetNumbers);
      }
    } catch (error) {
      showLoading(false);
      console.error(error);
    }
  };

  const budgetNumberSaveHandler = async (value, category) => {
    console.log(value, category);
    const budget = budgetNumberList.find((bud) => bud.szCategory === category);
    showLoading(true);
    try {
      const response = await postRequest(UPDATE_BUDGET_NUMBER_API, {
        szReference,
        szCategory: category,
        szBudgetNumber: value,
        id: budget?.id || 0,
      });
      showLoading(false);

      if (response?.code === 200) {
        setBudgetEdit(null);
        getMonthlySpendData();
      }
    } catch (error) {
      showLoading(false);
      console.error(error);
    }
  };

  const getEditableText = (record, _) => {
    return (
      <>
        {_ ? (
          <>
            {budgetEdit !== record?.category && (
              <span
                className={`${record.className} ${getClassNameWithSign(
                  _
                )} ${budgetEdit} ${record?.category}`}
                onClick={() => {
                  console.log("clicked", record?.category);
                  // setBudgetEdit(record?.onClickData?.keySub);
                  setEditHandler(record, _);
                }}
              >
                {record.className?.includes("spending-cat")
                  ? _ == 0
                    ? _
                    : numberFormat(
                        (record?.onClickData?.keyParent == "income" ||
                        Math.sign(removeFormatCurrency(_)) === 1
                          ? "+"
                          : _.includes("-")
                          ? "-"
                          : "") +
                          "-" +
                          removeFormat(_)
                      )
                  : numberFormat(
                      (record?.onClickData?.keyParent == "income" ||
                      Math.sign(removeFormatCurrency(_)) === 1
                        ? "+"
                        : _.includes("-")
                        ? "-"
                        : "") + removeFormat(_)
                    )}
              </span>
            )}
            {budgetEdit === record?.category && (
              <Input
                type="text"
                defaultValue={removeFormatCurrency(_)}
                id={"id" + budgetEdit}
                onBlur={(e) =>
                  budgetNumberSaveHandler(e.target.value, budgetEdit)
                }
                suffix={
                  <CaretRightOutlined
                    onClick={(e) => {
                      console.log(e);
                      budgetNumberSaveHandler(
                        document.getElementById("id" + budgetEdit).value,
                        budgetEdit
                      );
                    }}
                  />
                }
              />
            )}
          </>
        ) : (
          ""
        )}
      </>
    );
  };

  const getClassNameWithSign = (amount) => {
    let actual = removeFormatCurrency(amount);
    if (actual > 0) {
      return "plus";
    } else if (actual < 0) {
      return "minus";
    }
    return "";
  };

  useEffect(() => {
    let cols = [];

    if (dataSourceApi || budgetEdit) {
      setSpendingCategories(dataSourceApi?.spendingCatAry);
      cols = dataSourceApi?.monthsAry?.map((month, index) => {
        return {
          title: month,
          dataIndex: month,
          key: month,
          width: 137,
          align: "center",
          defaultSortOrder: "descend",
          showSorterTooltip: false,
          className:
            index === dataSourceApi?.monthsAry?.length - 1 ? "last-index" : "",
          sortIcon: (props) => {
            return props?.sortOrder
              ? props?.sortOrder === "descend"
                ? orderUp(1)
                : orderUp()
              : orderUp(1);
          },
          sorter: (a, b) => compareFn(a[month], b[month]),
          // sorter: (a, b) => {console.log("sorter", a[month], b[month])},
          render: (_, record) => getOpenPopup(_, record, month),
        };
      });
      setColumns([
        {
          title: "Category",
          dataIndex: "category",
          key: "category",
          fixed: "left",
          width: 273,
          align: "left",
          render: (_, record) => (_ ? _ : ""),
        },
        ...cols,
        {
          title: "Average",
          dataIndex: "average",
          key: "average",
          fixed: "right",
          align: "center",
          width: 130,
          render: (_, record) =>
            _ ? (
              <span
                className={`${record.className} ${getClassNameWithSign(_)}`}
              >
                {record.className?.includes("spending-cat")
                  ? _ == 0
                    ? _
                    : numberFormat(
                        (record?.onClickData?.keyParent == "income" ||
                        Math.sign(removeFormatCurrency(_)) === 1
                          ? "+"
                          : _.includes("-")
                          ? "-"
                          : "") +
                          "-" +
                          removeFormat(_)
                      )
                  : numberFormat(
                      (record?.onClickData?.keyParent == "income" ||
                      Math.sign(removeFormatCurrency(_)) === 1
                        ? "+"
                        : _.includes("-")
                        ? "-"
                        : "") + removeFormat(_)
                    )}
              </span>
            ) : (
              ""
            ),
        },
        {
          title: "Budget",
          dataIndex: "budget",
          key: "budget",
          fixed: "right",
          width: 140,
          align: "center",
          render: (_, record) => getEditableText(record, _),
        },
      ]);
      let dataSource = [];
      for (const [key, value] of Object.entries(dataSourceApi.maincategories)) {
        if (key !== "remaining-funds") {
          let dataObject = {
            key: Math.random(),
            category: value?.szName || "",
            className: "parent-category",
          };
          dataSource.push(dataObject);
          for (const [keySub, valueSub] of Object.entries(
            value.subcategories
          )) {
            let dataObjectChild = {
              key: Math.random(),
              category: valueSub["szName"],
              average: numberFormat(valueSub["monthAverage"] || 0.0),
              budget: numberFormat(valueSub["monthBudget"] || 0.0),
              onClickData: {
                keyParent: key,
                parentValue: value,
                keySub: valueSub.szName,
                value: valueSub,
              },
            };
            dataSourceApi?.monthsAry.map((month, index) => {
              dataObjectChild[month] = numberFormat(
                (key == "income" ||
                (valueSub[index]["monthAmount"] &&
                  Math.sign(valueSub[index]["monthAmount"]) === 1)
                  ? "+"
                  : "") +
                  Number.parseFloat(
                    valueSub[index]["monthAmount"] || "0.00"
                  ).toFixed(2)
              );
              dataObjectChild["className"] = `${
                index === dataSourceApi?.monthsAry.length - 1
                  ? "last-index"
                  : ""
              }`;
              // dataObjectChild['ClassName'] =
              return true;
            });
            dataSource.push(dataObjectChild);
          }
        }
      }
      setDataTable(dataSource);
      // Scroll table to right
      let table = document
        .getElementById("monthly-spend-table")
        .getElementsByClassName("ant-table-content")[0];
      if (table) {
        // Set the horizontal scroll to the maximum value (scroll to the right)
        table.scrollLeft = table.scrollWidth;
      }
    }
  }, [dataSourceApi, budgetEdit]);

  useEffect(() => {
    props.active === "2" && getMonthlySpendData();
  }, [props]);

  // const getFieldEdibaleTxn = (record, field, _ = '')  => {
  //     return <>
  //     {(editId !== record.id || editField !== field) && (<div className="text-block"><span className="text" >{ _? (field === 'interestRate'? _ + '%': field.includes('Date')? moment(_).format('DD/MM/YYYY'): _)  : '-'}</span>
  //     <span className="edit-icon" onClick={() => {setEditHandler(record, field)}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  //   <path d="M11.7068 0.292786C11.5192 0.105315 11.2649 0 10.9998 0C10.7346 0 10.4803 0.105315 10.2928 0.292786L1.29277 9.29279C1.15403 9.43136 1.05909 9.60768 1.01977 9.79979L0.0197683 14.7998C-0.0133427 14.9618 -0.00576954 15.1295 0.0418098 15.2878C0.0893892 15.4462 0.17549 15.5902 0.292405 15.7071C0.40932 15.8241 0.553401 15.9102 0.71175 15.9577C0.870099 16.0053 1.03777 16.0129 1.19977 15.9798L6.19977 14.9798C6.39331 14.9412 6.5711 14.8462 6.71077 14.7068L15.7108 5.70679C15.8982 5.51926 16.0036 5.26495 16.0036 4.99979C16.0036 4.73462 15.8982 4.48031 15.7108 4.29279L11.7068 0.292786ZM5.50677 13.0788L2.27377 13.7258L2.92077 10.4928L7.99977 5.41379L10.5858 7.99979L5.50677 13.0788ZM11.9998 6.58579L9.41377 3.99979L10.9998 2.41379L13.5858 4.99979L11.9998 6.58579Z" fill="#45A8E5"/>
  // </svg></span></div>)}
  //     {editId === record.id && editField === field && <span className="input-dev">
  //         {field.includes('Date') &&  <Input type="date"  defaultValue={_} onChange={(e) => setEditValue(e.target.value)} onBlur={() => savehandler(field)}/>}
  //         {!field.includes('Date') &&
  //         //  <Input type="text" defaultValue={_} onChange={(e) => setEditValue(e.target.value)} suffix={<CaretRightOutlined onClick={() => savehandler(field)}/>} />
  //          <Select
  //   showSearch
  //   defaultValue={_}
  //   placeholder={'Select Category'}
  //   style={{}}
  //   defaultActiveFirstOption={false}
  // //   suffixIcon={null}
  //   filterOption={false}
  // //   onSearch={handleSearch}
  //   onChange={(value) => setEditValue(value || '')}
  //   notFoundContent={null}
  //   options={(currentData?.txnCategory || []).map((d) => ({
  //     value: d.value,
  //     label: d.text,
  //   }))}
  //   onBlur={() => savehandler(field, record)}
  // />

  //         }
  //     </span> }
  //     </>;
  //   }

  const setEditHandler = (record, field) => {
    console.log("call editHandler", record, field);
    // setEditId(record.id);
    // setEditField(field);
    setBudgetEdit(record?.onClickData?.keySub);
  };



  const savehandlerCat = async (value, record) => {
    if (value && record) {
      showLoading(true);
      try {
        const response = await postRequest(UPDATE_TRANSACTION_API, {
          szReference,
          id: record.id,
          szCategory: value,
        });
        showLoading(false);

        if (response?.code === 200) {
          setEditId(null);
          setEditValue(null);
          setEditField(null);
          // setCurrentRecord(null);
          // setTransactionModel(false);

          // loadTransactionData(currentData, currentMonth);
          getMonthlySpendData();
          handleModalCancel();
        }
      } catch (error) {
        showLoading(false);
        console.error(error);
      }
    } else {
      setEditId(null);
      setEditValue(null);
      setEditField(null);
    }
  };

  const openTransactionDialog = async (record, month = "") => {
    console.log(month);
    console.log("record", record);

    showLoading(true);
    loadTransactionData(record, month).then((response) => {
      setTransactionModel(true);
    });
  };

  const loadTransactionData = async (record, month) => {
    console.log(record);
    let monthIndex = dataSourceApi?.monthsAry?.findIndex(
      (monthVal) => monthVal === month
    );
    console.log(monthIndex);
    try {
      if (monthIndex > -1 && record?.onClickData?.value[monthIndex]) {
        const response = await postRequest(TRANSACTION_API, {
          szReference,
          monthStartDate:
            record?.onClickData?.value[monthIndex]?.monthStartDate,
          monthEndDate: record?.onClickData?.value[monthIndex]?.monthEndDate,
          szSubCategory: record?.onClickData?.keySub,
        });
        showLoading(false);

        if (response?.code === 200) {
          console.log(response.data);

          let txnData = response.data?.transcationsData.map((transaction) => {
            return {
              id: transaction?.id,
              key: Math.random(),
              transactionDate: moment(transaction.transactionDate).format(
                "MMMM DD, YYYY"
              ),
              instituteName: transaction.instituteName,
              accountName: transaction.accountName,
              categorization_category: transaction.categorization_category,
              payeename: transaction.categorization_normalizedPayeeName || "",
              amount: transaction.amount,
              width: 150,
            };
          });
          let txnCategory = response.data?.transactionSubCategories.map(
            (cat) => {
              return {
                value: cat.name,
                label: cat.name,
              };
            }
          );
          // setTransactionData(txnData);

          setCurrentRecord({
            ...record,
            currentMonth: month,
            transactionData: txnData || [],
            txnCategory: txnCategory || [],
          });
          // setTransactionModel(true);
        }
      }
    } catch (error) {
      showLoading(false);
      console.error(error);
    }
  };

  const orderUp = (down = 0) => {
    if (down) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="7"
          viewBox="0 0 13 7"
          fill="none"
          style={{ transform: "rotate(180deg)" }}
        >
          <line
            x1="4"
            y1="6.5"
            x2="9"
            y2="6.5"
            stroke="#24516D"
            strokeOpacity="0.8"
          />
          <line
            x1="2"
            y1="3.5"
            x2="11"
            y2="3.5"
            stroke="#24516D"
            strokeOpacity="0.8"
          />
          <line
            y1="0.5"
            x2="13"
            y2="0.5"
            stroke="#24516D"
            strokeOpacity="0.8"
          />
        </svg>
      );
    } else {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="7"
          viewBox="0 0 13 7"
          fill="none"
        >
          <line
            x1="4"
            y1="6.5"
            x2="9"
            y2="6.5"
            stroke="#24516D"
            strokeOpacity="0.8"
          />
          <line
            x1="2"
            y1="3.5"
            x2="11"
            y2="3.5"
            stroke="#24516D"
            strokeOpacity="0.8"
          />
          <line
            y1="0.5"
            x2="13"
            y2="0.5"
            stroke="#24516D"
            strokeOpacity="0.8"
          />
        </svg>
      );
    }
  };

  const getOpenPopup = (_, record, month = "") => {
    let actualValue = removeFormatCurrency(_);
    // console.log({ record });
    return (
      <span
        style={{ cursor: "pointer" }}
        className={`${record.className} ${
          actualValue < 0 ? "minus" : actualValue > 0 ? "plus" : ""
        }`}
        onClick={() =>
          removeFormat(_) > 0 && openTransactionDialog(record, month)
        }
      >
        {_
          ? removeFormat(_) < 0
            ? _ === 0
              ? _
              : numberFormat("-" + removeFormat(_))
            : _
          : ""}
      </span>
    );
  };
  const columnTransactions = [
    {
      title: "Date",
      dataIndex: "transactionDate",
      key: "transactionDate",
      align: "center",
      defaultSortOrder: "descend",
      // width: 250,
      sorter: (a, b) => compareFn(a.transactionDate, b.transactionDate),
      sortIcon: (props) => {
        return props?.sortOrder
          ? props?.sortOrder === "descend"
            ? orderUp(1)
            : orderUp()
          : orderUp(1);
      },
      showSorterTooltip: false,
      render: (text) => (
        <span>{moment(text, "MMMM DD, YYYY").format("MM/DD/YYYY")}</span>
      ),
    },
    {
      title: "Institution",
      dataIndex: "instituteName",
      key: "instituteName",
      align: "center",
      defaultSortOrder: "descend",
      sorter: (a, b) => compareFn(a.instituteName, b.instituteName),
      sortIcon: (props) => {
        return props?.sortOrder
          ? props?.sortOrder === "descend"
            ? orderUp(1)
            : orderUp()
          : orderUp(1);
      },
      showSorterTooltip: false,
    },
    {
      title: "Account",
      dataIndex: "accountName",
      key: "accountName",
      align: "center",
      defaultSortOrder: "descend",
      sorter: (a, b) => compareFn(a.accountName, b.accountName),
      sortIcon: (props) => {
        return props?.sortOrder
          ? props?.sortOrder === "descend"
            ? orderUp(1)
            : orderUp()
          : orderUp(1);
      },
      showSorterTooltip: false,
    },
    {
      title: "Category",
      dataIndex: "categorization_category",
      key: "categorization_category",
      align: "center",
      defaultSortOrder: "descend",
      width: 200,
      className: "category-td",
      sorter: (a, b) =>
        compareFn(a.categorization_category, b.categorization_category),
      sortIcon: (props) => {
        return props?.sortOrder
          ? props?.sortOrder === "descend"
            ? orderUp(1)
            : orderUp()
          : orderUp(1);
      },
      render: (_, record) => (
        // getFieldEdibaleTxn(record, 'categorization_category', _)
        <Select
          showSearch
          defaultValue={_}
          placeholder={"Select Category"}
          // dropdownStyle={{background:'#fff',overflowY:'hidden',maxHeight:200}}
          // style={{background:'#fff',overflowY:'hidden',maxHeight:200}}
          popupClassName="custom-dropdown transaction-dropdown"
          className="part5"
          defaultActiveFirstOption={false}
          //   suffixIcon={null}
          filterOption={false}
          //   onSearch={handleSearch}
          onChange={(value) => savehandlerCat(value, record)}
          style={{ width: "100%" }}
          notFoundContent={null}
          virtual={false}
          options={(currentData?.txnCategory || []).map((d) => ({
            value: d.value,
            label: d.text,
          }))}
          //   onBlur={() => savehandler(field, record)}
        />
      ),
      showSorterTooltip: false,
    },
    {
      title: "Description",
      dataIndex: "payeename",
      key: "payeename",
      align: "center",
      defaultSortOrder: "descend",
      sorter: (a, b) => compareFn(a.payeename, b.payeename),
      sortIcon: (props) => {
        return props?.sortOrder
          ? props?.sortOrder === "descend"
            ? orderUp(1)
            : orderUp()
          : orderUp(1);
      },
      showSorterTooltip: false,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      defaultSortOrder: "descend",
      className: spendingCategories?.includes(
        currentData?.onClickData?.value?.szName
      )
        ? "spending-category"
        : "",
      sorter: (a, b) => compareFn(a.amount, b.amount),
      sortIcon: (props) => {
        return props?.sortOrder
          ? props?.sortOrder === "descend"
            ? orderUp(1)
            : orderUp()
          : orderUp(1);
      },
      showSorterTooltip: false,
      render: (_, record) =>
        _ ? (
          // spendingCategories?.includes(
          //   currentData?.onClickData?.value?.szName
          // ) ? (
          //   <span className={"spending-category"}>{numberFormatBasic(_)}</span>
          // ) : (
          //   numberFormatBasic(_)
          // )
          <span className={`${getClassNameWithSign(_)} `}>
            {" "}
            {numberFormatBasic(_)}
          </span>
        ) : (
          "0.00"
        ),
    },
  ];

  const handleModalCancel = () => {
    setTransactionModel(false);
  };
  const handleModalOk = () => {
    setTransactionModel(false);
  };
  const getAverageAmount = () => {
    let average = numberFormat(
      dataSourceApi?.maincategories["remaining-funds"]
        ? Object.keys(
            dataSourceApi?.maincategories["remaining-funds"]?.subcategories
          ).reduce((total, subCat) => {
            return (total = parseFloat(
              parseFloat(total) +
                parseFloat(
                  dataSourceApi?.maincategories["remaining-funds"]
                    ?.subcategories[subCat]?.monthAverage
                )
            ).toFixed(2));
          }, 0)
        : "0.00"
    );
    return average;
  };

  const getBudgetAmount = () => {
    let budget = numberFormat(
      dataSourceApi?.maincategories["remaining-funds"]
        ? Object.keys(
            dataSourceApi?.maincategories["remaining-funds"]?.subcategories
          )
            .reduce((total, subCat) => {
              return Number(
                Number(total) +
                  Number(
                    dataSourceApi?.maincategories["remaining-funds"]
                      ?.subcategories[subCat]?.monthBudget
                  )
              );
            }, 0)
            .toFixed(2)
        : "0.00"
    );
    return budget;
  };
  return (
    <div className="formdiv current-spending-table">
      <Table
        className="scroll monthly-spend-table"
        id="monthly-spend-table"
        rootClassName="monthly-spend-table"
        // style={{direction: "rtl"}}
        dataSource={dataTable}
        columns={columns}
        size="middle"
        virtual
        pagination={false}
        rowClassName={(record) => record.className}
        summary={() => (
          <Table.Summary
            fixed={"bottom"}
            className="monthly-spending"
            id="monthly-spend-table-foot"
          >
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} align="left">
                Remaining Funds
              </Table.Summary.Cell>
              {dataSourceApi?.monthsAry.map((month, monthIndex) => {
                let monthlyData = numberFormat(
                  dataSourceApi?.maincategories["remaining-funds"]
                    ? Object.keys(
                        dataSourceApi?.maincategories["remaining-funds"]
                          ?.subcategories
                      ).reduce((total, subCat) => {
                        return Number(
                          Number(total) +
                            Number(
                              isNaN(
                                dataSourceApi?.maincategories["remaining-funds"]
                                  ?.subcategories[subCat][monthIndex]
                                  ?.monthAmount
                              )
                                ? 0
                                : dataSourceApi?.maincategories[
                                    "remaining-funds"
                                  ]?.subcategories[subCat][monthIndex]
                                    ?.monthAmount
                            )
                        ).toFixed(2);
                        // );
                      }, 0)
                    : "0.00"
                );
                return (
                  <Table.Summary.Cell align="center" key={Math.random()}>
                    <span className={`${getClassNameWithSign(monthlyData)}`}>
                      {monthlyData}
                    </span>
                  </Table.Summary.Cell>
                );
              })}
              <Table.Summary.Cell align="center" index={13}>
                <span className={`${getClassNameWithSign(getAverageAmount())}`}>
                  {getAverageAmount()}
                </span>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={14} align="center" fixed="right">
                <span>{getBudgetAmount()}</span>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
      <Modal
        title="Transactions"
        open={isTransactionModel}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        footer={[]}
        width={900}
        className="transaction-modal"
      >
        <p className="monthly-text">
          {currentData?.currentMonth}:{" "}
          {currentData?.onClickData?.parentValue?.szName} -{" "}
          {currentData?.onClickData?.value?.szName}{" "}
        </p>
        <Table
          columns={columnTransactions || []}
          dataSource={currentData?.transactionData || []}
          size="middle"
          pagination={false}
          virtual
        />
      </Modal>
    </div>
  );
}

export default MonthlySpend;
