import React, { useEffect } from 'react';
import { useAppContext } from '../../context/AppContext';
import { Space } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import FirmSettings from './FirmSettings';
import PlanSettings from './PlanSettings';
import LanguageSettings from './LanguageSettings';
function Settings() {
    const { activeDetails, setActiveDetails } = useAppContext(8);
    useEffect(() => {
        setActiveDetails(parseInt((sessionStorage.getItem("activeDetails") === 1 ? 0 : sessionStorage.getItem("activeDetails")) || 0))
        return () => {
            setActiveDetails(0)
        }
    }, [])
    return (
        <div className='bodymaincontainer'>
            <div className='personalmaindev'>
                <div className='personalheader' style={{ background: '#E17049' }}>

                    <div className='personalheadertext'>
                        <Space wrap size={16}>
                            <SettingOutlined style={{ color: 'white' }} className='ant-avatar-circle' />
                        </Space>
                        {'Settings'}
                    </div>
                </div>
                {activeDetails === 8 && (<FirmSettings />)}
                {activeDetails === 1 && (<PlanSettings />)}
                {activeDetails === 9 && (<LanguageSettings />)}
            </div>
        </div>
    );
}

export default Settings;