// apiService.js
import axios from 'axios';
import { TEMP_AUTH_TOKEN_API, BASE_URL } from './apiConstant';

let axiosInstance = axios.create({
  BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getAuthToken = async () => {
  return new Promise((resolve, reject) => {
    if(!localStorage.getItem('token')){
      axiosInstance.get(BASE_URL + TEMP_AUTH_TOKEN_API, {
      }).then(response => {
        console.log(response);
        if(response?.data?.data?.jwtToken){
          // localStorage.setItem('token', response?.data?.data?.jwtToken);
          resolve(response?.data?.data?.jwtToken);
        }
      })
      
    } else {
      resolve(localStorage.getItem('token'));
    }
  })
  
};

let token = getAuthToken();

if(!token){
  getAuthToken('');
}

// Function to make a GET request
export const getRequest = async (url, params = {}, customHeaders  = {}) => {
  token = await getAuthToken();
  console.log(token);
  try {
    const headers = {
      Authorization: `${token}`, // Include the token in the headers
      ...customHeaders, // Merge custom headers with authorization header
    };
    console.log(headers)

    const response = await axiosInstance.get(BASE_URL + url, {
      params,
      headers,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to make a POST request
export const postRequest = async (url, data = {}, customHeaders  = {}) => {
  token = await getAuthToken();
  try {
    const headers = {
      Authorization: `${token}`, // Include the token in the headers
      ...customHeaders, // Merge custom headers with authorization header
    };

    const response = await axiosInstance.post(BASE_URL + url, data, {
      headers,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to make a PUT request
export const putRequest = async (url, data = {}, customHeaders = {}) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`, // Include the token in the headers
      ...customHeaders, // Merge custom headers with authorization header
    };

    const response = await axiosInstance.put(BASE_URL + url, data, {
      headers,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to make a DELETE request
export const deleteRequest = async (url, customHeaders = {}) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`, // Include the token in the headers
      ...customHeaders, // Merge custom headers with authorization header
    };

    const response = await axiosInstance.delete(BASE_URL + url, {
      headers,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};