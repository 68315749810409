import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Select, InputNumber } from "antd";
import {
  CREATE_MEETING_NOTES,
  DELETE_MEETING_NOTES,
  GET_MEETING_LIST,
  UPDATE_MEETING_NOTES,
} from "../../../services/apiConstant";
import { useApiMessages } from "../../../context/ApiMessageContext";
import { useParams } from "react-router-dom";
import { postRequest } from "../../../services/apiService";
import { showLoading } from "react-global-loading";
import { CaretDownOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import moment from "moment-timezone";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

function MeetingHistory() {
  const date = moment().format("YYYY-MM-DDT23:59:59.000Z");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [meetingTitle, setMeetingTitle] = useState("");
  const [meetingTime, setMeetingTime] = useState("");
  const [meetingDescription, setMeetingDescription] = useState("");
  const [meetingDate, setMeetingDate] = useState(date);
  const { Option } = Select;
  const { setErrorMessage, setSuccessMessage } = useApiMessages();
  const [notes, setNotes] = useState();
  const [data, setData] = useState([]);
  const params = useParams();
  const [selectedData, setSelectedData] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const [infoModalVisible, isInfoModalVisible] = useState(false);
  const [readMoreItem, setReadMoreItem] = useState(null);

  const [form] = Form.useForm();

  const szReference = params?.id;

  const desiredTimezone = 'America/New_York';
  const handleDateChange = (date) => {
    // Convert the selected date to the desired timezone
    const convertedDate = moment.tz(date, desiredTimezone).toDate();
    setDateHandler(convertedDate);
  };

  function convertUTCToLocalDate(date) {
    if (!date) {
      return date
    }
    date = new Date(date)
    date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
    return date
  }
  
  function convertLocalToUTCDate(date) {
    if (!date) {
      return date
    }
    date = new Date(date)
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
    return date
  }


  const getData = async () => {
    showLoading(true);
    try {
      const response = await postRequest(GET_MEETING_LIST, {
        szReference,
      });
      showLoading(false);
      if (response?.code === 200) {
        setNotes(response?.data?.szDedicatedNotes);
        setData(response?.data?.meetingnotes);
        setMeetingDate(date);
      }
    } catch (error) {
      showLoading(false);
      console.error(error);
    }
  };

  const handleDelete = async () => {
    showLoading(true);

    try {
      const response = await postRequest(DELETE_MEETING_NOTES, {
        szReference,
        id: selectedData?.id,
      });

      if (response?.code === 200) {
        setSuccessMessage(response?.data?.msg);
        getData();
        setIsDelete(false);
      } else {
        setErrorMessage("Something went wrong");
      }
      showLoading(false);
    } catch (error) {
      showLoading(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleSave = async () => {
    showLoading(true);
    setIsDisable(true);

    try {
      const response = await postRequest(UPDATE_MEETING_NOTES, {
        szReference,
        szDedicatedNotes: notes,
      });

      if (response?.code === 200) {
        setSuccessMessage(response?.data?.msg);
        scrollToTop();
      } else {
        setErrorMessage("Something went wrong");
      }
      showLoading(false);
      setIsDisable(false);
    } catch (error) {
      showLoading(false);
      setIsDisable(false);
    }
  };

  const openModal = async () => {
    setIsModalVisible(true);
    setMeetingTitle("");
    setMeetingTime("");
    setMeetingDescription("");
    setSelectedData();

    await form.resetFields();
    console.log(dayjs(date, "YYYY-MM-DD"));
    setMeetingDate(date);
  };

  const handleModalOk = async () => {
    showLoading(true);
    setIsDisable(true);

    try {
      let validated = await form.validateFields();
      console.log(validated);
      const response = await postRequest(CREATE_MEETING_NOTES, {
        szReference,
        szSubject: meetingTitle,
        szNotes: meetingDescription,
        timespent: meetingTime,
        meetingDate: meetingDate,
        id: selectedData?.id > 0 ? selectedData?.id : 0,
      });

      if (response?.code === 200) {
        setSuccessMessage(response?.data?.msg);
        setIsModalVisible(false);
        setMeetingTitle("");
        setMeetingTime("");
        setMeetingDescription("");
        setMeetingDate(date);
        getData();
        form.resetFields();
        setSelectedData();
      } else {
        setErrorMessage("Something went wrong");
      }
      showLoading(false);
      setIsDisable(false);
    } catch (error) {
      showLoading(false);
      setIsDisable(false);
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setSelectedData();
  };

  const onChangeAction = (e, item) => {
    setSelectedData(item);
    if (e === "edit") {
      form.setFieldsValue(item, { timespent: parseInt(item?.timespent) });
      setMeetingTitle(item?.szSubject);
      setMeetingDescription(item?.szNotes);
      setMeetingTime(item?.timespent);
      setMeetingDate(item.meetingDate ? item.meetingDate : date);
      setIsModalVisible(true);
      console.log(item);
    } else {
      setIsDelete(true);
    }
  };

  useEffect(() => {
    document.title = "Meeting History & Notes";
    window.scrollTo(0, 0);
    console.log("scrol to top");
    getData();
  }, []);

  const readMore = (item) => {
    console.log(item);
    isInfoModalVisible(true);
    setReadMoreItem(item);
  };

  const okReadMore = () => {
    isInfoModalVisible(false);
    setReadMoreItem(null);
  };

  const setDateHandler = (value) => {
    console.log("Selected Time: ", value);
    setMeetingDate(dayjs(value).format("YYYY-MM-DD"));
  };

  return (
    <>
      <div className="personalmainheader">
        <div className="personaltextbackground">
          <p className="personaltext">Meeting History & Notes</p>
        </div>
        <div className="line"></div>
        <div className="meetingline">
          <div>
            <h3 className="meetingText">Meetings</h3>
          </div>
          <div className="d-flex align-items-center">
            <div style={{ marginBottom: 2 }}>+ &nbsp;</div>
            <p className="newmeetingtext cursor-pointer" onClick={openModal}>
              New meeting note
            </p>
          </div>
        </div>
        <div className="data-section">
          {data?.map((item, index) => (
            <div className="cardcontainer" key={index}>
              <div className="datediv">
                <p className="datetext">
                  {moment(item.meetingDate).format("MMMM DD, YYYY")}
                </p>
              </div>
              <div className="followdiv">
                <div className="flowdiv">
                  <p className="followtext">{item.szSubject}</p>
                </div>
                <div className="textdiv">
                  <div
                    className="paratextes"
                    dangerouslySetInnerHTML={{
                      __html:
                        item.szNotes?.length <= 250
                          ? item.szNotes
                          : item.szNotes.substring(0, 250) + "...",
                    }}
                  />
                  {item.szNotes?.length > 250 && (
                    <span
                      className="readmore"
                      onClick={() => {
                        readMore(item);
                      }}
                    >
                      Read More
                    </span>
                  )}
                </div>
              </div>
              {/* <div className='followdivmain'></div> */}
              <div className="meetingHistoryaction">
                <Select
                  placeholder="Actions"
                  value={null}
                  onChange={(e) => onChangeAction(e, item)}
                  suffixIcon={<CaretDownOutlined />}
                >
                  <Option value={"edit"}>Edit</Option>
                  <Option value={"delete"}>Delete</Option>
                </Select>
              </div>
            </div>
          ))}
        </div>
        {data?.length === 0 && (
          <div className="cardcontainer not-found">
            No Meeting Scheduled Yet
          </div>
        )}
        <div className="card-bottom-line"></div>
        <div className="notediv">
          <p className="notetext">Notes</p>
        </div>
        <div className="notediv">
          <p className="generaltext">General notes</p>
        </div>
        <div /*className='textarea'*/>
          <Input.TextArea
            value={notes}
            rows={20}
            className="inputtextarea"
            onChange={(e) => setNotes(e?.target?.value)}
            onBlurCapture={() => !isDisable && handleSave}
            style={{ resize: "none" }}
          />
        </div>
        <Button
          className="primary-medium-btn"
          disabled={isDisable}
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
      {isModalVisible && (
        <Modal
          title={`${selectedData ? "Edit" : "New"} Meeting Note`}
          open={isModalVisible}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
          okText="Save"
          maskClosable={false}
          confirmLoading={isDisable}
        >
          <Form form={form} layout="vertical">
            <div className="title-time1">
              <div className="">
                <Form.Item
                  name="szSubject"
                  label="Title"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a title",
                    },
                  ]}
                  initialValue={meetingTitle}
                >
                  <Input onChange={(e) => setMeetingTitle(e.target.value)} />
                </Form.Item>
              </div>
            </div>
            <div className="title-time">
              <div className="title">
                <Form.Item
                  name="meetingDateField"
                  label="Meeting Date"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a meeting date",
                    },
                  ]}
                  initialValue={dayjs(meetingDate, "MM-DD-YYYY")}
                >
                  <DatePicker
                    dateFormat={"MM-dd-yyyy"}
                    selected={convertUTCToLocalDate(meetingDate)}
                    onChange={date => handleDateChange(convertLocalToUTCDate(date))}

                  />
                </Form.Item>
              </div>
              <div className="time">
                <Form.Item
                  name="timespent"
                  label="Meeting Length (in minutes)"
                  initialValue={meetingTime}
                >
                  <InputNumber onChange={(value) => setMeetingTime(value)} />
                </Form.Item>
              </div>
            </div>
            <div>
              <Form.Item
                name="szNotes"
                label="Description"
                rules={[
                  {
                    required: true,
                    message: "Please enter a description",
                  },
                ]}
                initialValue={meetingDescription}
                maxLength={500}
              >
                <CKEditor
                  editor={ClassicEditor}
                  data={meetingDescription || ""}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setMeetingDescription(data);
                  }}
                />
              </Form.Item>
            </div>
          </Form>
        </Modal>
      )}
      {infoModalVisible && (
        <Modal
          title={`${readMoreItem?.szSubject} (${moment(
            readMoreItem?.meetingDate
          ).format("MMMM DD, YYYY")} - ${readMoreItem?.timespent} minutes)`}
          open={infoModalVisible}
          onOk={okReadMore}
          onCancel={okReadMore}
          okText="Ok"
          footer={[]}
          maskClosable={false}
          afterClose={() => {
            setMeetingDate(date);
          }}
        >
          <p dangerouslySetInnerHTML={{ __html: readMoreItem?.szNotes }} />
        </Modal>
      )}
      {isDelete && (
        <DeleteConfirmationModal
          desc={"note"}
          isModalVisible={isDelete}
          setIsModalVisible={setIsDelete}
          handleDelete={handleDelete}
        />
      )}
    </>
  );
}

export default MeetingHistory;
