let apiUrl = "";
if (window.location.hostname.includes("localhost")) {
  apiUrl = "http://localhost:3000/";
} else if (window.location.hostname.includes("devadvisor.fisecal.com")) {
  apiUrl = "https://devadvisorapi.fisecal.com/";
} else {
  apiUrl = "https://coachapi.fisecal.com/";
}

export const BASE_URL = apiUrl;

export const LOGIN_API = "api/v1/login";
export const TEMP_AUTH_TOKEN_API = "auth/getAuthToken";

export const CLIENT_LIST_API = "api/v1/getClientList";
export const UPDATE_CLIENT_STATUS_API = "api/v1/updateClientStatus";
export const UPDATE_CLIENT_FINANCE_STATUS_API =
  "api/v1/updateClientFinanceStatus";
export const FORGOT_PASSWORD_API = "api/v1/forgotPassword";
export const UPDATE_CLIENT = "api/v1/updateClient";
export const FORGOT_PASSWORD_SAVE_API = "api/v1/changePasswordByEmail";
export const GET_CLIENT_DETAIL = "api/v1/getClientDetail";
export const SPENDING_AND_ACCOUNT_API = "api/v1/getCurrentSpendsAndBalance";
export const MONTHLY_SPENDING_API = "api/v1/getMonthlySpendData";
export const TRANSACTION_API = "api/v1/getMonthTransactions";
export const UPDATE_TRANSACTION_API = "api/v1/updateTransactionCatData";
export const GET_MEETING_LIST = "api/v1/getMeetingNotesList";
export const UPDATE_MEETING_NOTES = "api/v1/saveDedicatedNotes";
export const DELETE_MEETING_NOTES = "api/v1/deleteMeetingNotes";
export const CREATE_MEETING_NOTES = "api/v1/saveMeetingNotes";
export const UPDATE_CURRENT_SPENDING_ACCOUNT_API =
  "api/v1/updateCurrentSpendsAndBalance";
export const GET_FINANCIAL_GOAL_API = "api/v1/getFinancialGoals";
export const SAVE_FINANCIAL_GOAL_API = "api/v1/saveFinancialGoal";
export const DELETE_FINANCIAL_GOAL_API = "api/v1/deleteFinancialGoal";
export const GET_FIRM_SETTING_API = "api/v1/getFirmDetails";
export const UPDATE_FIRM_SETTING_API = "api/v1/updateFirmDetails";
export const FILE_UPLOAD_API = "api/v1/fileUpload";
export const FIRM_LOGO_DELETE_API = "api/v1/deleteFirmLogo";
export const GET_QUESTIONNAIRE_DETAILS = "api/v1/getQuestionnaireDetails";
export const SAVE_QUESTIONNAIRE_DETAILS = "api/v1/saveQuestionnaireDetails";
export const UPDATE_INSURANCE_DETAILS_API = "api/v1/saveInsuranceInformation";
export const GET_INSURANCE_DETAILS_API = "api/v1/getInsuranceInformation";
export const GET_RECOMMENDATION_API = "api/v1/getRecommendations";
export const SAVE_RECOMMENDATION_API = "api/v1/saveRecommendations";

export const DOWNLOAD_FINANCIAL_PLAN_API = "api/v1/downloadFinancialPlan";
export const SAVE_NOT_CONNECTED_ACCOUNT = "api/v1/saveNotConnectedAccount";

export const GET_NOT_CONNECTED_ACCOUNT = "api/v1/getNotConnectedAccount";
export const UPDATE_BUDGET_NUMBER_API = "api/v1/updateBudgetNumber";

export const GET_IMPORTANT_DATA = "api/v1/getImportantData";
export const DELETE_NOT_CONNECTED_ACCOUNT = "api/v1/deleteNotConnectedAccount";
export const UPDATE_RECOMMENDATION = "api/v1/updateGoalRecommendation";
