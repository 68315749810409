// ApiMessageContext.js
import React, { createContext, useContext, useState } from 'react';

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [activeDetails, setActiveDetails] = useState(0);
  const [firmDetails, setFirmDetails] = useState(null);
  const [toggle, setToggle] = useState(0);
  

  return (
    <AppContext.Provider
      value={{
        activeDetails,
        setActiveDetails,
        firmDetails,
        setFirmDetails,
        toggle,
        setToggle
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  return useContext(AppContext);
};

export { AppProvider, useAppContext };