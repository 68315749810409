import React, { useEffect } from 'react';
import { Card, Form, Input, Button } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer';
import { postRequest } from '../../services/apiService';
import { FORGOT_PASSWORD_SAVE_API } from '../../services/apiConstant';
import Header from '../../components/Header';
import { showLoading } from 'react-global-loading';
import { useApiMessages } from '../../context/ApiMessageContext';

function ResetPassword() {
    const params = useParams();
    const navigate = useNavigate();
    const { setErrorMessage, setSuccessMessage } = useApiMessages();
    const onFinish = async (values) => {
        showLoading(true);
        // Handle form submission here, e.g., send a request to reset the password
        let requestData = { ...values, szForgotPasswordToken: params.id };
        try {
            const response = await postRequest(FORGOT_PASSWORD_SAVE_API, requestData);
            showLoading(false);
            if (response?.code === 200) {
                setSuccessMessage(response?.data?.msg);
                navigate('/login');
            }
            else {
                setErrorMessage(response?.data?.msg)
            }
        } catch (error) {
            showLoading(false);
            // Handle API call error here
            if (error?.response?.data?.data?.msg) {
                setErrorMessage(error?.response?.data?.data?.msg);
            }

        }

    };

    useEffect(function () {
        return () => {
            setErrorMessage('');
        }
    }, []);

    return (
        <>
            <Header />
            <div className='MainContainer'>


                <Card bordered={false} className='card'>
                    <div className="ordiv">
                        <div className='welcometext'>Reset Password</div>
                        <p>Enter the new password below which you want to set.</p>
                    </div>
                    <Form
                        name="resetPasswordForm"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="szPassword"
                            rules={[
                                { required: true, message: 'Please enter your new password' },
                                { min: 6, message: 'Password must be at least 6 characters long' },
                            ]}
                        >
                            <Input.Password className='input1' placeholder="New Password" visibilityToggle={false} />
                        </Form.Item>

                        <Form.Item
                            name="szConfirmPassword"
                            dependencies={['szPassword']}
                            hasFeedback
                            rules={[
                                { required: true, message: 'Please confirm your password' },
                                { min: 6, message: 'Password must be at least 6 characters long' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('szPassword') === value || value.length <= 6) {
                                            return Promise.resolve();
                                        } else {
                                            return Promise.reject('The two passwords do not match');
                                        }

                                    },
                                }),
                            ]}
                        >
                            <Input.Password className='input1' placeholder="Confirm Password" visibilityToggle={false} />
                        </Form.Item>

                        <Form.Item >
                            <Button type="primary" htmlType="submit" className='primary-medium-btn mt-1'>
                                Reset Password
                            </Button>
                        </Form.Item>


                    </Form>
                </Card>
            </div>
            <Footer />
        </>
    );
}

export default ResetPassword;