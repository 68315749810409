import React, { useEffect, useState, useRef } from "react";
import { Button, Form, Input, Avatar, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";
import {
  GET_FIRM_SETTING_API,
  UPDATE_FIRM_SETTING_API,
  FILE_UPLOAD_API,
  BASE_URL,
  FIRM_LOGO_DELETE_API,
} from "../../../services/apiConstant";
import { postRequest } from "../../../services/apiService";
import { showLoading } from "react-global-loading";
import { useApiMessages } from "../../../context/ApiMessageContext";
import { useAppContext } from "../../../context/AppContext";

import { PatternFormat } from "react-number-format";

import "./index.scss";

function FirmSettings() {
  const [form] = Form.useForm();
  const [user, setUser] = useState(null);
  const { setFirmDetails } = useAppContext();
  const { setErrorMessage, setSuccessMessage } = useApiMessages();
  const inputFileRef = useRef(null);

  const szReference = localStorage.getItem("szReference");

  const getFirmDetails = async () => {
    showLoading(true);
    try {
      const response = await postRequest(GET_FIRM_SETTING_API, {
        szReference,
      });

      showLoading(false);

      if (response?.code === 200) {
        // navigate(-1);
        let data = response?.data;
        data.firm_logo = data?.firm_logo ? BASE_URL + data?.firm_logo : "";
        setUser(data);
      } else {
        setErrorMessage("Something went wrong");
      }
    } catch (error) {
      showLoading(false);
      setErrorMessage("Something went wrong");
    }
  };
  const updateFirmDetails = async () => {
    const values = await form.validateFields();
    try {
      showLoading(true);

      const response = await postRequest(UPDATE_FIRM_SETTING_API, {
        szReference,
        ...values,
      });

      showLoading(false);

      if (response?.code === 200) {
        // navigate(-1);
        setSuccessMessage(response?.data?.msg);
        let data = response?.data;
        data.firm_logo = data?.firm_logo ? BASE_URL + data?.firm_logo : "";
        setUser(data);
        window.scrollTo(0, 0);
      } else {
        setErrorMessage("Something went wrong");
      }
    } catch (error) {
      showLoading(false);
      setErrorMessage("Something went wrong");
      console.log("Form Validation Error:", error);
    }
  };
  useEffect(() => {
    document.title = "Firm Settings";
    window.scrollTo(0, 0);
    getFirmDetails();
    return () => {
      form.resetFields();
    };
  }, []);
  useEffect(() => {
    // form.i
    form.setFieldsValue(user);
    setFirmDetails(user);
  }, [user]);

  const clearFileInput = (id) => {
    document.getElementById(id).value = "";
  };

  const handleSelectFile = (e) => {
    const file = e.target.files[0];
    if (file?.type?.includes("image")) {
      console.log(file);
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        async () => {
          showLoading(true);
          // convert image file to base64 string
          console.log(reader.result);
          setUser({ ...user, avatar: reader.result });
          const formData = {
            szReference: szReference,
            fileSource: reader.result,
          };
          const response = await postRequest(FILE_UPLOAD_API, {
            szReference,
            ...formData,
          });
          if (response?.code === 200) {
            setSuccessMessage(response?.data?.msg);
            getFirmDetails();
            showLoading(false);
            clearFileInput("input-file");
          } else {
            setErrorMessage("Something went wrong");
            showLoading(false);
          }
        },
        false
      );
      if (file) {
        reader.readAsDataURL(file);
      }
    } else {
      setErrorMessage("Invalid image format");
    }
  };

  const handleRemoveFile = async () => {
    showLoading(true);
    const response = await postRequest(FIRM_LOGO_DELETE_API, {
      szReference,
    });
    if (response?.code === 200) {
      setSuccessMessage(response?.data?.msg);
      getFirmDetails();
      showLoading(false);
    } else {
      setErrorMessage("Something went wrong");
      showLoading(false);
    }
  };

  const InputField = ({ name, label, required, className, rule = {} }) => (
    <Form.Item
      className={"formtitle"}
      name={name}
      label={label}
      validateFirst={true}
      rules={[
        {
          required: required,
          message: `Please enter your ${label
            ?.toLowerCase()
            ?.replace("your", "")}`,
          whitespace: true,
        },
        {
          ...rule,
        },
      ]}
    >
      <Input className={className || "input1 w-100"} />
    </Form.Item>
  );

  const cancelForm = () => {
    form.setFieldsValue(user);
  };

  return (
    <>
      <div className="personalmainheader">
        <div className="personaltextbackground">
          <p className="personaltext">Firm Settings</p>
        </div>
        <div className="line"></div>

        <div className="formdiv setting-form">
          <div className="from-section">
            <Form
              form={form}
              name="firmSettiingForm"
              layout="basic"
              autoComplete="off"
              onFinish={updateFirmDetails}
              scrollToFirstError={{
                behavior: "smooth",
                block: "center",
                inline: "center",
              }}
            >
              <div className="name-input">
                <InputField name="first_name" label="First name" required />
              </div>
              <div className="name-input">
                <InputField name="last_name" label="Last name" required />
              </div>
              <div className="line2"></div>

              <div className="name-input">
                <InputField name="firm_name" label="Firm Name" required />
              </div>
              <div className="name-input">
                <InputField
                  name="email"
                  label="Email"
                  required={true}
                  rule={{
                    type: "email",
                    message: "Please enter a valid email address",
                  }}
                />
              </div>
              <div className="name-input">
                <Form.Item
                  className={"formtitle"}
                  name="phone"
                  label="Phone Number"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const pattern = new RegExp(
                          /^((\+1)|1)? ?\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})( ?(ext\.? ?|x)(\d*))?$/
                        );
                        if (value && !pattern.test(value)) {
                          return Promise.reject(
                            new Error("Please enter a valid phone number")
                          );
                        }
                        return Promise.resolve(value);
                      },
                    }),
                  ]}
                >
                  <PatternFormat
                    format="(###) ###-####"
                    className="ant-input css-dev-only-do-not-override-1vr7spz input1 w-100 phonenumber-input"
                    valueIsNumericString={false}
                  />
                </Form.Item>
              </div>
              <div className="line7"></div>
              <div className="name-input logo-change-section ant-form-item-label">
                <div className="yourLogodiv">
                  <label className="yourLogoText">Your logo</label>
                </div>
                <div className="image-section">
                  <Space wrap size={78} className="logo-left">
                    {user?.firm_logo && (
                      <img
                        src={user?.firm_logo}
                        alt="user images"
                      />
                    )}
                    {!user?.firm_logo && (
                      <Avatar
                        size={78}
                        shape="square"
                        icon={<UserOutlined />}
                      />
                    )}
                  </Space>
                  <input
                    type="file"
                    name="file"
                    onChange={handleSelectFile}
                    id="input-file"
                    ref={inputFileRef}
                    accept="image/*"
                  ></input>
                  <div className="logo-right">
                    {user?.firm_logo && (
                      <span
                        onClick={() => {
                          user.firm_logo && handleRemoveFile();
                        }}
                      >
                        Delete
                      </span>
                    )}
                    <span
                      onClick={() => {
                        inputFileRef.current.click();
                      }}
                    >
                      Update
                    </span>
                  </div>
                </div>
              </div>
              <div className="line7"></div>
              <div className="btm-btn">
                <Form.Item>
                  <Button
                    type="button"
                    className="ant-btn-default"
                    htmlType="button"
                    onClick={cancelForm}
                  >
                    Cancel
                  </Button>
                  <Button className="primary-medium-btn" htmlType="submit">
                    Save changes
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default FirmSettings;
