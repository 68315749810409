import React, { useEffect, useState } from "react";
import { Radio, Button, Form, Input, Select, InputNumber } from "antd";
import { useParams } from "react-router-dom";
import { postRequest } from "../../../services/apiService";
import { showLoading } from "react-global-loading";
import {
  UPDATE_INSURANCE_DETAILS_API,
  GET_INSURANCE_DETAILS_API,
} from "../../../services/apiConstant";
import { useApiMessages } from "../../../context/ApiMessageContext";
import "./index.scss";
import {
  getInflationRateInsurance,
  getRateOfReturnInsurance,
  getTotalAssets,
  getTotalLiability,
} from "../../../utils/helper";
import { debounce } from "lodash";
export default function InsuranceInformation({ data, userData }) {
  const [form] = Form.useForm();
  const [insurance, setInsurance] = useState([
    { id: 0, szType: "life-insurance", insuranceVisible: 2 },
    { id: 0, szType: "health-insurance", insuranceVisible: 2 },
    { id: 0, szType: "car-insurance", insuranceVisible: 2 },
    { id: 0, szType: "renters-insurance", insuranceVisible: 2 },
    { id: 0, szType: "disablity-insurance", insuranceVisible: 2 },
    { id: 0, szType: "liability-insurance", insuranceVisible: 2 },
    { id: 0, szType: "long-term-care-insurance", insuranceVisible: 2 },
    {
      id: 0,
      szType: "2nd/vacation/investment-property-insurance",
      insuranceVisible: 2,
    },
    { id: 0, szType: "other-insurance", insuranceVisible: 2 },
  ]);
  const [insuranceRec, setInsuranceRec] = useState();
  const [defaultValue, setDefaultValue] = useState({});
  const [clickedIndex, setClickedIndex] = useState(null);
  const params = useParams();
  const { setErrorMessage, setSuccessMessage } = useApiMessages();
  const szReference = params?.id;

  const dropdownProps = {
    paymentFrequency: [
      {
        label: "Weekly",
        value: "weekly",
      },
      {
        label: "Biweekly",
        value: "biweekly",
      },
      {
        label: "15th/30th",
        value: "15th-30th",
      },
      {
        label: "Monthly",
        value: "monthly",
      },
      {
        label: "Every 3 Months (Quarterly)",
        value: "quarterly",
      },
      {
        label: "Every 6 Months (Semi-Annually)",
        value: "semi-annually",
      },
      {
        label: "Yearly",
        value: "yearly",
      },
    ],
    payViaPayCheck: [
      {
        label: "Yes",
        value: 1,
      },
      {
        label: "No",
        value: 0,
      },
    ],
  };

  const handleSave = async (values) => {
    try {
      console.log(values);
      const formData = {
        szReference,
        ...values?.insurance?.[clickedIndex],
      };
      showLoading(true);

      try {
        const response = await postRequest(
          UPDATE_INSURANCE_DETAILS_API,
          formData
        );

        showLoading(false);

        if (response?.code === 200) {
          setSuccessMessage("Insurance Details Saved Successfully");
          window.scrollTo(0, 0);
        } else {
          setErrorMessage("Something went wrong");
        }
      } catch (error) {
        showLoading(false);
        setErrorMessage("Something went wrong");
        console.log("Form Validation Error:", error);
      }
      // }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getInsuranceData();
    window.scrollTo(0, 0);
  }, []);

  const getInsuranceData = async () => {
    try {
      showLoading(true);
      const response = await postRequest(GET_INSURANCE_DETAILS_API, {
        szReference,
      });
      if (response?.code === 200) {
        console.log(response?.data);

        setInsuranceRec(response?.data?.insuranceRec);
        const mergedArray = insurance
          .map((obj1, index) => {
            const matchingObj = response?.data?.insuranceinformation?.find(
              (obj2) => obj2.szType === obj1.szType
            );
            return matchingObj ? { ...obj1, ...matchingObj } : obj1;
          })
          .concat(
            response?.data?.insuranceinformation?.filter(
              (obj2) => !insurance?.find((obj1) => obj1.szType === obj2.szType)
            )
          );
        setInsurance([...mergedArray] || []);
        form.setFieldsValue({ insurance: mergedArray });

        mergedArray?.forEach((obj, index) => {
          getInsuranceRecDefault(
            obj?.insuranceVisible,
            obj?.szType,
            index,
            mergedArray
          );
        });
      } else {
        setErrorMessage("Something went wrong");
      }
      showLoading(false);
    } catch (error) {
      showLoading(false);
      setErrorMessage("Something went wrong");
      console.log("Server Error:", error);
    }
  };

  const calcRec = (values, val) => {
    let initialValues = form.getFieldsValue();
    let allInsurance = initialValues?.insurance;
    const changeItem = val?.insurance?.[values?.insurance?.length - 1];
    const index = values?.insurance?.length - 1;
    console.log({ values, val, changeItem });

    if (values?.insurance?.[index]?.szPaymentFrequency) {
      allInsurance[index].monthlyCost = getMonthlyCostViaFrq(
        changeItem?.szPaymentFrequency,
        allInsurance[index].cost || 0
      );
      console.log("allInsurance==", allInsurance);
    }
    if (values?.insurance?.[index]?.cost) {
      allInsurance[index].monthlyCost = getMonthlyCost(
        values?.insurance?.[index]?.cost,
        changeItem?.szPaymentFrequency
      );
    }

    form.setFieldsValue({ insurance: allInsurance });
    setInsurance(allInsurance);

    if (values?.insurance?.[index]?.insuranceVisible) {
      getInsuranceRecDefault(
        changeItem?.insuranceVisible,
        changeItem?.szType,
        index,
        allInsurance
      );
    }
  };
  const formatLable = (value) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  const getMonthlyCostViaFrq = (e, cost) => {
    console.log(e, "e");
    console.log(e);
    let freqValue = 1;
    switch (e) {
      case "weekly":
        freqValue = 4.3333;
        break;
      case "biweekly":
        freqValue = 2.1667;
        break;
      case "15th-30th":
        freqValue = 2;
        break;
      case "monthly":
        freqValue = 1;
        break;
      case "quarterly":
        freqValue = 0.3333;
        break;
      case "semi-annually":
        freqValue = 0.16667;
        break;
      case "yearly":
        freqValue = 0.0833;
        break;
      default:
        freqValue = 4.3333;
        break;
    }

    return Math.max((cost * freqValue).toFixed(2))?.toString();
  };

  const getMonthlyCost = (e, freq) => {
    let freqValue = 1;
    switch (freq) {
      case "weekly":
        freqValue = 4.3333;
        break;
      case "biweekly":
        freqValue = 2.1667;
        break;
      case "15th-30th":
        freqValue = 2;
        break;
      case "monthly":
        freqValue = 1;
        break;
      case "quarterly":
        freqValue = 0.3333;
        break;
      case "semi-annually":
        freqValue = 0.16667;
        break;
      case "yearly":
        freqValue = 0.0833;
        break;
      default:
        freqValue = 4.3333;
        break;
    }
    return Math.max((freqValue * e).toFixed(2))?.toString();
  };

  const getInsuranceRecDefault = (
    insuranceVisibile,
    szType,
    index,
    allInsurance
  ) => {
    let HealthInsurance = "No";
    let OtherInsurance = "No";
    let LiabilityInsurance = "No";
    let DisablityInsurance = "No";
    let RenterInsurance = "No";
    let LifeInsurance = "No";
    let NeedLifeInsurance = "No";
    let healthBudget = allInsurance[1].monthlyCost || 456;
    let carBudget = allInsurance[2].monthlyCost || 119;
    let renterBudget = allInsurance[3].monthlyCost || 15;
    let otherBudget = allInsurance[8].monthlyCost;
    let liablilityBudget = allInsurance[5].monthlyCost || 0;
    let disablityBudget = allInsurance[4].monthlyCost || 0;
    let AutoInsurance = "No";
    let LongTermCareInsurance = "No";
    let HaveCar = "No";
    let carRec = "";
    let healthRec = "";
    let otherRec = "";
    let liabilityRec = "";
    let disablityRec = "";
    let rentersRec = "";
    let lifeInsuranceRec = "";
    let longTermCareInsuranceRec = "";
    let totalAssets = 0;
    let totalLiability = 0;
    let lifeInsuranceBudget = allInsurance?.[0]?.monthlyCost || 0;
    let TLICYou = data?.importantValues?.TLICYou || 0;
    let TLICPart = data?.importantValues?.TLICPart || 0;
    let _2ndVacationPropertyInsurance = "No";
    let _2ndVacationPropertyInsuranceRec = "";
    let _2ndPropBudget = allInsurance[7].monthlyCost || 0;
    let longTermBudget = allInsurance[6].monthlyCost || 0;

    let NW = 0;
    totalAssets = getTotalAssets(data?.accounts || []);
    totalLiability = getTotalLiability(data?.accounts || []);

    NW = totalAssets - totalLiability;
    console.log({ totalAssets, totalLiability, NW });
    if (insuranceVisibile === 1 && szType === "health-insurance") {
      HealthInsurance = "Yes";
    }
    if (
      insuranceVisibile === 1 &&
      szType === "2nd/vacation/investment-property-insurance"
    ) {
      _2ndVacationPropertyInsurance = "Yes";
    } else {
      _2ndVacationPropertyInsurance = "No";
    }
    if (
      userData?.accounts?.find((acc) =>
        ["2ndVacationProperty", "2ndVacationPropertyMortgage"].includes(
          acc.type
        )
      ) &&
      _2ndVacationPropertyInsurance === "No"
    ) {
      _2ndPropBudget = 210;
    }
    if (
      userData?.accounts?.find((acc) =>
        ["2ndVacationProperty", "2ndVacationPropertyMortgage"].includes(
          acc.type
        )
      ) &&
      _2ndVacationPropertyInsurance === "Yes"
    ) {
      _2ndVacationPropertyInsuranceRec =
        "Review policy with your insurance provider to ensure correct coverage";
    } else if (
      userData?.accounts?.find((acc) =>
        ["2ndVacationProperty", "2ndVacationPropertyMortgage"].includes(
          acc.type
        )
      ) &&
      _2ndVacationPropertyInsurance === "No"
    ) {
      _2ndVacationPropertyInsuranceRec =
        "Obtain coverage as soon as possible by speaking with an insurance provider";
    } else {
      _2ndVacationPropertyInsuranceRec =
        "No coverage is recommended based on the info entered";
    }
    if (insuranceVisibile === 1 && szType === "long-term-care-insurance") {
      LongTermCareInsurance = "Yes";
    }
    if (insuranceVisibile === 1 && szType === "life-insurance") {
      LifeInsurance = "Yes";
    }
    if (
      !data.is_info_sharable &&
      HealthInsurance === "No" &&
      !data?.no_of_dep_for_tax_return
    ) {
      healthBudget = 456;
    } else if (
      (data.is_info_sharable === 1 &&
        HealthInsurance === "No" &&
        data?.no_of_dep_for_tax_return >= 0) ||
      (data.is_info_sharable &&
        HealthInsurance === "No" &&
        data?.no_of_dep_for_tax_return > 0)
    ) {
      healthBudget = 1152;
    }
    if (
      data?.is_info_sharable === 1 ||
      data?.no_of_dep_for_tax_return >= 1 ||
      data?.rent_or_own === "own"
    ) {
      NeedLifeInsurance = "Yes";
    }
    if (
      userData?.accounts?.find((acc) => ["car", "carLoan"].includes(acc.type))
    ) {
      HaveCar = "Yes";
    }
    if (insuranceVisibile === 1 && szType === "car-insurance") {
      AutoInsurance = "Yes";
    }
    if (insuranceVisibile === 1 && szType === "other-insurance") {
      OtherInsurance = "Yes";
    }
    if (insuranceVisibile === 1 && szType === "renters-insurance") {
      RenterInsurance = "Yes";
    }
    if (insuranceVisibile === 1 && szType === "disablity-insurance") {
      DisablityInsurance = "Yes";
    }
    if (insuranceVisibile === 1 && szType === "liability-insurance") {
      LiabilityInsurance = "Yes";
    }
    if (!carBudget) {
    }
    if (LongTermCareInsurance === "Yes") {
      longTermCareInsuranceRec =
        "Review policy with your insurance provider to ensure correct coverage";
    } else if (
      data?.age >= 50 &&
      LongTermCareInsurance === "No" &&
      (!data?.is_info_sharable || data?.is_info_sharable === 2)
    ) {
      longTermCareInsuranceRec =
        "Depending on your own health history and your family’s health history, you may want to consider talking with an insurance agent to discuss your need for Long-Term Care coverage.";
    } else if (
      (data?.age >= 50 &&
        LongTermCareInsurance === "No" &&
        data?.is_info_sharable === 1) ||
      (data?.partner_age >= 50 &&
        LongTermCareInsurance === "No" &&
        data?.is_info_sharable === 1) ||
      (data?.age >= 50 &&
        LongTermCareInsurance === "No" &&
        data?.partner_age >= 50 &&
        data?.is_info_sharable === 1)
    ) {
      longTermCareInsuranceRec =
        "Depending on your and your Partner’s own health history, as well as your family’s health history, you and your Partner may want to consider talking with an insurance agent to discuss your need for Long-Term Care coverage.";
    } else if (
      data?.age < 50 &&
      LongTermCareInsurance === "No" &&
      (!data?.is_info_sharable || data?.is_info_sharable === 2)
    ) {
      longTermCareInsuranceRec =
        "No coverage is recommended based on the info entered. However, depending on your own health history, as well as your family’s health history, you may want to consider talking with an insurance agent about your need for Long-Term Care coverage if you believe your health history warrants it.";
    } else if (
      (data?.age < 50 &&
        LongTermCareInsurance === "No" &&
        data?.is_info_sharable === 1) ||
      (data?.partner_age < 50 &&
        LongTermCareInsurance === "No" &&
        data?.is_info_sharable === 1) ||
      (data?.age < 50 &&
        LongTermCareInsurance === "No" &&
        data?.partner_age < 50 &&
        data?.is_info_sharable === 1)
    ) {
      longTermCareInsuranceRec =
        "No coverage is recommended based on the info entered. However, depending on your and your Partner’s own health history, as well as your family’s health history, you and your Partner may want to consider talking with an insurance agent about the need for Long-Term Care coverage if you believe your and your Partner’s health history warrants it.";
    } else {
      longTermCareInsuranceRec =
        "No coverage is recommended based on the info entered. However, depending on your own health history, as well as your family’s health history, you may want to consider talking with an insurance agent about your need for Long-Term Care coverage if you believe your health history warrants it.";
    }

    if (HaveCar === "Yes" && AutoInsurance === "Yes") {
      carRec =
        "Review policy with your insurance provider to ensure correct coverage";
    } else if (HaveCar === "Yes" && AutoInsurance === "No") {
      carRec =
        "Obtain coverage as soon as possible by speaking with an insurance provider";
    } else {
      carRec = "No coverage is needed based on the info entered.";
    }

    if (HealthInsurance === "Yes") {
      healthRec =
        "Review policy with your insurance provider to ensure correct coverage";
    } else {
      healthRec =
        "Obtain coverage as soon as possible by speaking with an insurance provider";
    }

    if (OtherInsurance === "Yes") {
      otherRec =
        "Review policy with your insurance provider to ensure correct coverage";
    } else {
      otherRec =
        "No additional coverage is recommended based on the info entered";
    }
    if (RenterInsurance === "Yes") {
      rentersRec =
        "Review policy with your insurance provider to ensure correct coverage";
    } else {
      rentersRec =
        "Obtain coverage as soon as possible by speaking with an insurance provider";
    }
    if (DisablityInsurance === "Yes") {
      disablityRec =
        "Review policy with your insurance provider to ensure correct coverage";
    } else {
      disablityRec =
        "Depending on your own health history and your family’s health history, you may want to consider talking with an insurance agent to discuss your need for disability coverage.";
    }
    console.log({ NW });
    if (LiabilityInsurance === "Yes") {
      liabilityRec =
        "Review policy with your insurance provider to ensure correct coverage";
    } else if (LiabilityInsurance === "No" && NW >= 10000) {
      liabilityRec =
        "Consider obtaining coverage by speaking with an insurance provider";
    } else {
      liabilityRec = "No coverage is recommended based on the info entered";
    }
    if (LiabilityInsurance === "No" && NW >= 10000 && NW < 1000000) {
      liablilityBudget = 25;
    } else if (LiabilityInsurance === "No" && NW >= 1000000 && NW < 2000000) {
      liablilityBudget = 50;
    } else if (LiabilityInsurance === "No" && NW >= 2000000 && NW < 3000000) {
      liablilityBudget = 75;
    } else if (LiabilityInsurance === "No" && NW >= 3000000) {
      liablilityBudget = 100;
    }
    console.log({ liablilityBudget });

    if (NeedLifeInsurance === "No" && LifeInsurance === "Yes") {
      lifeInsuranceRec =
        "Based on the info entered, it doesn’t appear that you need life insurance at this time. Since you have a life insurance policy, you should review it with your insurance provider to ensure it provides the coverage you’re seeking.";
    } else if (
      NeedLifeInsurance === "Yes" &&
      LifeInsurance === "No" &&
      data.is_info_sharable === 2
    ) {
      lifeInsuranceRec =
        "You should obtain coverage as soon as possible by speaking with an insurance provider. Based on your info, you should have a " +
        TLICYou +
        " Term Life Insurance Policy.";
    } else if (
      NeedLifeInsurance === "Yes" &&
      LifeInsurance === "No" &&
      data.is_info_sharable === 1
    ) {
      lifeInsuranceRec =
        "You should obtain coverage as soon as possible by speaking with an insurance provider. Based on your info, you should have a " +
        TLICYou +
        " Term Life Insurance Policy and " +
        data?.partner_first_name +
        " " +
        data?.partner_last_name +
        " should have a " +
        TLICPart +
        " Term Life Insurance Policy.";
    } else if (
      NeedLifeInsurance === "Yes" &&
      LifeInsurance === "Yes" &&
      data.is_info_sharable === 2
    ) {
      lifeInsuranceRec =
        "You should review your Life Insurance Policy with your insurance provider to ensure it provides the appropriate coverage. Based on your info, you should have a " +
        TLICYou +
        " Term Life Insurance Policy.";
    } else if (
      NeedLifeInsurance === "Yes" &&
      LifeInsurance === "Yes" &&
      data.is_info_sharable === 1
    ) {
      lifeInsuranceRec =
        "You should review your Life Insurance Policy with your insurance provider to ensure it provides the appropriate coverage. Based on your info, you should have a " +
        TLICYou +
        " Term Life Insurance Policy and " +
        data.partner_first_name +
        " " +
        data.partner_last_name +
        " should have a " +
        TLICPart +
        " Term Life Insurance Policy.";
    } else if (NeedLifeInsurance === "No" && LifeInsurance === "No") {
      lifeInsuranceRec = "No coverage is needed based on the info entered";
    }
    lifeInsuranceBudget =
      lifeInsuranceBudget > 0
        ? lifeInsuranceBudget
        : data?.importantValues?.lifeInsuranceBudget;
    if (data?.rent_or_own === "own" && RenterInsurance === "No") {
      renterBudget = 101;
    } else {
      renterBudget = renterBudget > 0 ? renterBudget : 15;
    }
    lifeInsuranceBudget = Number(lifeInsuranceBudget || 0)
      .toFixed(2)
      .toString();
    console.log("healthBudget====", healthBudget);
    const budget = {
      // ...defaultValue,
      "health-insurance": healthBudget,
      "car-insurance": carBudget,
      "disablity-insurance": disablityBudget,
      "other-insurance": otherBudget,
      "renters-insurance": renterBudget,
      "liability-insurance": liablilityBudget,
      "life-insurance": lifeInsuranceBudget,
      "2nd/vacation/investment-property-insurance": _2ndPropBudget,
      "long-term-care-insurance": longTermBudget,
    };

    const szRec = {
      // ...insuranceRec,
      "car-insurance": carRec,
      "health-insurance": healthRec,
      "other-insurance": otherRec,
      "renters-insurance": rentersRec,
      "disablity-insurance": disablityRec,
      "liability-insurance": liabilityRec,
      "life-insurance": lifeInsuranceRec,
      "long-term-care-insurance": longTermCareInsuranceRec,
      "2nd/vacation/investment-property-insurance":
        _2ndVacationPropertyInsuranceRec,
    };

    let allIns = allInsurance;
    allIns[index].szRecommendation = szRec?.[szType];
    allIns[index].monthlyCost = budget?.[szType];

    setDefaultValue({
      // ...defaultValue,
      healthMonthlyBudget: healthBudget,
      carMonthlyBudget: carBudget,
      disablityBudget: disablityBudget,
      otherBudget: otherBudget,
      renterBudget: renterBudget,
      liablilityBudget: liablilityBudget,
      lifeInsuranceBudget: lifeInsuranceBudget,
      _2ndPropBudget: _2ndPropBudget,
      longTermBudget: longTermBudget,
    });

    setInsuranceRec(szRec);
    setInsurance(allIns);
    console.log("all====", allIns);
    form.setFieldsValue({ insurance: allIns });
  };

  const getLabel = (szType) => {
    if (szType === "renters-insurance") {
      if (userData?.rent_or_own === "own") {
        return "Home Insurance";
      } else {
        return szType?.replace(/-/g, " ");
      }
    } else {
      return szType?.replace(/-/g, " ");
    }
  };

  return (
    <div className="personalmainheader">
      <div className="personaltextbackground">
        <p className="personaltext">Insurance Information</p>
      </div>
      <div className="line"></div>
      <div className="from-section insurance-form">
        <div className="formdiv">
          <h3 className="in-policiesText">
            Do you have any of the below insurance policies?
          </h3>
          <div className="insurance-type-section">
            <div className="main-container" key={Math.random()}>
              <Form
                form={form}
                initialValues={{ insurance }}
                onFinish={handleSave}
                onValuesChange={debounce(calcRec, 500)}
                key={"insurance"}
                name="insuranceForm"
                layout="vertical"
                scrollToFirstError={{
                  behavior: "smooth",
                  block: "center",
                  inline: "center",
                }}
              >
                <Form.List name="insurance" form={form} onFinish={handleSave}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name }, index) => (
                        <>
                          <Form.Item
                            name={[name, "insuranceVisible"]}
                            className="radio-heading"
                            key={key}
                            label={getLabel(insurance?.[name]?.szType)}
                            rules={[
                              {
                                required:
                                  insurance?.[name]?.szType !==
                                    "life-insurance" &&
                                  insurance?.[name]?.szType !==
                                    "health-insurance",
                              },
                            ]}
                          >
                            <Radio.Group>
                              <Radio value={1}>Yes</Radio>
                              <Radio value={2}>No</Radio>
                            </Radio.Group>
                          </Form.Item>
                          <div className="inner-section">
                            {insurance?.[name]?.insuranceVisible === 1 && (
                              <>
                                <div className="bothdiv">
                                  <Form.Item
                                    className="formtitles"
                                    label="Payment Frequency"
                                    name={[name, "szPaymentFrequency"]}
                                    initialValue={"monthly"}
                                  >
                                    <Select
                                      style={{ minWidth: 221 }}
                                      options={dropdownProps?.paymentFrequency}
                                      className={`buttonWithStyles`}
                                    />
                                  </Form.Item>

                                  <Form.Item
                                    className="formtitles"
                                    label="Paid via paycheck?"
                                    name={[name, "paidViaCheck"]}
                                    initialValue={1}
                                  >
                                    <Select
                                      style={{ minWidth: 196 }}
                                      options={dropdownProps?.payViaPayCheck}
                                      className={`buttonWithStyles`}
                                    />
                                  </Form.Item>
                                </div>

                                {insurance?.[name]?.szType ==
                                  "life-insurance" && (
                                  <>
                                    <div className="name-inputs2">
                                      <Form.Item
                                        name={[name, "inflationRate"]}
                                        className={"formtitle"}
                                        label={
                                          insurance?.[name]?.szType?.replace(
                                            /-/g,
                                            " "
                                          ) + " - Inflation Rate"
                                        }
                                        validateFirst={true}
                                        rules={[
                                          {
                                            required: true,
                                            message: `Please enter the ${
                                              insurance?.[
                                                name
                                              ]?.szType?.replace(/-/g, " ") +
                                              " - Inflation Rate"
                                            }`,
                                          },
                                        ]}
                                        initialValue={getInflationRateInsurance()}
                                      >
                                        <InputNumber
                                          step={"0.00"}
                                          suffix={"%"}
                                          className={"input1 w-100 input-parse"}
                                          controls={false}
                                        />
                                      </Form.Item>
                                      <div className="current-text">
                                        <strong>Current Rate:</strong>{" "}
                                        {getInflationRateInsurance() + "%"}
                                      </div>
                                    </div>
                                    <div className="name-inputs2">
                                      <Form.Item
                                        name={[name, "clientRateOfReturn"]}
                                        className={"formtitle"}
                                        label={
                                          insurance?.[name]?.szType?.replace(
                                            /-/g,
                                            " "
                                          ) + " - Client Rate Of Return"
                                        }
                                        validateFirst={true}
                                        rules={[
                                          {
                                            required: true,
                                            message: `Please enter the ${
                                              insurance?.[
                                                name
                                              ]?.szType?.replace(/-/g, " ") +
                                              " - Client Rate Of Return"
                                            }`,
                                          },
                                        ]}
                                        initialValue={getRateOfReturnInsurance(
                                          userData?.importantValues
                                            ?.RiskTolorenceScore || 0
                                        )}
                                      >
                                        <InputNumber
                                          step={"0.00"}
                                          suffix={"%"}
                                          className={"input1 w-100 input-parse"}
                                          controls={false}
                                        />
                                      </Form.Item>
                                      <div className="current-text">
                                        <strong>Current Rate:</strong>{" "}
                                        {getRateOfReturnInsurance(
                                          userData?.importantValues
                                            ?.RiskTolorenceScore || 0
                                        ) + "%"}
                                      </div>
                                    </div>
                                  </>
                                )}
                                <div className="name-inputs2">
                                  <Form.Item
                                    name={[name, "cost"]}
                                    label={
                                      formatLable(
                                        insurance?.[name]?.szPaymentFrequency
                                          ? insurance?.[name]
                                              ?.szPaymentFrequency
                                          : "Monthly"
                                      ) +
                                      " " +
                                      getLabel(insurance?.[name]?.szType) +
                                      " cost"
                                    }
                                    className={"formtitle"}
                                    validateFirst={true}
                                    rules={[
                                      {
                                        required: true,
                                        message: `Please enter the ${
                                          formatLable(
                                            insurance?.[name]
                                              ?.szPaymentFrequency
                                              ? insurance?.[name]
                                                  ?.szPaymentFrequency
                                              : "Monthly"
                                          ) +
                                          " " +
                                          insurance?.[name]?.szType?.replace(
                                            /-/g,
                                            " "
                                          ) +
                                          " cost"
                                        }`,
                                      },
                                    ]}
                                    initialValue={
                                      insurance?.[name]?.szType ==
                                      "health-insurance"
                                        ? defaultValue?.healthMonthlyBudget
                                        : insurance?.[name]?.szType ==
                                          "car-insurance"
                                        ? defaultValue?.carMonthlyBudget
                                        : insurance?.[name]?.szType ==
                                          "life-insurance"
                                        ? defaultValue?.lifeInsuranceBudget
                                        : insurance?.[name]?.szType ==
                                          "liability-insurance"
                                        ? defaultValue?.liablilityBudget
                                        : insurance?.[name]?.szType ==
                                          "2nd/vacation/investment-property-insurance"
                                        ? defaultValue?._2ndPropBudget
                                        : insurance?.[name]?.szType ==
                                          "renters-insurance"
                                        ? defaultValue?.renterBudget
                                        : insurance?.[name]?.szType ==
                                          "long-term-care-insurance"
                                        ? defaultValue?.longTermBudget
                                        : 0
                                    }
                                  >
                                    <InputNumber
                                      step={"0.00"}
                                      prefix={"$"}
                                      className={"input1 w-100 input-parse"}
                                      controls={false}
                                    />
                                  </Form.Item>
                                </div>
                              </>
                            )}
                            <div className="name-inputs2">
                              <Form.Item
                                name={[name, "monthlyCost"]}
                                label={
                                  "Budget Monthly " +
                                  getLabel(insurance?.[name]?.szType) +
                                  " cost"
                                }
                                rules={[
                                  {
                                    required: true,
                                    message: `Please enter the Budget ${
                                      formatLable(
                                        insurance?.[name]?.szPaymentFrequency
                                          ? insurance?.[name]
                                              ?.szPaymentFrequency
                                          : "Monthly"
                                      ) +
                                      " " +
                                      insurance?.[name]?.szType?.replace(
                                        /-/g,
                                        " "
                                      ) +
                                      " cost"
                                    }`,
                                  },
                                ]}
                                className={"formtitle"}
                                initialValue={
                                  insurance?.[name]?.szType ==
                                  "health-insurance"
                                    ? defaultValue?.healthMonthlyBudget
                                    : insurance?.[name]?.szType ==
                                      "car-insurance"
                                    ? defaultValue?.carMonthlyBudget
                                    : insurance?.[name]?.szType ==
                                      "life-insurance"
                                    ? defaultValue?.lifeInsuranceBudget
                                    : insurance?.[name]?.szType ==
                                      "liability-insurance"
                                    ? defaultValue?.liablilityBudget
                                    : insurance?.[name]?.szType ==
                                      "2nd/vacation/investment-property-insurance"
                                    ? defaultValue?._2ndPropBudget
                                    : insurance?.[name]?.szType ==
                                      "renters-insurance"
                                    ? defaultValue?.renterBudget
                                    : insurance?.[name]?.szType ==
                                      "long-term-care-insurance"
                                    ? defaultValue?.longTermBudget
                                    : 0
                                }
                              >
                                <InputNumber
                                  step={"0.00"}
                                  prefix={"$"}
                                  className={"input1 w-100 input-parse"}
                                  controls={false}
                                />
                              </Form.Item>
                            </div>
                            <div className="insurance-textarea">
                              <Form.Item
                                name={[name, "szRecommendation"]}
                                label={
                                  getLabel(insurance?.[name]?.szType) +
                                  " Recommendation"
                                }
                                initialValue={
                                  insuranceRec?.[insurance?.[name]?.szType] ||
                                  ""
                                }
                              >
                                <Input.TextArea
                                  rows={4}
                                  className="inputtext"
                                  style={{ resize: "none" }}
                                />
                              </Form.Item>
                              <div className="current-text">
                                <strong>Current recommendation:</strong>{" "}
                                {insurance?.[name]?.szRecommendation}
                              </div>
                            </div>
                          </div>
                          <br />
                          <Form.Item>
                            <Button
                              className="primary-medium-btn save-btn"
                              onClick={() => {
                                setClickedIndex(index);
                                form.submit();
                              }}
                            >
                              Save Changes
                            </Button>
                            <hr></hr>
                          </Form.Item>
                        </>
                      ))}
                    </>
                  )}
                </Form.List>
              </Form>
            </div>
          </div>
        </div>
        {/* right-section */}
        <div className="ant-notification-notice-content">
          <div className="alert">
            Personal insurance policies are designed to provide individuals with
            financial protection and peace of mind against unforeseen risks and
            events that may result in financial loss or hardship.
          </div>
        </div>
      </div>
    </div>
  );
}
